import { useQueries } from "@tanstack/react-query"
import styled from "styled-components"
import { Address, formatEther, formatUnits } from "viem"
import { useAccount, useReadContracts } from "wagmi"

import { DECIMALS_ETH, VaultsEnum } from "../../constants"
import colors from "../../designSystem/colors"
import { fetchMintAmount, fetchUserYieldEarned } from "../../gql/queries"
import { useUserAccount } from "../../hooks/useUserAccount"
import {
  getContractAbi,
  getContractAddr,
  PLACEHOLDER_ACCOUNT,
} from "../../utils/address"
import { formatAmount, formatBigint } from "../../utils/math"
import { getVaultImg } from "../../utils/vault"
import { delayedFade, Subtitle, Title } from "../common"

const BALANCE_FONT_SIZE = 56

const ProductAssetLogoContainer = styled.div<{ delay?: number }>`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${colors.background.one};
  border-radius: 50%;
  position: absolute;
  left: -48px;
  ${delayedFade}
`

const BalanceTitle = styled.div<{ delay?: number }>`
  font-size: 12px;
  text-transform: uppercase;
  text-align: center;
  letter-spacing: 1.5px;
  color: ${colors.primaryText}7A;
  ${delayedFade}
`

const HeroText = styled(Title)<{ delay?: number }>`
  font-size: ${BALANCE_FONT_SIZE}px;
  line-height: ${BALANCE_FONT_SIZE + 8}px;
  ${delayedFade}
`

const HeroSubtitle = styled(Subtitle)<{ delay?: number; color: string }>`
  color: ${(props) => props.color};
  ${delayedFade}
`

const Flex = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
`

export const BalanceInfo = () => {
  const { address, isConnected } = useAccount()
  const account: Address = address ?? PLACEHOLDER_ACCOUNT

  const { data, isLoading } = useReadContracts({
    contracts: [
      {
        abi: getContractAbi("WEETH"),
        address: getContractAddr("WEETH"),
        functionName: "getRate",
      },
    ],
  })

  const dataSubgraph = useQueries({
    queries: [
      {
        queryKey: ["mint-amount", account],
        queryFn: async () => {
          return fetchMintAmount({ account })
        },
        enabled: isConnected,
      },
      {
        queryKey: ["yield", account],
        queryFn: async () => {
          return fetchUserYieldEarned({
            vaultId: getContractAddr("EtherfiVault"),
            account: account,
          })
        },
        enabled: isConnected,
      },
    ],
  })

  const { totalUserBalance } = useUserAccount({
    account,
  })

  const getRate = data?.[0].result || 0n
  const totalBalance =
    parseFloat(formatUnits(totalUserBalance, DECIMALS_ETH)) *
    parseFloat(formatUnits(getRate, DECIMALS_ETH))
  const yieldEarned = dataSubgraph[1].data || 0n

  const yieldEarnedPct =
    totalUserBalance === 0n
      ? 0
      : (parseFloat(formatEther(yieldEarned)) /
          parseFloat(formatEther(totalUserBalance))) *
        100

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "flex-end",
        gap: "8px",
      }}
    >
      <BalanceTitle delay={0.2}>
        <span>3Jane Vault Balance</span>
      </BalanceTitle>
      <Flex>
        <ProductAssetLogoContainer>
          <img
            width={BALANCE_FONT_SIZE * 0.75}
            height={BALANCE_FONT_SIZE * 0.75}
            src={getVaultImg(VaultsEnum.ETHERFI)}
            alt="etherfi"
          />
        </ProductAssetLogoContainer>
        <HeroText delay={0.3}>
          {isLoading ? "---" : totalBalance.toFixed(2)}
        </HeroText>
      </Flex>
      <HeroSubtitle color={colors.green} delay={0.4}>
        {isLoading
          ? "---"
          : `${formatBigint({
              n: yieldEarned,
              decimals: DECIMALS_ETH,
            })} (${formatAmount(yieldEarnedPct, true)}%)`}
      </HeroSubtitle>
    </div>
  )
}
