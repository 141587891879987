import { Col, Row } from "react-bootstrap"
import styled from "styled-components"

import colors from "../../designSystem/colors"
import { components } from "../../designSystem/components"
import sizes from "../../designSystem/sizes"
import { StepsCarouselVault } from "../StepsHeader/StepsCarouselVault"

const FooterRow = styled(Row)`
  height: ${components.footer}px;
  box-sizing: border-box;
  background: black;

  > *:last-child {
    border: none;
  }

  @media (max-width: ${sizes.lg}px) {
    position: sticky;
    bottom: 0;
  }
`

const HiddenCol = styled(Col)`
  height: ${components.header}px;
  background: ${colors.background.one};
  border: 1px solid ${colors.background.one};
  @media (max-width: ${sizes.lg}px) {
    display: none;
  }
`

const FooterCol = styled(Col)`
  height: ${components.header}px;
  border: 1px solid ${colors.border};
`

const FooterContainer = styled(Col)`
  position: relative;
  display: flex;
  width: 100%;
  height: 64px;
  min-height: 64px;
  justify-content: center;
  align-items: top;
  z-index: 1;
  border-bottom: 1px solid ${colors.border};
`

export const VaultsFooter = () => {
  return (
    <FooterRow>
      <FooterCol md={12} lg={6}>
        <FooterContainer>
          <StepsCarouselVault />
        </FooterContainer>
      </FooterCol>
      <HiddenCol md={0} lg={0} />
    </FooterRow>
  )
}
