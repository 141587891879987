import { useEffect, useState } from "react"
import { useQueries } from "@tanstack/react-query"
import styled, { css } from "styled-components"
import { Address, formatEther } from "viem"
import { useAccount } from "wagmi"

import { DECIMALS_ETH, LINKS } from "../../constants"
import colors from "../../designSystem/colors"
import { components } from "../../designSystem/components"
import { fetchMintAmount, fetchUserYieldEarned } from "../../gql/queries"
import { useUserAccount } from "../../hooks/useUserAccount"
import { getContractAddr, PLACEHOLDER_ACCOUNT } from "../../utils/address"
import { formatAmount, formatBigint } from "../../utils/math"
import { delayedFade, fadeIn } from "../common"
import HelpInfo from "../HelpInfo"
import { LinkWithIcon } from "../LinkWithIcon"
import { AmplolLogo, AppLogo } from "../Logo"
import TooltipExplanation from "../TooltipExplanation"

const AmplolEarnedContainer = styled.div<{ delay?: number }>`
  display: flex;
  align-items: center;
  font-size: 16px;
  gap: 8px;
  ${delayedFade}
`

const AmplolInfoWrapper = styled.div<{ $show: boolean; delay?: number }>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  ${({ $show, delay }) => {
    return (
      $show &&
      css`
        opacity: 0;

        &:disabled {
          opacity: 0;
        }

        animation: ${fadeIn} 1s ease-in-out forwards;
        animation-delay: ${delay || 0}s;
      `
    )
  }}
  font-size: 14px;
  color: ${colors.tertiaryText};
  display: flex;
  align-items: center;
  gap: 4px;
`

const AmplolAmountWrapper = styled.span`
  color: ${colors.primaryText};
  margin-left: 8px;
`

export const AmplolInfo = () => {
  const { address, isConnected } = useAccount()
  const account: Address = address ?? PLACEHOLDER_ACCOUNT

  const dataSubgraph = useQueries({
    queries: [
      {
        queryKey: ["mint-amount", account],
        queryFn: async () => {
          return fetchMintAmount({ account })
        },
        enabled: isConnected,
      },
      {
        queryKey: ["yield"],
        queryFn: async () => {
          return fetchUserYieldEarned({
            vaultId: getContractAddr("EtherfiVault"),
            account: account,
          })
        },
        enabled: isConnected,
      },
    ],
  })

  const { amplolBalance, isLoading: isUserDataLoading } = useUserAccount({
    account,
  })

  const sumValueFromMint = parseFloat(formatEther(dataSubgraph[0].data || 0n))

  const amplolBalanceFormatted = parseFloat(formatEther(amplolBalance))
  const amplolProfit = amplolBalanceFormatted - Number(sumValueFromMint)
  const amplolProfitPct =
    sumValueFromMint === 0 || amplolBalanceFormatted === 0
      ? 0
      : (amplolProfit / Number(sumValueFromMint)) * 100

  const [triggerAnimation, setTriggerAnimation] = useState<boolean>(true)

  useEffect(() => {
    setTimeout(() => {
      setTriggerAnimation(false)
    }, 1600)
  }, [])

  return (
    <AmplolInfoWrapper $show={triggerAnimation} delay={0.5}>
      <div
        style={{
          display: "flex",
          alignItems: "center",
        }}
      >
        <span>AMPLOL Earned</span>
        <TooltipExplanation
          placement="top"
          maxWidth={300}
          explanation={
            <span
              style={{
                fontSize: "12px",
                textWrap: "balance",
                display: "block",
                maxWidth: `${components.modalWidth}px`,
                whiteSpace: "normal",
              }}
            >
              Initial AMPLOL Minted + AMPLOL Earned from leveraged rebase. <br /> <br />
              AMPLOL ={" "}
              <span
                style={{
                  display: "inline-flex",
                  verticalAlign: "middle",
                }}
              >
                <AppLogo size={14} />{" "}
              </span>
              {" "} upside to 3Jane. <br />
              <LinkWithIcon color={colors.secondaryText} href={LINKS.amplol}>
                Read more
              </LinkWithIcon>
            </span>
          }
          renderContent={({ ref, ...triggerHandler }) => (
            <HelpInfo
              containerRef={ref}
              {...triggerHandler}
              style={{
                color: colors.secondaryText,
                marginLeft: "4px",
                display: "inline-block",
              }}
            >
              <span
                style={{
                  fontSize: "8px",
                  marginLeft: "4px",
                }}
              >
                i
              </span>
            </HelpInfo>
          )}
        />
      </div>
      <AmplolEarnedContainer delay={0.6}>
        <AmplolLogo />
        <AmplolAmountWrapper>
          <LinkWithIcon
            noUnderline
            color={colors.primaryText}
            href={LINKS.amplolContract}
          >
            {isUserDataLoading ? (
              "---"
            ) : (
              <>
                <span>
                  {formatBigint({
                    n: amplolBalance,
                    decimals: DECIMALS_ETH,
                  })}
                </span>
                <span
                  style={{
                    color:
                      amplolBalanceFormatted !== 0 && amplolProfit < 0
                        ? colors.red
                        : colors.green,
                  }}
                >
                  {" "}
                  ({formatAmount(amplolProfitPct, true)}%)
                </span>
              </>
            )}
          </LinkWithIcon>
        </AmplolAmountWrapper>
      </AmplolEarnedContainer>
    </AmplolInfoWrapper>
  )
}
