import { PropsWithChildren } from "react"
import {
  darkTheme,
  getDefaultConfig,
  RainbowKitProvider,
} from "@rainbow-me/rainbowkit"

import "@rainbow-me/rainbowkit/styles.css"

import { QueryClient, QueryClientProvider } from "@tanstack/react-query"
import { http, WagmiProvider } from "wagmi"
import { mainnet } from "wagmi/chains"

const config = getDefaultConfig({
  ssr: true,
  // Your dApps chains
  chains: [mainnet],
  transports: {
    // RPC URL for each chain
    [mainnet.id]: http(process.env.REACT_APP_MAINNET_RPC || ""),
  },

  // Required API Keys
  projectId: process.env.REACT_APP_WALLET_CONNECT_PROJECT_ID || "",

  // Required App Info
  appName: "3Jane",

  // Optional App Info
  appDescription:
    "Unlocking crypto-native derivatives yield. Backed by cryptoeconomic security",
  appUrl: "https://3jane.xyz", // your app's url
  appIcon: "https://frontend-puce-tau-48.vercel.app/logo192.png", // your app's icon, no bigger than 1024x1024px (max. 1MB)
})

const queryClient = new QueryClient()

export const Web3Provider: React.FC<PropsWithChildren> = ({ children }) => {
  return (
    <WagmiProvider config={config}>
      <QueryClientProvider client={queryClient}>
        <RainbowKitProvider modalSize="compact" theme={darkTheme()}>
          {children}
        </RainbowKitProvider>
      </QueryClientProvider>
    </WagmiProvider>
  )
}
