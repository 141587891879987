import { Col, Row } from "react-bootstrap"
import styled from "styled-components"

import { VaultsEnum } from "../../constants"
import colors from "../../designSystem/colors"
import { components } from "../../designSystem/components"
import sizes from "../../designSystem/sizes"
import { getVaultContract } from "../../utils/vault"
import { Button } from "../common"
import { ContractAddress } from "../ContractAddress"

const FooterRow = styled(Row)`
  height: ${components.footer}px;
  border-top: 1px solid ${colors.border};
  box-sizing: border-box;
  background: black;

  > * {
    padding: 0;

    &:not(:last-child) {
      border-right: 1px solid ${colors.border};
    }
  }

  @media (max-width: ${sizes.lg}px) {
    position: fixed;
    bottom: 0;
  }
`

const FooterButton = styled(Button)<{
  $isActive?: boolean
  delay: number
  disabled?: boolean
  tooltip?: boolean
  width?: string
}>`
  font-size: 12px;
  border: none;
  border-radius: 0;
  height: ${components.footer}px;
  width: ${({ width }) => (width ? width : "50%")};
  color: ${({ $isActive }) =>
    $isActive ? colors.primaryText : colors.tertiaryText};

  &:hover {
    transition: 0.2s;
    color: ${colors.primaryText};
  }
  &:disabled {
    color: ${colors.tertiaryText};
    pointer-events: ${(props) => (props.tooltip ? "auto" : "none")};
  }

  &:not(:last-child) {
    border-right: 1px solid ${colors.border};
  }
`

interface FooterProps {
  vault: VaultsEnum
  setAction: (action: "deposit" | "withdraw") => void
}

export const VaultFooter = ({ vault, setAction }: FooterProps) => {
  return (
    <FooterRow>
      <Col md={0} lg={6}>
        <ContractAddress address={getVaultContract(vault).vault} />
      </Col>
      <Col md={12} lg={6}>
        <FooterButton
          delay={0.2}
          $isActive={true}
          onClick={() => setAction("deposit")}
          style={{
            fontSize: "1rem",
            backgroundColor: "rgba(0, 41, 255, 0.25)",
          }}
        >
          Deposit
        </FooterButton>
        <FooterButton
          delay={0.3}
          $isActive={true}
          onClick={() => setAction("withdraw")}
          style={{ fontSize: "1rem" }}
        >
          Withdraw
        </FooterButton>
      </Col>
    </FooterRow>
  )
}
