import currency from "currency.js"
import { Col, Row } from "react-bootstrap"
import styled, { css } from "styled-components"

import { ReactComponent as ExternalLinkIcon } from "../../../assets/svg/externalLink.svg"
import {
  DECIMALS_ETH,
  LINKS,
  MAX_VAULT_CAP,
  VAULT_INFO,
  VaultsEnum,
} from "../../../constants"
import colors from "../../../designSystem/colors"
import { components } from "../../../designSystem/components"
import sizes from "../../../designSystem/sizes"
import { useAmplol } from "../../../hooks/useAmplol"
import { useApy } from "../../../hooks/useApy"
import { useVaultSize } from "../../../hooks/useVaultSize"
import { Asset } from "../../../utils/asset"
import { formatAmount, formatBigint } from "../../../utils/math"
import { ApyTooltip } from "../../ApyTooltip"
import { BaseUnderlineLink, fadeIn, PrimaryText, Title } from "../../common"
import { LinkWithIcon } from "../../LinkWithIcon"
import TvlProgressBar from "../../Vaults/TvlProgressBar"
import { ActionModalEnum, ActionType } from "./types"

const FooterRow = styled(Row)`
  min-height: ${components.footer}px;
  border-top: 1px solid ${colors.border};
  box-sizing: content-box;
  z-index: 1;
`

const DetailContainer = styled.div<{ $show?: boolean; delay?: number }>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  height: 100%;
  padding: 10px 0;
  ${({ $show, delay }) => {
    return (
      $show &&
      css`
        opacity: 0;

        &:disabled {
          opacity: 0;
        }

        animation: ${fadeIn} 1s ease-in-out forwards;
        animation-delay: ${delay || 0}s;
      `
    )
  }}
`

const DesktopOnly = styled.div`
  display: flex;
  width: 100%;
  > * {
    padding: 0;

    &:not(:last-child) {
      border-right: 1px solid ${colors.border};
    }
  }
  @media (max-width: ${sizes.lg}px) {
    display: none;
  }
`

const MobileOnly = styled.div`
  display: none;
  width: 100%;
  > * {
    padding: 0;

    &:not(:last-child) {
      border-right: 1px solid ${colors.border};
    }
  }
  @media (max-width: ${sizes.lg}px) {
    display: flex;
  }
`

const DetailTitle = styled.div`
  font-size: 12px;
  color: ${colors.primaryText}52;
  text-align: center;
`

const DetailText = styled(Title)`
  font-size: 14px;
  text-wrap: balance;

  // to ensure vault name overflows downwards
  @media (min-width: ${sizes.lg}px) {
    height: 40px;
  }
`

const StyledPrimaryText = styled(PrimaryText)`
  font-size: 14px;
  line-height: 20px;
  text-decoration: underline;
  margin-right: 4px;
`

const UnderlineLink = styled(BaseUnderlineLink)`
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 100%;
`

interface ActionModalFooterProps {
  vault: VaultsEnum
  actionType: ActionType
  page: ActionModalEnum
  show: boolean
  depositAsset: Asset
  txhash?: string
}

const ActionModalFooter: React.FC<ActionModalFooterProps> = ({
  show,
  vault,
  actionType,
  page,
  depositAsset,
  txhash,
}) => {
  const { totalSupply } = useAmplol()

  const info = VAULT_INFO[vault]
  const {
    totalBalanceUSD,
    totalBalanceNum,
    isLoading: vaultSizeLoading,
  } = useVaultSize({
    asset: info.nativeAsset,
    vault,
  })

  const fillPct = Math.round((totalBalanceUSD / MAX_VAULT_CAP) * 100)

  const apy = useApy()

  return (
    <>
      {page === ActionModalEnum.PREVIEW ? (
        <FooterRow>
          <DesktopOnly>
            <Col xs={3}>
              <DetailContainer $show={show} delay={0.1}>
                <DetailTitle>Vault</DetailTitle>
                <DetailText>{info.fullName}</DetailText>
              </DetailContainer>
            </Col>
            <Col xs={3}>
              <DetailContainer $show={show} delay={0.1}>
                <DetailTitle>
                  <LinkWithIcon
                    color={colors.secondaryText}
                    href={LINKS.amplolContract}
                  >
                    AMPLOL Minted
                  </LinkWithIcon>
                </DetailTitle>
                <DetailText>
                  {formatBigint({
                    n: totalSupply ?? 0n,
                    decimals: DECIMALS_ETH,
                  })}{" "}
                  AMPLOL
                </DetailText>
              </DetailContainer>
            </Col>
            <Col xs={3}>
              <DetailContainer $show={show} delay={0.2}>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    gap: 4,
                    marginLeft: -12,
                  }}
                >
                  <DetailTitle>APY</DetailTitle>
                  <ApyTooltip />
                </div>
                <DetailText
                  style={{
                    marginRight: "14px",
                  }}
                >
                  <span style={{ color: colors.green }}>{apy.total}</span>
                </DetailText>
              </DetailContainer>
            </Col>
            <Col xs={3}>
              <DetailContainer $show={show} delay={0.3}>
                <DetailTitle>TVL</DetailTitle>
                <div
                  style={{
                    width: "60%",
                    display: "flex",
                    gap: 16,
                  }}
                >
                  <TvlProgressBar
                    percent={fillPct}
                    isLoading={vaultSizeLoading}
                    marginTop={8}
                  />
                  <DetailText>{`${`${currency(totalBalanceNum, {
                    precision: 2,
                    symbol: "",
                  }).format()}`} ${info.nativeAsset}`}</DetailText>
                </div>
              </DetailContainer>
            </Col>
          </DesktopOnly>
          <MobileOnly>
            <Col xs={6}>
              <DetailContainer $show={show} delay={0.1}>
                <DetailTitle>Vault</DetailTitle>
                <DetailText>{info.fullName}</DetailText>
              </DetailContainer>
            </Col>
            <Col xs={6}>
              <DetailContainer $show={show} delay={0.2}>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    gap: 4,
                    marginLeft: -12,
                  }}
                >
                  <DetailTitle>APY</DetailTitle>
                  <ApyTooltip />
                </div>
                <DetailText
                  style={{
                    marginRight: "14px",
                  }}
                >
                  <span style={{ color: colors.green }}>{apy.total}</span>
                </DetailText>
              </DetailContainer>
            </Col>
          </MobileOnly>
        </FooterRow>
      ) : (
        <FooterRow>
          <UnderlineLink
            to={`${LINKS.etherscan}/tx/${txhash}`}
            target="_blank"
            rel="noreferrer noopener"
          >
            <StyledPrimaryText>View on Etherscan</StyledPrimaryText>
            <ExternalLinkIcon />
          </UnderlineLink>
        </FooterRow>
      )}
    </>
  )
}

export default ActionModalFooter
