import { Address, zeroAddress } from "viem"

import addresses from "../addresses.json"
import ENALogo from "../assets/png/ethena.png"
import EtherFiLogo from "../assets/png/etherfi.png"
import { VaultsEnum } from "../constants"
import { Asset } from "./asset"

export const getVaultImg = (vault: VaultsEnum) => {
  switch (vault) {
    case VaultsEnum.ETHERFI:
      return EtherFiLogo
    case VaultsEnum.ETHENA:
      return ENALogo
    default:
      throw new Error("Vault image not supported")
  }
}

type IVaultDepositContracts = {
  depositHelper: Address
  vault: Address
}

export const getVaultContract = (
  vault?: VaultsEnum
): IVaultDepositContracts => {
  switch (vault) {
    case VaultsEnum.ETHENA:
      return {
        depositHelper: addresses.EthenaDepositHelper as Address,
        vault: addresses.EthenaVault as Address,
      }
    case VaultsEnum.ETHERFI:
    default:
      return {
        depositHelper: addresses.EtherfiDepositHelper as Address,
        vault: addresses.EtherfiVault as Address,
      }
    // default:
    //   throw new Error("Vault not supported")
  }
}

export type IVaultParams = {
  optionType: "call" | "put"
  underlyingAsset: Asset
  collateralAsset: Asset
  optionStrikePrice: string
  spotPrice: string
  optionDelta: number
  chanceITM: number
  cycle: "week"
  endOfCycle: string
  express: boolean
  expressKnockOutBarrier: number
  premiumType: "fixed"
  expressKnockOutObservation: "daily"
  premiumObservation: "daily"
  premiumsPaidIn: "weETH" | "ENA"
  optionStyle: "european"
  settlementType: "cash"
  performanceFee: number
  managementFee: number
  currentOptionToken: Address
}

export const getVaultParams = (vault: VaultsEnum): IVaultParams => {
  switch (vault) {
    case VaultsEnum.ETHERFI:
      return {
        optionType: "call",
        underlyingAsset: Asset.ETH,
        collateralAsset: Asset.WEETH,
        optionStrikePrice: "0",
        spotPrice: "0",
        optionDelta: 5,
        chanceITM: 0.05,
        cycle: "week",
        endOfCycle: "0:00:00",
        express: true,
        expressKnockOutBarrier: 0.95,
        premiumType: "fixed",
        expressKnockOutObservation: "daily",
        premiumObservation: "daily",
        premiumsPaidIn: "weETH",
        optionStyle: "european",
        settlementType: "cash",
        performanceFee: 0,
        managementFee: 0,
        currentOptionToken: zeroAddress,
      }
    case VaultsEnum.ETHENA:
      return {
        optionType: "call",
        underlyingAsset: Asset.ETH,
        collateralAsset: Asset.ENA,
        optionStrikePrice: "0",
        spotPrice: "0",
        optionDelta: 5,
        chanceITM: 0.05,
        cycle: "week",
        endOfCycle: "0:00:00",
        express: true,
        expressKnockOutBarrier: 0.95,
        premiumType: "fixed",
        expressKnockOutObservation: "daily",
        premiumObservation: "daily",
        premiumsPaidIn: "ENA",
        optionStyle: "european",
        settlementType: "cash",
        performanceFee: 0,
        managementFee: 0,
        currentOptionToken: zeroAddress,
      }
  }
}
