import { useCallback, useEffect, useMemo, useRef, useState } from "react"
import { useNavigate } from "react-router-dom"
import styled from "styled-components"
import {
  Address,
  erc20Abi,
  formatEther,
  formatUnits,
  maxUint256,
  parseUnits,
} from "viem"
import { mainnet } from "viem/chains"
import {
  useAccount,
  useBalance,
  useReadContract,
  useWaitForTransactionReceipt,
  useWriteContract,
} from "wagmi"

import {
  DECIMALS_ETH,
  MAX_VAULT_CAP,
  VAULT_INFO,
  VaultsEnum,
} from "../../../constants"
import colors from "../../../designSystem/colors"
import { components } from "../../../designSystem/components"
import sizes from "../../../designSystem/sizes"
import { PermitSignature } from "../../../hooks/permit"
import { useAssetPrices } from "../../../hooks/useAssetPrices"
import useRecordDeposit from "../../../hooks/useRecordDeposit"
import { useUserAccount } from "../../../hooks/useUserAccount"
import useUtmParams from "../../../hooks/useUtmParams"
import useVault from "../../../hooks/useVault"
import { useThreeJaneContext } from "../../../store"
import { getContractAbi, PLACEHOLDER_ACCOUNT } from "../../../utils/address"
import {
  Asset,
  getAssetAddress,
  getAssetDecimals,
  getAssetImg,
} from "../../../utils/asset"
import { getVaultContract } from "../../../utils/vault"
import { BrandModalConnectWalletButton } from "../../BrandConnectWalletButton"
import {
  ActionButton,
  BaseInput,
  BaseInputContainer,
  delayedFade,
  FormButtonFade,
  SecondaryText,
} from "../../common"
import FilterDropdown from "../../FilterDropdown"
import TooltipExplanation from "../../TooltipExplanation"
import { DepositBalance } from "./ActionModalBalance"
import {
  ActionModalMintBurnAmount,
  ActionModalMintBurnAmountPlaceholder,
} from "./ActionModalMintBurnAmount"
import { ActionModalNote } from "./ActionModalNote"
import { ActionModalPermit } from "./ActionModalPermit"
import { ActionModalTab } from "./ActionModalTab"
import { ActionModalTransaction } from "./ActionModalTransaction"
import {
  ActionModalCompleteWithdraw,
  ActionModalInitiateWithdraw,
  ActionModalInstantWithdraw,
} from "./ActionModalWithdraw"
import { ActionModalEnum, ActionType, PoolValidationErrors } from "./types"

const ModalContainer = styled.div`
  height: calc(100vh - ${components.header}px - ${components.footer}px);
  @media (max-width: ${sizes.lg}px) {
    height: 100%;
    margin-top: -24px;
  }
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: relative; // for ActionModalNote
`

const ModalWrapper = styled.div`
  width: ${components.modalWidth}px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 16px;
`

const ButtonsWrapper = styled.div`
  width: 100%;
  padding-top: 24px;
  gap: 24px;
  display: flex;
  flex-direction: column;
`

const BalanceTitle = styled.div<{ delay?: number }>`
  font-size: 14px;
  text-transform: uppercase;
  text-align: center;
  color: ${colors.primaryText}7A;
  margin-top: 10px;
  text-transform: capitalize;
  ${delayedFade}
`

const BaseInputButton = styled.div`
  margin-right: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${colors.secondaryText};
  border: 2px solid ${colors.secondaryText};
  width: 54px;
  flex-shrink: 0;
  height: 40px;
  font-size: 12px;
  line-height: 16px;
  text-align: center;
  letter-spacing: 1px;
  cursor: pointer;
  &:hover {
    color: black;
    background: ${colors.secondaryText};
  }
  &:focus {
    color: black;
    background: ${colors.secondaryText};
  }
  tab-index: 1;
  border-radius: 16px;
  margin-top: 8px;
`

const StyledBaseInput = styled(BaseInput)`
  font-size: 48px;
`

const InputContainer = styled(BaseInputContainer)<{
  delay?: number
}>`
  display: flex;
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
    border: none;
  }
  border: none;
  box-shadow: none;
  margin: 0;
  padding: 0;
  max-width: 100%;
  align-items: center;
  background: black;

  /* Firefox */

  input[type="number"] {
    -moz-appearance: textfield;
    text-align: center;
    width: 100%;
  }

  ${delayedFade};
`

const ErrorText = styled(SecondaryText)`
  text-align: center;
  font-size: 12px;
  color: ${colors.red};
  margin-bottom: 8px;
  height: 24px;
  margin-top: -12px;
`

const FilterDropdownDisplay = styled.div`
  z-index: 9999;

  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 24px;
  margin-top: 6px;

  > img {
    width: 32px;
    height: 32px;
    margin-right: 8px;
  }
`

const FilterDropdownContainer = styled.div`
  position: relative;
  z-index: 100;
  margin: 16px 0;
`

interface ActionModalHeroProps {
  actionType: ActionType
  vault: VaultsEnum
  page: ActionModalEnum
  setPage: (page: ActionModalEnum) => void
  setTxhashMain: (txhash: string) => void
  onHide: () => void
  show: boolean
  triggerAnimation: boolean
  depositAsset: Asset
  setDepositAsset: React.Dispatch<React.SetStateAction<Asset>>
}

const ActionModalHero: React.FC<ActionModalHeroProps> = ({
  actionType,
  vault,
  page,
  setPage,
  setTxhashMain,
  onHide,
  show,
  triggerAnimation,
  depositAsset,
  setDepositAsset,
}) => {
  const { address, isConnected: active, chainId } = useAccount()
  const account: Address = address ?? PLACEHOLDER_ACCOUNT

  const { utmSource, utmMedium, utmCampaign } = useUtmParams()

  const inputRef = useRef<HTMLInputElement>(null)
  const [inputAmount, setInputAmount] = useState<string>("")
  const [tab, setTab] = useState<"instant" | "initiate">("instant")
  const [inputFocused, setInputFocused] = useState(false)
  const [permittedAmount, setPermittedAmount] = useState<bigint | undefined>(
    undefined
  )

  const { totalBalance, decimals } = useVault()

  const {
    accountVaultBalance,
    instantWithdrawalAmount,
    withdrawAmount,
    withdrawalShares,
  } = useUserAccount({
    account,
  })

  const navigate = useNavigate()

  const { setAmountBn, setTxError, setShowToast, setAction } =
    useThreeJaneContext((s) => ({
      setAmountBn: s.setAmountBn,
      setTxError: s.setTxError,
      setShowToast: s.setShowToast,
      setAction: s.setAction,
    }))

  const info = VAULT_INFO[vault]

  const { depositHelper: depositHelperContract, vault: vaultContract } =
    getVaultContract(vault)

  const assetDecimals = getAssetDecimals(depositAsset)
  const assetContractAddress = getAssetAddress(depositAsset)
  const ethBalance = useBalance({
    address: account,
  })

  const { data: assetBalanceData } = useReadContract({
    abi: erc20Abi,
    address: assetContractAddress,
    functionName: "balanceOf",
    args: [account],
  })

  const userAssetBalance: bigint =
    depositAsset === Asset.ETH
      ? ethBalance?.data?.value || 0n
      : assetBalanceData !== undefined
        ? assetBalanceData
        : 0n

  const {
    data: dhHashWithPermit,
    writeContract: dhWriteContractWithPermit,
    isPending: dhIsPendingWithPermit,
  } = useWriteContract()
  const {
    isLoading: dhConfirmingWithPermit,
    isSuccess: dhConfirmingSuccessWithPermit,
    error: dhConfirmingErrorWithPermit,
  } = useWaitForTransactionReceipt({
    hash: dhHashWithPermit,
  })

  const {
    data: ddHashWithPermit,
    writeContract: ddWriteContractWithPermit,
    isPending: ddIsPendingWithPermit,
  } = useWriteContract()
  const {
    isLoading: ddConfirmingWithPermit,
    isSuccess: ddConfirmingSuccessWithPermit,
    error: ddConfirmingErrorWithPermit,
  } = useWaitForTransactionReceipt({
    hash: ddHashWithPermit,
  })

  const {
    data: dhETHHash,
    writeContract: dhETHWriteContract,
    isPending: dhETHIsPending,
  } = useWriteContract()
  const {
    isLoading: dhETHConfirming,
    error: dhETHConfirmingError,
    isSuccess: dhETHConfirmingSuccess,
  } = useWaitForTransactionReceipt({
    hash: dhETHHash,
  })

  const depositHash = dhETHHash || dhHashWithPermit || ddHashWithPermit
  const depositIsPending =
    dhETHIsPending || dhIsPendingWithPermit || ddIsPendingWithPermit
  const depositConfirming =
    dhETHConfirming || dhConfirmingWithPermit || ddConfirmingWithPermit
  const depositSuccess =
    dhETHConfirmingSuccess ||
    dhConfirmingSuccessWithPermit ||
    ddConfirmingSuccessWithPermit
  const txError =
    dhETHConfirmingError ||
    dhConfirmingErrorWithPermit ||
    ddConfirmingErrorWithPermit

  const { mutate: recordDeposit } = useRecordDeposit()

  const amountBn = useMemo(() => {
    try {
      const amount = parseUnits(
        parseFloat(inputAmount).toFixed(assetDecimals),
        assetDecimals
      )
      setAmountBn(amount)
      return amount
    } catch (err) {
      return 0n
    }
  }, [assetDecimals, inputAmount, setAmountBn])

  useEffect(() => {
    if (!depositHash) {
      return
    }
    if (depositSuccess) {
      recordDeposit({
        account,
        amount: formatEther(amountBn),
        utmSource,
        utmMedium,
        utmCampaign,
      })
      setShowToast(true)
      setTxhashMain("")
      setInputAmount("")
      setPermittedAmount(undefined)
      navigate("/")
    } else {
      setPage(ActionModalEnum.TRANSACTION_STEP)
    }
  }, [
    account,
    amountBn,
    depositConfirming,
    depositHash,
    depositSuccess,
    inputAmount,
    navigate,
    onHide,
    recordDeposit,
    setPage,
    setShowToast,
    setTxhashMain,
    utmCampaign,
    utmMedium,
    utmSource,
  ])

  useEffect(() => {
    if (txError) {
      console.error("Tx failed", txError)
      setShowToast(true)
      setTxError(true)
    }
  }, [txError, setTxError, setShowToast])

  useEffect(() => {
    //Add deposit helper
    if (depositHash) {
      setTxhashMain(depositHash)
    }
  }, [depositHash, setTxhashMain])

  const [showTypingContainer, setShowTypingContainer] = useState(false)
  const [permitSignature, setPermitSignature] = useState<
    PermitSignature | undefined
  >(undefined)

  const { data: ethPrice } = useAssetPrices(info.nativeAsset)
  const totalBalanceNum = parseFloat(
    formatUnits(totalBalance || 0n, decimals ?? DECIMALS_ETH)
  )
  const totalBalanceUSD = totalBalanceNum * (ethPrice ?? 0)
  const isVaultCapMax = totalBalanceUSD > MAX_VAULT_CAP

  const isInputNonZero = useMemo((): boolean => {
    return parseFloat(inputAmount) > 0
  }, [inputAmount])

  const error = useMemo((): PoolValidationErrors | undefined => {
    try {
      /** Check block with input requirement */
      if (isInputNonZero && active) {
        const amountBigNumber = parseUnits(inputAmount, assetDecimals)
        switch (actionType) {
          case "deposit":
            if (amountBigNumber > userAssetBalance) {
              return "insufficientBalance"
            }
            break
          case "withdraw":
            if (
              tab === "instant" &&
              amountBigNumber > instantWithdrawalAmount
            ) {
              return "withdrawLimitExceeded"
            }
            if (tab === "initiate" && amountBigNumber > accountVaultBalance) {
              return "withdrawLimitExceeded"
            }
            break
        }
      }
    } catch (err) {
      // Assume no error because empty input unable to parse
    }

    return undefined
  }, [
    actionType,
    active,
    tab,
    assetDecimals,
    instantWithdrawalAmount,
    accountVaultBalance,
    isInputNonZero,
    inputAmount,
    userAssetBalance,
  ])

  const renderErrorText = useCallback((_error: PoolValidationErrors) => {
    switch (_error) {
      case "insufficientBalance":
        return "Insufficient balance"
      case "withdrawLimitExceeded":
        return "Available limit exceeded"
      case "insufficientLiquidity":
        return "Insufficient liquidity"
      default:
        return ""
    }
  }, [])

  const handleInputChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const rawInput = e.target.value
      setInputAmount(rawInput)
    },
    []
  )

  const handleMaxButtonClick = useCallback(() => {
    const maxAmount =
      actionType === "deposit"
        ? userAssetBalance
        : tab === "instant"
          ? instantWithdrawalAmount
          : accountVaultBalance
    const input = formatUnits(maxAmount, assetDecimals)
    setInputAmount(input)

    if (inputRef.current) {
      inputRef.current.type = "text" // Temporarily change type to text
      inputRef.current.focus()
      inputRef.current.setSelectionRange(input.length, input.length)
      inputRef.current.type = "number"
    }
  }, [
    actionType,
    assetDecimals,
    userAssetBalance,
    instantWithdrawalAmount,
    accountVaultBalance,
    tab,
  ])

  const isPermit = useMemo(() => {
    return (
      depositAsset === Asset.EETH ||
      depositAsset === Asset.STETH ||
      depositAsset === Asset.WEETH
    )
  }, [depositAsset])

  const handleDeposit = useCallback(async () => {
    // recordDeposit({
    //   account,
    //   amount: formatEther(amountBn),
    //   utmSource,
    //   utmMedium,
    //   utmCampaign,
    // })
    setAction("deposit")
    if (active) {
      try {
        if (isPermit) {
          if (!permitSignature || !amountBn) {
            console.error("No permit signature or amount")
            return
          }
          if (depositAsset === Asset.WEETH) {
            ddWriteContractWithPermit({
              abi: getContractAbi("EtherfiVault"),
              address: vaultContract,
              functionName: "depositWithPermit",
              args: [
                amountBn,
                maxUint256,
                permitSignature?.v,
                permitSignature?.r,
                permitSignature?.s,
              ],
            })
          } else {
            dhWriteContractWithPermit({
              abi: getContractAbi("DepositHelper"),
              address: depositHelperContract,
              functionName: "depositWithPermit",
              args: [
                assetContractAddress,
                amountBn,
                maxUint256,
                permitSignature?.v,
                permitSignature?.r,
                permitSignature?.s,
              ],
            })
          }
        } else {
          // ETH
          dhETHWriteContract({
            abi: getContractAbi("DepositHelper"),
            address: depositHelperContract,
            functionName: "depositETH",
            account,
            args: [],
            value: amountBn,
          })
        }
      } catch (e) {
        console.error(e)
        onHide()
      }
    }
  }, [
    active,
    isPermit,
    permitSignature,
    amountBn,
    depositAsset,
    ddWriteContractWithPermit,
    vaultContract,
    dhWriteContractWithPermit,
    depositHelperContract,
    account,
    assetContractAddress,
    dhETHWriteContract,
    onHide,
    setAction,
  ])

  useEffect(() => {
    if (inputFocused && amountBn > 0n) {
      setShowTypingContainer(true)
      const timer = setTimeout(() => {
        setShowTypingContainer(false)
      }, 1000) // Show for 1 second

      return () => clearTimeout(timer)
    }
  }, [inputFocused, amountBn])

  const renderDepositButton = useCallback(() => {
    if (isVaultCapMax) {
      return (
        <FormButtonFade
          $show={show}
          $triggerAnimation={triggerAnimation}
          $delay={0.5}
        >
          <ActionButton disabled>
            <TooltipExplanation
              placement="top"
              maxWidth={150}
              explanation={`Deposit cap for ${info.fullName} has been reached`}
              renderContent={({ ref, ...triggerHandler }) => (
                <div ref={ref} {...triggerHandler}>
                  Deposit
                </div>
              )}
            />
          </ActionButton>
        </FormButtonFade>
      )
    } else if (isPermit) {
      const disable =
        permitSignature === undefined ||
        permittedAmount !== amountBn ||
        !isInputNonZero ||
        Boolean(error)
      return (
        <FormButtonFade
          $show={show}
          $triggerAnimation={triggerAnimation}
          $delay={0.4}
        >
          <ActionButton onClick={handleDeposit} disabled={disable}>
            {depositIsPending ? "DEPOSITING..." : actionType}
          </ActionButton>
        </FormButtonFade>
      )
    }

    return (
      <FormButtonFade
        $show={show}
        $triggerAnimation={triggerAnimation}
        $delay={0.5}
      >
        <ActionButton
          onClick={handleDeposit}
          disabled={Boolean(error) || !isInputNonZero || depositIsPending}
        >
          {depositIsPending ? "DEPOSITING..." : actionType}
        </ActionButton>
      </FormButtonFade>
    )
  }, [
    isVaultCapMax,
    isInputNonZero,
    isPermit,
    show,
    triggerAnimation,
    handleDeposit,
    error,
    depositIsPending,
    actionType,
    info.fullName,
    permitSignature,
    permittedAmount,
    amountBn,
  ])

  const handleInputFocus = () => setInputFocused(true)

  const isNetworkOk = active && chainId === mainnet.id

  const isComplete = withdrawAmount > 0n

  if (page === ActionModalEnum.TRANSACTION_STEP) {
    return <ActionModalTransaction actionType={actionType} />
  }

  if (actionType === "withdraw") {
    return (
      <ModalContainer>
        <ModalWrapper>
          <ActionModalTab
            tab={tab}
            setTab={setTab}
            setInputAmount={setInputAmount}
          >
            {isComplete ? (
              <>
                <BalanceTitle delay={0.1}>Receive Amount (weETH)</BalanceTitle>
                <FilterDropdownContainer>
                  <InputContainer delay={0.2}>
                    <FilterDropdownDisplay>
                      <img src={getAssetImg(Asset.WEETH)} alt={Asset.WEETH} />
                    </FilterDropdownDisplay>
                    <StyledBaseInput
                      type="number"
                      disabled
                      value={
                        withdrawAmount > 0n
                          ? parseFloat(formatEther(withdrawAmount)).toFixed(4)
                          : ""
                      }
                      placeholder="0"
                    />
                  </InputContainer>
                </FilterDropdownContainer>
                <ErrorText>{error ? renderErrorText(error) : ""}</ErrorText>
              </>
            ) : (
              <>
                <BalanceTitle delay={0.1}>
                  Enter Withdraw Amount (weETH)
                </BalanceTitle>
                <FilterDropdownContainer>
                  <InputContainer delay={0.2}>
                    <FilterDropdownDisplay>
                      <img src={getAssetImg(Asset.WEETH)} alt={Asset.WEETH} />
                    </FilterDropdownDisplay>
                    <StyledBaseInput
                      type="number"
                      className="form-control"
                      placeholder="0"
                      value={inputAmount}
                      onChange={handleInputChange}
                      onFocus={handleInputFocus}
                      step={"0.000001"}
                      ref={inputRef}
                    />
                    <BaseInputButton onClick={handleMaxButtonClick}>
                      MAX
                    </BaseInputButton>
                  </InputContainer>
                </FilterDropdownContainer>
                <ErrorText>{error ? renderErrorText(error) : ""}</ErrorText>
              </>
            )}
            {tab === "instant" ? (
              <>
                <ActionModalMintBurnAmount
                  inputAmount={inputAmount}
                  isDeposit={false}
                  showTyping={showTypingContainer}
                  account={account}
                />
                <ButtonsWrapper>
                  <ActionModalInstantWithdraw
                    amountBn={amountBn}
                    initiateWithdrawableAmount={accountVaultBalance}
                    instantWithdrawableAmount={instantWithdrawalAmount}
                    show={show}
                    triggerAnimation={triggerAnimation}
                    hasError={error !== undefined}
                    setPage={setPage}
                    setTxhashMain={setTxhashMain}
                    setInputAmount={setInputAmount}
                    setPermittedAmount={setPermittedAmount}
                    isNetworkOk={isNetworkOk}
                  />
                </ButtonsWrapper>
              </>
            ) : isComplete && tab === "initiate" ? (
              <>
                <ActionModalMintBurnAmountPlaceholder />
                <ButtonsWrapper>
                  <ActionModalCompleteWithdraw
                    withdrawAmount={withdrawAmount}
                    show={show}
                    triggerAnimation={triggerAnimation}
                    hasError={error !== undefined}
                    setPage={setPage}
                    setTxhashMain={setTxhashMain}
                    setInputAmount={setInputAmount}
                    setPermittedAmount={setPermittedAmount}
                    isNetworkOk={isNetworkOk}
                    withdrawalShares={withdrawalShares}
                  />
                </ButtonsWrapper>
              </>
            ) : (
              <>
                <ActionModalMintBurnAmountPlaceholder />
                <ButtonsWrapper>
                  <ActionModalInitiateWithdraw
                    amountBn={amountBn}
                    initiateWithdrawableAmount={accountVaultBalance}
                    instantWithdrawableAmount={instantWithdrawalAmount}
                    show={show}
                    triggerAnimation={triggerAnimation}
                    hasError={error !== undefined}
                    setPage={setPage}
                    setTxhashMain={setTxhashMain}
                    setInputAmount={setInputAmount}
                    setPermittedAmount={setPermittedAmount}
                    isNetworkOk={isNetworkOk}
                  />
                </ButtonsWrapper>
              </>
            )}
          </ActionModalTab>
        </ModalWrapper>
      </ModalContainer>
    )
  }

  return (
    <ModalContainer>
      <ModalWrapper>
        <BalanceTitle delay={0.1}>Enter Deposit Amount</BalanceTitle>
        <FilterDropdownContainer>
          <InputContainer delay={0.2}>
            <FilterDropdown
              options={info.depositableAssets.map((asset) => {
                return {
                  display: asset,
                  value: asset,
                  img: getAssetImg(asset),
                }
              })}
              value={depositAsset}
              displayValue={
                <FilterDropdownDisplay>
                  <img src={getAssetImg(depositAsset)} alt={depositAsset} />
                </FilterDropdownDisplay>
              }
              onSelect={(option: string) => {
                setDepositAsset(option as Asset)
              }}
              buttonConfig={{
                background: colors.background.two,
                activeBackground: colors.background.three,
                paddingHorizontal: 16,
                paddingVertical: 12,
                color: colors.primaryText,
              }}
              dropdownMenuConfig={{
                horizontalOrientation: "right",
                topBuffer: 16,
              }}
              className="flex-grow-1"
            />
            <StyledBaseInput
              type="number"
              className="form-control"
              placeholder="0"
              value={inputAmount}
              onChange={handleInputChange}
              onFocus={handleInputFocus}
              step={"0.000001"}
              ref={inputRef}
            />
            <BaseInputButton onClick={handleMaxButtonClick}>
              MAX
            </BaseInputButton>
          </InputContainer>
        </FilterDropdownContainer>
        <ErrorText>{error ? renderErrorText(error) : ""}</ErrorText>
        <ActionModalMintBurnAmount
          inputAmount={inputAmount}
          showTyping={showTypingContainer}
          account={account}
          isDeposit
        />
        <ButtonsWrapper>
          {!isNetworkOk ? (
            <BrandModalConnectWalletButton
              show={show}
              triggerAnimation={triggerAnimation}
            />
          ) : (
            <div style={{ display: "flex", flexDirection: "column", gap: 8 }}>
              <ActionModalPermit
                actionType={actionType}
                depositAsset={depositAsset}
                hasError={Boolean(error)}
                amountBn={amountBn}
                spender={
                  depositAsset === Asset.WEETH
                    ? vaultContract
                    : depositHelperContract
                }
                contractAddress={assetContractAddress}
                setPermitSignature={setPermitSignature}
                setPermittedAmount={setPermittedAmount}
                show={show}
                triggerAnimation={triggerAnimation}
              />
              {renderDepositButton()}
            </div>
          )}
          <DepositBalance
            depositAsset={depositAsset}
            userAssetBalance={userAssetBalance}
            assetDecimals={assetDecimals}
          />
        </ButtonsWrapper>
      </ModalWrapper>
      <ActionModalNote
        isCapReached={false}
        showDepositNote={
          depositAsset === Asset.ETH || depositAsset === Asset.EETH
        }
      />
    </ModalContainer>
  )
}

export default ActionModalHero
