import styled from "styled-components"

import Amplol from "../assets/png/assets/amplol.png"
import { ReactComponent as Logo } from "../assets/svg/logo.svg"

const LogoContainer = styled.div`
  display: flex;
  border-radius: 48px;
`

export const AppLogo = ({ size }: { size?: number }) => {
  return (
    <LogoContainer>
      <Logo height={size} width={size} />
    </LogoContainer>
  )
}

export const AmplolLogo = ({ size = 16 }: { size?: number }) => {
  return (
    <LogoContainer>
      <img width={size} height={size} src={Amplol} alt={"Amplol"} />
    </LogoContainer>
  )
}
