import { useQuery } from "@tanstack/react-query"
import axios, { AxiosError } from "axios"
import { z } from "zod"

import { Asset, getAssetId } from "../utils/asset"

const CoingeckoSchema = z.record(
  z.object({
    usd: z.number(),
  })
)

const coingeckoUrl = "https://pro-api.coingecko.com/api/v3/simple/price"

export const useAssetPrices = (asset: Asset) => {
  const assetId = getAssetId(asset).toLowerCase()
  const res = useQuery({
    queryKey: ["cg-assetPrice", assetId],
    queryFn: async () => {
      try {
        const ethereumUrl = `${coingeckoUrl}?ids=${assetId}&vs_currencies=usd`
        const res = await axios.get(ethereumUrl, {
          headers: {
            "x-cg-pro-api-key": process.env.REACT_APP_COINGECKO_API_KEY,
          },
        })
        const data = res.data
        const price = CoingeckoSchema.parse(data) as Record<
          string,
          { usd: number }
        >
        return price[assetId].usd
      } catch (error) {
        const axiosError = error as AxiosError
        console.error(axiosError)
        return 0
      }
    },
    refetchInterval: 1000 * 60 * 5,
    retry: (failureCount, error) => {
      const axiosError = error as AxiosError
      console.error(axiosError)
      return failureCount < 5
    },
    retryDelay: (attemptIndex) => Math.min(1000 * 2 ** attemptIndex, 30000),
  })
  return res
}
