import {
  useAccountModal,
  useChainModal,
  useConnectModal,
} from "@rainbow-me/rainbowkit"
import styled from "styled-components"
import { mainnet } from "viem/chains"
import { useAccount } from "wagmi"

import colors from "../designSystem/colors"
import sizes from "../designSystem/sizes"
import { PLACEHOLDER_ACCOUNT, truncateAddress } from "../utils/address"
import { ActionButton, delayedFade, FormButtonFade, Title } from "./common"
import Indicator from "./Indicator"

const WalletButtonText = styled(Title)<{
  $connected?: boolean
}>`
  font-size: 14px;
  line-height: 20px;

  @media (max-width: ${sizes.md}px) {
    font-size: 16px;
  }

  @media (max-width: 350px) {
    font-size: 13px;
  }

  color: ${({ $connected: connected }) =>
    connected ? colors.primaryText : colors.ribbonGreen};
`

const WalletButton = styled.div<{ delay: number }>`
  display: flex;
  margin: auto;
  height: 100%;
  justify-content: center;
  cursor: pointer;
  border-radius: 0;

  ${delayedFade}

  @media (max-width: ${sizes.md}px) {
    background: black;
  }

  > * {
    margin: auto 0;

    &:not(:last-child) {
      margin-right: 8px;
    }
  }
`

const ConnectButton = styled(ActionButton)`
  color: ${colors.threeJane};
`

export function BrandConnectWalletButton() {
  const { address, isConnected: active, chainId } = useAccount()
  const account = address ?? PLACEHOLDER_ACCOUNT
  const isNotMainnet = active && chainId !== mainnet.id

  const { openConnectModal } = useConnectModal()
  const { openAccountModal } = useAccountModal()
  const { openChainModal } = useChainModal()

  return (
    <WalletButton
      delay={0.2}
      onClick={() => {
        if (isNotMainnet) {
          openChainModal?.()
        } else if (active) {
          openAccountModal?.()
        } else {
          openConnectModal?.()
        }
      }}
    >
      {isNotMainnet ? (
        <WalletButtonText
          $connected={active}
          style={{
            color: colors.red,
          }}
        >
          Wrong Network
        </WalletButtonText>
      ) : active ? (
        <>
          <Indicator connected={active} />
          <WalletButtonText $connected={active}>
            {truncateAddress(account)}
          </WalletButtonText>
        </>
      ) : (
        <WalletButtonText $connected={active}>Connect Wallet</WalletButtonText>
      )}
    </WalletButton>
  )
}

export function BrandModalConnectWalletButton({
  show,
  triggerAnimation,
}: {
  show: boolean
  triggerAnimation: boolean
}) {
  const { isConnected: active, chainId } = useAccount()
  const isNotMainnet = active && chainId !== mainnet.id

  const { openConnectModal } = useConnectModal()
  const { openChainModal } = useChainModal()

  return isNotMainnet ? (
    <FormButtonFade
      $show={show}
      $triggerAnimation={triggerAnimation}
      $delay={0.4}
    >
      <ConnectButton onClick={() => openChainModal?.()}>
        Switch Network
      </ConnectButton>
    </FormButtonFade>
  ) : (
    <FormButtonFade
      $show={show}
      $triggerAnimation={triggerAnimation}
      $delay={0.4}
    >
      <ConnectButton onClick={() => openConnectModal?.()}>
        Connect Wallet
      </ConnectButton>
    </FormButtonFade>
  )
}
