import { Address } from "viem"
import { useReadContracts } from "wagmi"

import { getContractAbi, getContractAddr } from "../utils/address"
import { getVaultContract } from "../utils/vault"
import { useVaultName } from "./useVaultName"

const etherfiAbiAddress = {
  abi: getContractAbi("EtherfiVault"),
  address: getContractAddr("EtherfiVault"),
} as const

const useVault = () => {
  const vault = useVaultName()
  const vc = getVaultContract(vault)
  const vaultContractAddress = vc?.vault as Address | undefined

  const { data } = useReadContracts({
    contracts: [
      {
        ...etherfiAbiAddress,
        functionName: "cap",
      },
      {
        ...etherfiAbiAddress,
        functionName: "totalBalance",
      },
      {
        ...etherfiAbiAddress,
        functionName: "accountVaultBalance",
      },
      {
        ...etherfiAbiAddress,
        functionName: "decimals",
      },
      {
        ...etherfiAbiAddress,
        functionName: "pricePerShare",
      },
      {
        ...etherfiAbiAddress,
        functionName: "totalSupply",
      },
      {
        ...etherfiAbiAddress,
        functionName: "period",
      },
      {
        ...etherfiAbiAddress,
        functionName: "managementFee",
      },
      {
        ...etherfiAbiAddress,
        functionName: "currentOption",
      },
    ],
  })

  const cap = data?.[0].result
  const totalBalance = data?.[1].result
  const accountBalance = data?.[2].result
  const decimals = data?.[3].result
  const pricePerShare = data?.[4].result
  const totalSupply = data?.[5].result
  const period = data?.[6].result
  const managementFee = data?.[7].result
  const currentOption = data?.[8].result

  return {
    address: vaultContractAddress,
    decimals,
    cap,
    totalBalance,
    pricePerShare,
    totalSupply,
    period,
    managementFee,
    currentOption,
  }
}

export default useVault
