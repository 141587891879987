import { Address, zeroAddress } from "viem"

import addresses from "../addresses.json"
import EETHLogo from "../assets/png/assets/eeth.png"
import ETHLogo from "../assets/png/assets/eth.png"
import STETHLogo from "../assets/png/assets/steth.png"
import THREE_JANE_WEETHLogo from "../assets/png/assets/three_jane_weeth.png"
import WEETHLogo from "../assets/png/assets/weeth.png"
import ENALogo from "../assets/png/ethena.png"

export enum Asset {
  ETH = "ETH",
  STETH = "stETH",
  EETH = "eETH",
  WEETH = "weETH",
  ENA = "ENA",
  THREE_JANE_WEETH = "three_jane_weeth",
}

export const getDefaultSignificantDecimalsFromAssetDecimals = (
  decimals: number
) => {
  switch (decimals) {
    case 18:
      return 6
    case 8:
      return 5
    case 9:
      return 4
    case 6:
    default:
      return 2
  }
}

export const getAssetDecimals = (asset: Asset): number => {
  switch (asset) {
    default:
      return 18
  }
}

export const getAssetImg = (asset: Asset) => {
  switch (asset) {
    case Asset.ETH:
      return ETHLogo
    case Asset.STETH:
      return STETHLogo
    case Asset.EETH:
      return EETHLogo
    case Asset.WEETH:
      return WEETHLogo
    case Asset.THREE_JANE_WEETH:
      return THREE_JANE_WEETHLogo
    case Asset.ENA:
      return ENALogo
    default:
      throw new Error(`Asset ${asset} logo not found`)
  }
}

export const getAssetAddress = (asset: Asset): Address => {
  let addr: string
  switch (asset) {
    case Asset.STETH:
      addr = addresses.STETH
      break
    case Asset.EETH:
      addr = addresses.EETH
      break
    case Asset.WEETH:
      addr = addresses.WEETH
      break
    case Asset.ETH:
      addr = zeroAddress
      break
    case Asset.ENA:
      addr = addresses.ENA
      break
    default:
      throw new Error(`Asset ${asset} address not found`)
  }
  return addr as Address
}

export const getAssetId = (asset: Asset): string => {
  let assetId: string
  switch (asset) {
    case Asset.STETH:
      assetId = "ethereum"
      break
    case Asset.EETH:
      assetId = "ethereum"
      break
    case Asset.WEETH:
      assetId = "ethereum"
      break
    case Asset.ETH:
      assetId = "ethereum"
      break
    case Asset.ENA:
      assetId = "ethena"
      break
    default:
      throw new Error(`Asset ${asset} address not found`)
  }
  return assetId
}
