import { useLocation } from "react-router-dom";
import { useEffect, useMemo } from "react";

const useUtmParams = () => {
  const location = useLocation();
  const queryParams = useMemo(() => new URLSearchParams(location.search), [location.search]);

  const utmSource = queryParams.get('utm_source');
  const utmMedium = queryParams.get('utm_medium');
  const utmCampaign = queryParams.get('utm_campaign');

  // useEffect(() => {
  //   if (utmSource || utmMedium || utmCampaign) {
  //     console.log('UTM Parameters:', { utmSource, utmMedium, utmCampaign });
  //   }
  // }, [utmSource, utmMedium, utmCampaign]);

  return { utmSource, utmMedium, utmCampaign };
};

export default useUtmParams;
