import { Col } from "react-bootstrap"
import styled from "styled-components"

import { VaultsEnum } from "../../constants"
import colors from "../../designSystem/colors"
import { components } from "../../designSystem/components"
import sizes from "../../designSystem/sizes"
import { delayedUpwardFade, Title } from "../common"
import {
  Risks,
  VaultDescription,
  VaultParams,
  Yield,
} from "../Modal/VaultDetailsModal/Details"

const PillButton = styled.a<{ delay: number }>`
  padding-left: 16px;
  padding-right: 16px;
  border: 1px solid ${colors.primaryText};
  background-color: transparent;
  border-radius: 100px;
  height: 48px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: fit-content;
  transition: 0.2s ease-in-out;
  color: ${colors.primaryText} !important;
  ${delayedUpwardFade}

  &:hover {
    cursor: pointer;
    color: ${colors.primaryText} !important;
    text-decoration: none !important;

    svg {
      transition: 0.2s ease-in-out;
      transform: translate(4px, -4px);
    }
  }

  > * {
    margin: auto 0;

    &:not(:last-child) {
      margin-right: 8px;
    }
  }
`

const Details = styled.div<{ delay?: number }>`
  align-items: center;
  padding: 20px;
  width: 100%;

  ${delayedUpwardFade}

  @media (max-width: ${sizes.lg}px) {
    padding: 16px;
  }

  a:not(${PillButton}) {
    color: ${colors.text};
    text-decoration: underline;

    svg {
      margin-left: 8px;
    }

    &:hover {
      svg {
        transition: 0.2s ease-in-out;
        transform: translate(4px, -4px);
      }
    }
  }
`

const VaultDetailsWrapper = styled.div`
  height: 100%;
  width: 100%;

  > ${Details} {
    display: block;
    width: 100%;
  }

  @media (max-width: ${sizes.lg}px) {
    padding-bottom: ${components.footer * 4}px;
  }
`

const StyledTitle = styled(Title)`
  font-size: 22px;
`

const DetailsIndex = styled.span`
  display: block;
  color: ${colors.quaternaryText};
  font-size: 12px;
`

interface VaultDetailsProps {
  vault: VaultsEnum
}

export function VaultDetails({ vault }: VaultDetailsProps) {
  return (
    <Col xs={12} md={6}>
      <VaultDetailsWrapper>
        <Details delay={0.25}>
          <DetailsIndex>01</DetailsIndex>
          <StyledTitle>Earn</StyledTitle>
          <Yield vault={vault} />
        </Details>
        <Details delay={0.5}>
          <DetailsIndex>02</DetailsIndex>
          <StyledTitle>Structure</StyledTitle>
          <VaultDescription vault={vault} />
        </Details>
        <Details delay={0.75}>
          <DetailsIndex>03</DetailsIndex>
          <StyledTitle>Parameters</StyledTitle>
          <VaultParams vault={vault} />
        </Details>
        <Details delay={1.0}>
          <DetailsIndex>04</DetailsIndex>
          <StyledTitle>Risks</StyledTitle>
          <Risks vault={vault} />
        </Details>
      </VaultDetailsWrapper>
    </Col>
  )
}
