import { createContext, useContext, useRef } from "react"
import { createStore, useStore } from "zustand"

import { ActionType } from "./components/Modal/ActionModal/types"

interface ThreeJaneGlobal {
  showOnboarding: boolean
  setShowOnboarding: (show: boolean) => void
  amountBn: bigint
  setAmountBn: (amount: bigint) => void
  txError: boolean | null
  setTxError: (isError: boolean | null) => void
  showToast: boolean
  setShowToast: (show: boolean) => void
  action: ActionType
  setAction: (action: ActionType) => void
}

const createThreeJaneGlobalState = () =>
  createStore<ThreeJaneGlobal>((set) => ({
    showOnboarding: true,
    setShowOnboarding: (show: boolean) =>
      set((state) => ({
        ...state,
        showOnboarding: show,
      })),
    amountBn: 0n,
    setAmountBn: (amount: bigint) =>
      set((state) => ({
        ...state,
        amountBn: amount,
      })),
    txError: null,
    setTxError: (isError: boolean | null) =>
      set((state) => ({
        ...state,
        txError: isError,
      })),
    showToast: false,
    setShowToast: (show: boolean) =>
      set((state) => ({
        ...state,
        showToast: show,
      })),
    action: "deposit",
    setAction: (action: ActionType) =>
      set((state) => ({
        ...state,
        action: action,
      })),
  }))

type GlobalStore = ReturnType<typeof createThreeJaneGlobalState>

const GlobalContext = createContext<GlobalStore | null>(null)

type ThreeJaneProviderProps = React.PropsWithChildren<{}>

export function ThreeJaneProvider({ children }: ThreeJaneProviderProps) {
  const storeRef = useRef<GlobalStore | null>(null)
  if (!storeRef.current) {
    storeRef.current = createThreeJaneGlobalState()
  }
  return (
    <GlobalContext.Provider value={storeRef.current}>
      {children}
    </GlobalContext.Provider>
  )
}

export function useThreeJaneContext<T>(
  selector: (state: ThreeJaneGlobal) => T
): T {
  const store = useContext(GlobalContext)
  if (!store) throw new Error("Missing GlobalContext.Provider in the tree")
  return useStore(store, selector)
}
