import { Asset } from "../utils/asset"

export const LINKS = {
  risks: "https://info.3jane.xyz/architecture/risks",
  etherscan: "https://etherscan.io",
  amplol: "https://info.3jane.xyz/amplol/introduction",
  amplolEmissions: "https://info.3jane.xyz/amplol/introduction/emissions",
  terms: "https://app.3jane.xyz/terms",
  policy: "https://app.3jane.xyz/policy",
  disclaimer: "https://app.3jane.xyz/disclaimer",
  whitepaper: "https://app.3jane.xyz/whitepaper",
  amplolContract:
    "https://etherscan.io/token/0xEadF1dE23Cece2109Cb72517da1b7b710b7509e5",
}

export const SOCIAL_LINKS = {
  twitter: "https://twitter.com/3janexyz",
  github: "https://github.com/3jane-protocol",
  about:
    "https://mirror.xyz/0x6F75b5FD6DD8aAbbEBCD2d186a6e0386E3e2B0EB/rcEbyaNa9NDhUHuDYBHpDboSuyfPlCUyFqAEnYQnfNI",
  discord: "https://discord.com/invite/xzfNggwBjH",
  docs: "https://info.3jane.xyz/3jane/introduction",
}

export enum VaultsEnum {
  ETHERFI = "eeth-x-c",
  ETHENA = "ena-x-c",
}

export type IVaultInfo = {
  fullName: string

  // Assets that needs to be deposited through the helper
  depositableAssets: Asset[]

  // This indicates the native asset, can be deposited directly into the vault.
  // also uses the icons of this asset
  nativeAsset: Asset

  vaultAsset: Asset
}

export const VAULT_INFO: {
  [vault in VaultsEnum]: IVaultInfo
} = {
  [VaultsEnum.ETHERFI]: {
    fullName: "ETHERFI EETH-X-C",
    nativeAsset: Asset.WEETH,
    vaultAsset: Asset.THREE_JANE_WEETH,
    depositableAssets: [Asset.ETH, Asset.STETH, Asset.EETH, Asset.WEETH],
  },
  [VaultsEnum.ETHENA]: {
    fullName: "ETHENA ENA-X-C",
    nativeAsset: Asset.ENA,
    vaultAsset: Asset.ENA,
    depositableAssets: [Asset.ENA, Asset.STETH, Asset.ETH],
  },
}

export const DECIMALS_OPTIONS = 8
export const DECIMALS_ETH = 18

const MILLION = Math.pow(10, 6)
export const MAX_VAULT_CAP = 5 * MILLION

export const EXPECTED_TVL = 500 * MILLION
