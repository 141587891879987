import React, { PropsWithChildren } from "react"
import styled from "styled-components"

import colors from "../../../designSystem/colors"
import { components } from "../../../designSystem/components"
import { Title } from "../../common"
import { ActionModalEnum, ActionType } from "./types"

const HeaderContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  border-radius: 0;
  min-height: ${components.header}px;
  border-bottom: 1px solid ${colors.border};
  z-index: 1;
  color: ${colors.primaryText};

  > * {
    margin: auto 0;

    &:not(:last-child) {
      margin-right: 8px;
    }
  }
`

const HeaderText = styled(Title)`
  font-size: 16px;
  line-height: 20px;
`

interface ActionModalHeaderProps extends PropsWithChildren {
  actionType: ActionType
  page: ActionModalEnum
}

const ActionModalHeader: React.FC<ActionModalHeaderProps> = ({
  page,
  actionType,
  children,
}) => {
  return (
    <HeaderContainer>
      <HeaderText>
        {actionType === "deposit" &&
          (page === ActionModalEnum.PREVIEW ? "Deposit" : "Depositing")}
        {actionType === "withdraw" &&
          (page === ActionModalEnum.PREVIEW ? "Withdraw" : "Withdrawing")}{" "}
      </HeaderText>
      {children}
    </HeaderContainer>
  )
}

export default ActionModalHeader
