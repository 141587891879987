import React, { useCallback, useEffect, useRef, useState } from "react"
import { useConnectModal } from "@rainbow-me/rainbowkit"
import { AnimatePresence, motion } from "framer-motion"
import styled from "styled-components"

import { LINKS } from "../../constants"
import colors from "../../designSystem/colors"
import { useThreeJaneContext } from "../../store"
import { Button } from "../common"
import HeroContent from "../HeroContent"
import { LinkWithIcon } from "../LinkWithIcon"
import { OnboardingGif } from "./OnboardingGif"

const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5); /* Optional: to darken the background */
  backdrop-filter: blur(10px);
  z-index: 999; /* Ensure it's behind the container */
`

const ExplainerContainer = styled.div`
  border: 1px solid ${colors.background.four};
  display: flex;
  flex-direction: column;
  overflow: hidden;
  width: 360px;
  height: 480px;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1000; /* Ensure it's above the overlay */
  background: ${colors.background.three};
`

const ExplainerSection = styled.div<{ height: number }>`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  justify-content: center;
  overflow: show;
  align-items: center;
  height: fit-content;
  z-index: 1;
  display: flex;
  flex-direction: column;
`

const SkipButton = styled(Button)`
  background-color: ${colors.background.four};
  color: ${colors.primaryText};
  height: 48px;
  width: 100%;
  border-radius: 0;
  font-size: 14px;
  border: none;
`

const ModalContainer = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`

const ExplanationStepList = ["step1", "step2", "step3"] as const

type ExplanationStep = (typeof ExplanationStepList)[number]

const SHOW_ONBOARDING_KEY = "3jane-show-onboarding-update-key"

export const OnboardingDialog: React.FC = () => {
  const containerRef = useRef(null)
  const { setShowOnboarding, showOnboarding } = useThreeJaneContext((s) => ({
    setShowOnboarding: s.setShowOnboarding,
    showOnboarding: s.showOnboarding,
  }))
  const { openConnectModal } = useConnectModal()
  const [step, setStep] = useState<ExplanationStep>(ExplanationStepList[0])
  const [step2Toggle, setStep2Toggle] = useState(false)

  const setShowOnboardingCallback = useCallback(() => {
    setShowOnboarding(false)
    localStorage.setItem(SHOW_ONBOARDING_KEY, "false")
  }, [setShowOnboarding])

  useEffect(() => {
    const show = localStorage.getItem(SHOW_ONBOARDING_KEY) !== "false"
    if (!show) setShowOnboardingCallback()
  }, [setShowOnboardingCallback])

  useEffect(() => {
    if (step === "step2") {
      // Start toggling
      const int = setInterval(() => {
        setStep2Toggle((toggle) => !toggle)
      }, 8000)
      return () => clearInterval(int)
    }
    return () => {}
  }, [step])

  const handleContinue = () => {
    if (step === "step1") {
      setStep("step3")
    }
    // else if (step === "step2") {
    //   setStep("step3")
    // }
    else {
      setShowOnboardingCallback()
      openConnectModal?.()
    }
  }

  if (!showOnboarding) return null

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ delay: 1 }}
    >
      <Overlay />
      <ExplainerContainer ref={containerRef}>
        <div
          style={{
            borderBottom: `1px solid ${colors.background.four}`,
            padding: "16px",
            textAlign: "center",
          }}
        >
          <span style={{ color: colors.primaryText }}>Welcome to 3Jane</span>
        </div>
        <ExplainerSection
          height={360}
          style={{
            height: "75%",
            width: "100%",
          }}
        >
          <AnimatePresence initial={true}>
            <motion.div
              key={step}
              initial={{
                opacity: 0,
                translateX: -20,
              }}
              animate={{
                opacity: 1,
                translateX: 0,
              }}
              transition={{
                duration: 0.25,
                type: "keyframes",
                ease: "easeInOut",
              }}
              style={{
                height: "100%",
                width: "100%",
              }}
            >
              {step === "step1" && (
                <ModalContainer>
                  <HeroContent
                    word="3Jane"
                    size={{
                      desktop: 64,
                      mobile: 36,
                    }}
                  />
                </ModalContainer>
              )}
              {step === "step2" &&
                (step2Toggle ? (
                  <ModalContainer>
                    <OnboardingGif
                      gifSrc="/gif/2.gif"
                      description="As more users deposit, AMPLOL is minted to existing holders  proportional to TVL — earning more AMPLOL. As TVL increases, your AMPLOL balance increases."
                    />
                  </ModalContainer>
                ) : (
                  <ModalContainer>
                    <OnboardingGif
                      gifSrc="/gif/1.gif"
                      description="Deposit ETH assets into EETH-X-C to earn ETH derivatives yield. Depositors are instantly minted rebased AMPLOL."
                    />
                  </ModalContainer>
                ))}
              {step === "step3" && (
                <p
                  style={{
                    color: colors.secondaryText,
                    padding: "16px",
                    textAlign: "left",
                  }}
                >
                  By clicking 'Continue' you agree to the{" "}
                  <LinkWithIcon href={LINKS.terms}>
                    Terms & Conditions
                  </LinkWithIcon>{" "}
                  and{" "}
                  <LinkWithIcon href={LINKS.policy}>
                    Privacy Policy
                  </LinkWithIcon>
                  .
                </p>
              )}
            </motion.div>
          </AnimatePresence>
        </ExplainerSection>
        <div
          style={{
            marginTop: "auto",
            padding: "8px",
            zIndex: 1000,
          }}
        >
          <SkipButton color={colors.background.two} onClick={handleContinue}>
            Continue
          </SkipButton>
        </div>
      </ExplainerContainer>
    </motion.div>
  )
}
