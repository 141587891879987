import { Col } from "react-bootstrap"
import styled, { css, keyframes } from "styled-components"
import { formatEther } from "viem"

import { EXPECTED_TVL, LINKS } from "../../constants"
import colors from "../../designSystem/colors"
import { components } from "../../designSystem/components"
import { useAmplol } from "../../hooks/useAmplol"
import { useTickingTotalSupply } from "../../hooks/useTickingTotalSupply"
import { useVaultSize } from "../../hooks/useVaultSize"
import { formatAmount } from "../../utils/math"
import { fadeIn, Title } from "../common"
import HelpInfo from "../HelpInfo"
import { LinkWithIcon } from "../LinkWithIcon"
import { AmplolLogo, AppLogo } from "../Logo"
import TooltipExplanation from "../TooltipExplanation"

const DetailContainer = styled.div<{ $show?: boolean; $delay?: number }>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  ${({ $show, $delay }) => {
    return (
      $show &&
      css`
        opacity: 0;

        &:disabled {
          opacity: 0;
        }

        animation: ${fadeIn} 1s ease-in-out forwards;
        animation-delay: ${$delay || 0}s;
      `
    )
  }}
`

const DetailTitle = styled.div`
  font-size: 12px;
  color: ${colors.primaryText}52;
  text-align: center;
`

const DetailText = styled(Title)`
  font-size: 14px;
  text-wrap: balance;
`

const HeaderWrapper = styled.div<{
  $isActive?: boolean
  delay: number
  disabled?: boolean
  $tooltip?: boolean
  width?: string
}>`
  font-size: 12px;
  border: none;
  border-radius: 0;
  width: ${({ width }) => (width ? width : "50%")};
  color: ${({ $isActive }) =>
    $isActive ? colors.primaryText : colors.tertiaryText};

  &:hover {
    transition: 0.2s;
    color: ${colors.primaryText};
  }
  &:disabled {
    color: ${colors.tertiaryText};
    pointer-events: ${(props) => (props.$tooltip ? "auto" : "none")};
  }

  &:not(:last-child) {
    border-right: 1px solid ${colors.border};
  }
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`

const changeColor = keyframes`
  0% {
    color: ${colors.primaryText};
  }
  50% {
    color: ${colors.primaryText};
  }
  100% {
    color: ${colors.primaryText};
  }
`

const AnimatedNumber = styled.span`
  animation: ${changeColor} 1.5s ease-in-out;
`

const StatsDetail = ({
  title,
  value,
  link,
  icon,
  disableAnimation,
  transform,
  shine,
}: {
  title: React.ReactNode
  value: string | number
  link?: string
  icon?: React.ReactNode
  disableAnimation?: boolean
  transform?: boolean
  shine?: boolean
}) => (
  <DetailContainer $show={true} $delay={0.1}>
    <DetailTitle>
      {link ? (
        <LinkWithIcon noUnderline color={"#A1A1A9"} href={link}>
          {title}
        </LinkWithIcon>
      ) : (
        <span style={{ color: "#A1A1A9" }}>{title}</span>
      )}
    </DetailTitle>
    <DetailText>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          gap: 8,
          marginLeft: "-15px",
          textTransform: transform ? "none" : "capitalize",
          color: shine ? "rgb(0,255,26)" : "rgb(255,255,255)",
        }}
      >
        {icon}
        {disableAnimation ? (
          <span>{value}</span>
        ) : (
          <AnimatedNumber key={value}>{value}</AnimatedNumber>
        )}
      </div>
    </DetailText>
  </DetailContainer>
)

const withStatsData = (Component: React.FC<any>) => () => {
  const { totalSupply, amplolRebaseDisplay } = useAmplol()
  const { displayedTotalSupply } = useTickingTotalSupply({ totalSupply })
  const { multiple, totalBalanceUSD } = useVaultSize({})

  return (
    <Component
      totalSupply={displayedTotalSupply}
      rebaseDisplay={amplolRebaseDisplay}
      multiple={multiple}
      totalBalanceUSD={totalBalanceUSD}
    />
  )
}

const StatsHeaderVaultHelper = ({
  totalSupply,
  rebaseDisplay,
  multiple,
  totalBalanceUSD,
}: {
  totalSupply: bigint
  rebaseDisplay: string
  multiple: number
  totalBalanceUSD: number
}) => (
  <Col
    md={12}
    lg={6}
    style={{
      display: "flex",
      placeSelf: "center",
      height: "100%",
    }}
  >
    <HeaderWrapper
      delay={0.2}
      disabled={false}
      $tooltip={false}
      $isActive={true}
      style={{ fontSize: "1rem" }}
    >
      <StatsDetail
        title="AMPLOL Minted"
        value={parseFloat(formatEther(totalSupply)).toLocaleString()}
        link={LINKS.amplolContract}
        icon={<AmplolLogo size={16} />}
      />
    </HeaderWrapper>
    <HeaderWrapper
      delay={0.2}
      disabled={false}
      $tooltip={false}
      $isActive={true}
      style={{ fontSize: "1rem" }}
    >
      <StatsDetail
        title={
          <div
            style={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <span>AMPLOL Leverage Ratio</span>
            <TooltipExplanation
              placement="bottom"
              maxWidth={200}
              explanation={
                <span
                  style={{
                    fontSize: "12px",
                    textWrap: "balance",
                    display: "inline",
                    maxWidth: `${components.modalWidth}px`,
                    whiteSpace: "normal",
                  }}
                >
                  Your leveraged upside to <span
                    style={{
                      display: "inline-flex",
                      verticalAlign: "middle",
                    }}
                  >
                    <AppLogo size={14} />{" "}
                  </span> 3Jane if you deposit now:{" "}
                  <span
                    style={{
                      color: colors.green,
                    }}
                  >
                    {formatAmount(multiple, true)}x
                  </span>{" "}
                  <span
                    style={{
                      color: colors.primaryText,
                    }}
                  >
                    (${formatAmount(EXPECTED_TVL, false)} / $
                    {formatAmount(totalBalanceUSD, false)} TVL). <br /> <br />
                  </span>{" "}
                  <LinkWithIcon
                    color={colors.secondaryText}
                    href={LINKS.amplolEmissions}
                  >
                    Read more
                  </LinkWithIcon>
                </span>
              }
              renderContent={({ ref, ...triggerHandler }) => (
                <HelpInfo
                  containerRef={ref}
                  {...triggerHandler}
                  style={{
                    color: colors.secondaryText,
                    marginLeft: "4px",
                    display: "inline-block",
                  }}
                >
                  <span
                    style={{
                      fontSize: "8px",
                    }}
                  >
                    i
                  </span>
                </HelpInfo>
              )}
            />
          </div>
        }
        value={`${formatAmount(multiple, false)}x`}
        icon={<AmplolLogo size={16} />}
        transform
        shine
        disableAnimation
      />
    </HeaderWrapper>
  </Col>
)

export const StatsHeaderVault = withStatsData(StatsHeaderVaultHelper)
