import React from "react"
import { Toast } from "react-bootstrap"

interface ErrorToastProps {
  show: boolean
  onClose: () => void
  errorMessage: string
}

const ErrorToast: React.FC<ErrorToastProps> = ({
  show,
  onClose,
  errorMessage,
}) => {
  return (
    <Toast
      bg="danger"
      onClose={onClose}
      show={show}
      delay={5000}
      autohide
      style={{ color: "white" }}
    >
      <Toast.Header>
        <strong className="me-auto">Error</strong>
      </Toast.Header>
      <Toast.Body>
        <p>{errorMessage}</p>
      </Toast.Body>
    </Toast>
  )
}

export default ErrorToast
