import { Col, Row } from "react-bootstrap"
import styled from "styled-components"

import colors from "../../designSystem/colors"
import { components } from "../../designSystem/components"
import sizes from "../../designSystem/sizes"
import { BrandConnectWalletButton } from "../BrandConnectWalletButton"
import MobileHeader from "../MobileHeader"
import { StatsHeaderVault } from "../StepsHeader/StatsHeader"

const HeaderRow = styled(Row)<{ mobile?: boolean }>`
  height: ${components.header}px;
  border-bottom: 1px solid ${colors.border};
  box-sizing: border-box;
  z-index: 1;
  margin-left: 0px;
  background: black;
  ${(props) => props.mobile === true && `display: none;`}
  > * {
    padding: 0;

    &:not(:last-child) {
      border-right: 1px solid ${colors.border};
    }
  }

  @media (max-width: ${sizes.lg}px) {
    ${(props) => props.mobile === true && `display: flex;`}
  }
`

const HeaderWalletCol = styled(Col)`
  height: ${components.header}px;

  @media (max-width: ${sizes.lg}px) {
    display: none;
  }
`

export const HorizontalHeader = () => {
  return (
    <>
      <MobileHeader />
      <HeaderRow>
        <StatsHeaderVault />
        <HeaderWalletCol md={0} lg={6}>
          <BrandConnectWalletButton />
        </HeaderWalletCol>
      </HeaderRow>
    </>
  )
}
