import styled from "styled-components"
import { Address, parseEther } from "viem"

import { DECIMALS_ETH } from "../../../constants"
import colors from "../../../designSystem/colors"
import useLoadingText from "../../../hooks/useLoadingText"
import { useUserAccount } from "../../../hooks/useUserAccount"
import { useVaultSize } from "../../../hooks/useVaultSize"
import { formatBigint } from "../../../utils/math"
import { delayedFade } from "../../common"
import { AmplolLogo } from "../../Logo"
import { formatAmount } from "../../../utils/math"

const ADJUSTMENT = BigInt(8_888)

const MintedText = styled.span`
  color: ${colors.secondaryText};
  flex-shrink: 0;
  margin-right: 4px;
`

const MintedValue = styled.span`
  font-weight: 700;
  margin-left: 4px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`

const MintedContainer = styled.div<{
  delay?: number
  $border?: boolean
}>`
  justify-content: center;
  display: flex;
  align-items: center;
  border: ${({ $border: border }) => (border ? "1px solid" : "none")}
    ${colors.primaryText};
  border-radius: 24px;
  padding: 10px 8px;
  background-color: ${({ $border: border }) =>
    border ? colors.background.two : "inherit"};
  color: ${colors.primaryText};
  font-size: 12px;
  height: 30px;
  gap: 4px;
  width: fit-content;
  margin-left: auto;
  margin-right: auto;
  ${delayedFade};
`

interface ActionModalMintedAmountProps {
  inputAmount: string
  isDeposit: boolean
  account: Address
  showTyping?: boolean
}

export const ActionModalMintBurnAmountPlaceholder: React.FC = () => {
  return <MintedContainer />
}

export const ActionModalMintBurnAmount: React.FC<
  ActionModalMintedAmountProps
> = ({ inputAmount, isDeposit, account, showTyping }) => {
  const loadingText = useLoadingText("")

  const { amplolBalance } = useUserAccount({ account })
  const mintAmount = parseEther(inputAmount) * ADJUSTMENT
  const amount = isDeposit ? mintAmount : amplolBalance
  const { multiple, totalBalanceUSD } = useVaultSize({})

  if (showTyping) return <MintedContainer>{loadingText}</MintedContainer>

  return (
    <MintedContainer $border={true}>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          gap: 8,
        }}
      >
        <MintedText>{isDeposit ? "Mint" : "Burn"}</MintedText>
        <AmplolLogo size={20} />
      </div>
      <MintedValue style={{ color: amount > 0 ? colors.primaryText : "" }}>
        ~
        {formatBigint({
          n: amount,
          decimals: DECIMALS_ETH,
          showDecimals: amount < parseEther("1"),
        })}{" "}
      </MintedValue>
      <span style={{ color: colors.green, fontWeight: 700 }}>
        ({formatAmount(multiple, false)}x)
      </span>
    </MintedContainer>
  )
}
