import { useCallback, useRef, useState } from "react"
import { AnimatePresence, motion } from "framer-motion"
import styled from "styled-components"

import { ReactComponent as ExternalLink } from "../assets/svg/externalLink.svg"
import colors from "../designSystem/colors"
import sizes from "../designSystem/sizes"
import theme from "../designSystem/theme"
import { useBoundingclientrect } from "../hooks/useBoundingclientrect"
import useOutsideAlerter from "../hooks/useOutsideAlerter"
import useScreenSize from "../hooks/useScreenSize"
import { capitalize } from "../utils/string"
import ButtonArrow from "./ButtonArrow"
import { BaseButton, Title } from "./common"

interface FilterDropdownButtonConfig {
  background?: string
  activeBackground?: string
  paddingHorizontal?: number
  paddingVertical?: number
  header?: boolean
  onPage?: boolean
  color?: string
}

interface FilterDropdownMenuConfig {
  horizontalOrientation?: "left" | "right"
  topBuffer: number
  backgroundColor?: string
}

interface MenuItemConfig {
  firstItemPaddingTop?: string
  lastItemPaddingBottom?: string
  padding?: string
}

interface MenuItemTextConfig {
  fontSize?: number
  letterSpacing?: number
  lineHeight?: number
}

const Filter = styled.div`
  position: relative;
  justify-content: center;
  align-items: center;
  z-index: 2000;
  background-color: black;
`

const FilterButton = styled(BaseButton)<{
  $config: FilterDropdownButtonConfig
  $active: boolean
}>`
  display: flex;
  width: 60px;
  justify-content: center;
  align-items: center;
  padding: ${(props) =>
    `${props.$config.paddingVertical || 8}px ${
      props.$config.paddingHorizontal || 12
    }px`};
  opacity: ${(props) =>
    props.$active || props.$config.onPage
      ? "1"
      : props.$config.header
        ? "0.48"
        : "1"};
`

const FilterButtonText = styled(Title)<{ $config: FilterDropdownButtonConfig }>`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: ${(props) => (props.$config.header ? `12px` : `14px`)};
  letter-spacing: ${(props) => (props.$config.header ? `1.5px` : ``)};
  color: ${(props) => props.$config.color || colors.text};
  text-transform: uppercase;
  align-self: center;
`

const FilterDropdownMenu = styled(motion.div)<{
  $isOpen: boolean
  $verticalOrientation: "top" | "bottom"
  $buttonPaddingVertical: number
  $config: FilterDropdownMenuConfig
}>`
  ${(props) =>
    props.$isOpen
      ? `
          position: absolute;
          z-index: 2000;
          ${(() => {
            switch (props.$config.horizontalOrientation) {
              case "left":
                return `left: 0px;`
            }
          })()}
          ${(() => {
            switch (props.$verticalOrientation) {
              case "top":
                return `bottom: 48px;`
              case "bottom":
              default:
                return `top: calc(21px + ${
                  props.$buttonPaddingVertical * 2
                }px + ${props.$config.topBuffer}px);`
            }
          })()}

          width: fit-content;
          background-color: ${
            props.$config.backgroundColor || colors.background.two
          };
          border-radius: ${theme.border.radius};
        `
      : `
          display: none;
        `}
`

const MenuItem = styled.div<MenuItemConfig>`
  padding: ${(props) => props.padding || "8px 16px 8px 16px"};
  opacity: 1;
  display: flex;
  align-items: center;

  > img {
    width: 32px;
    height: 32px;
    margin-right: 16px;
  }

  &:first-child {
    padding-top: ${(props) =>
      props.firstItemPaddingTop ? props.firstItemPaddingTop : "16px"};
  }

  &:last-child {
    padding-bottom: ${(props) =>
      props.lastItemPaddingBottom ? props.lastItemPaddingBottom : "16px"};
  }

  svg {
    opacity: 0.64;
  }

  &:hover {
    span {
      color: ${colors.primaryText};
    }
    svg {
      opacity: 1;
    }
  }
`

const MenuItemText = styled(Title)`
  color: ${colors.primaryText}A3;
  white-space: nowrap;
`

type FilterDropdownProps = Omit<
  React.HTMLAttributes<HTMLDivElement>,
  "onSelect"
> & {
  options: Array<
    | string
    | { display: string; value: string; externalLink?: boolean; img?: string }
  >
  value: string
  displayValue?: string | JSX.Element
  onSelect: (option: string) => void
  buttonConfig?: FilterDropdownButtonConfig
  dropdownMenuConfig?: FilterDropdownMenuConfig
  menuItemConfig?: MenuItemConfig
  menuItemTextConfig?: MenuItemTextConfig
}

const FilterDropdown: React.FC<FilterDropdownProps> = ({
  options,
  value,
  displayValue,
  onSelect,
  buttonConfig = {},
  dropdownMenuConfig = {
    topBuffer: 8,
    horizontalOrientation: "left",
  },
  menuItemConfig,
  menuItemTextConfig,
  ...props
}) => {
  const ref = useRef<HTMLDivElement>(null)
  const [open, setOpen] = useState(false)
  const { height, width } = useScreenSize()
  const dropdownBoundingRect = useBoundingclientrect(ref)

  useOutsideAlerter(ref, () => {
    setOpen(false)
  })

  const renderMenuItem = useCallback(
    (
      title: string,
      externalLink: boolean,
      onClick: () => void,
      img?: string
    ) => (
      <MenuItem onClick={onClick} role="button" key={title} {...menuItemConfig}>
        {img && <img src={img} alt={title} />}
        <MenuItemText
          fontSize={menuItemTextConfig?.fontSize || 14}
          letterSpacing={menuItemTextConfig?.letterSpacing || undefined}
          $lineHeight={menuItemTextConfig?.lineHeight || 20}
        >
          {title}
        </MenuItemText>
        {externalLink && (
          <div className="ml-2">
            <ExternalLink />
          </div>
        )}
      </MenuItem>
    ),
    [
      menuItemConfig,
      menuItemTextConfig?.fontSize,
      menuItemTextConfig?.letterSpacing,
      menuItemTextConfig?.lineHeight,
    ]
  )

  const getVerticalOrientation = useCallback(() => {
    /**
     * Height of dropdown: 48px
     * Height of each option: 36px
     * Total dropdown margin: 16px
     */
    if (
      dropdownBoundingRect &&
      dropdownBoundingRect.top +
        48 +
        options.length * 36 +
        16 +
        (width > sizes.lg
          ? theme.footer.desktop.height
          : theme.footer.mobile.height) >
        height
    ) {
      return "top"
    }

    return "bottom"
  }, [width, height, dropdownBoundingRect, options])

  return (
    <Filter {...props} ref={ref}>
      <FilterButton
        role="button"
        $active={open}
        onClick={() => {
          setOpen((open) => !open)
        }}
        $config={buttonConfig}
      >
        <FilterButtonText $config={buttonConfig}>
          <span>{displayValue || value}</span>
          <ButtonArrow
            $isOpen={open}
            color={buttonConfig.color}
            style={{
              marginTop: 8,
            }}
          />
        </FilterButtonText>
      </FilterButton>
      <AnimatePresence>
        <FilterDropdownMenu
          key={open.toString()}
          $isOpen={open}
          $verticalOrientation={getVerticalOrientation()}
          $buttonPaddingVertical={buttonConfig.paddingVertical || 8}
          $config={dropdownMenuConfig}
          initial={{
            opacity: 0,
            y: 20,
          }}
          animate={{
            opacity: 1,
            y: 0,
          }}
          exit={{
            opacity: 0,
            y: 20,
          }}
          transition={{
            type: "keyframes",
            duration: 0.2,
          }}
        >
          {options.map(
            (
              filterOption:
                | string
                | {
                    display: string
                    value: string
                    externalLink?: boolean
                    img?: string
                  }
            ) =>
              typeof filterOption === "string"
                ? renderMenuItem(capitalize(filterOption), false, () => {
                    onSelect(filterOption)
                    setOpen(false)
                  })
                : renderMenuItem(
                    capitalize(filterOption.display),
                    filterOption.externalLink
                      ? filterOption.externalLink
                      : false,
                    () => {
                      onSelect(filterOption.value)
                      setOpen(false)
                    },
                    filterOption.img
                  )
          )}
        </FilterDropdownMenu>
      </AnimatePresence>
    </Filter>
  )
}

export default FilterDropdown
