import styled from "styled-components"

import Amplol from "../assets/png/assets/amplol.png"
import Eeth from "../assets/png/assets/eeth.png"
import Eigenlayer from "../assets/png/assets/eigenlayer.png"
import Eth from "../assets/png/assets/eth.png"
import Etherfi from "../assets/png/etherfi.png"
import colors from "../designSystem/colors"
import { useApy } from "../hooks/useApy"
import useVault from "../hooks/useVault"
import { useVaultSize } from "../hooks/useVaultSize"
import { formatAmount } from "../utils/math"
import HelpInfo from "./HelpInfo"
import TooltipExplanation from "./TooltipExplanation"

const YieldExplainerTitle = styled.div<{ color: string }>`
  color: ${(props) => props.color};
  fontsize: 14px;
  display: flex;
  justify-content: space-between;
  margin-bottom: 8px;
  min-width: 240px;
`

const YieldExplainerStat = styled.div`
  color: ${colors.primaryText};
  fontsize: 14px;
  display: flex;
  justify-content: space-between;
  margin-bottom: 8px;
  gap: 8px;
`

interface ApyTooltipProps {
  placement?: "bottom" | "top"
}

export function ApyTooltip({ placement = "top" }: ApyTooltipProps) {
  const apy = useApy()

  const apyTypes = [
    { label: "ETH Staking", value: `~${apy.staking}`, imgSrc: Eth },
    { label: "ETH Options", value: `~${apy.options}`, imgSrc: Eth },
    { label: "Amplol", value: apy.amplol, imgSrc: Amplol },
    {
      label: "Etherfi Yield",
      value: apy.etherfiPoints,
      imgSrc: Etherfi,
    },
    {
      label: "Eigen Yield",
      value: apy.elPoints,
      imgSrc: Eigenlayer,
    },
  ]

  return (
    <TooltipExplanation
      maxWidth={280}
      placement={placement}
      explanation={
        <>
          <YieldExplainerTitle color={colors.green}>
            <span>Total APY</span>
            <span>+{apy.total}</span>
          </YieldExplainerTitle>
          {apyTypes.map((apyType, index) => (
            <YieldExplainerStat key={index}>
              <span>{apyType.label}</span>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: 8,
                }}
              >
                {apyType.imgSrc ? (
                  <img
                    width={16}
                    height={16}
                    src={apyType.imgSrc}
                    alt={apyType.label}
                    style={{ borderRadius: "50%" }}
                  />
                ) : null}
                <span>{apyType.value}</span>
              </div>
            </YieldExplainerStat>
          ))}
          <div
            style={{
              fontSize: "10px",
              marginTop: "24px",
            }}
          >
          </div>
        </>
      }
      renderContent={({ ref, ...triggerHandler }) => (
        <HelpInfo containerRef={ref} {...triggerHandler}>
          i
        </HelpInfo>
      )}
    />
  )
}
