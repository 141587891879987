import axios from 'axios';
import { useMutation } from '@tanstack/react-query';

interface RecordAirTableDepositParams {
  account: string;
  amount: string;
  utmSource: string | null;
  utmMedium: string | null;
  utmCampaign: string | null;
}

const recordAirTableDeposit = async ({
  account,
  amount,
  utmSource,
  utmMedium,
  utmCampaign,
}: RecordAirTableDepositParams) => {
  return await axios.post(
    `https://api.airtable.com/v0/${process.env.REACT_APP_AIRTABLE_BASE_ID}/${process.env.REACT_APP_AIRTABLE_TABLE_ID}`,
    {
      records: [
        {
          fields: {
            Account: account,
            Timestamp: new Date().toISOString(),
            Amount: amount,
            Source: utmSource,
            // Medium: utmMedium,
            // Campaign: utmCampaign,
          },
        },
      ],
    },
    {
      headers: {
        Authorization: `Bearer ${process.env.REACT_APP_AIRTABLE_API_KEY}`,
        'Content-Type': 'application/json',
      },
    }
  ).catch((error) => {
    console.log('Fail to write to airtable:', error);
  })
};

const useRecordDeposit = () => {
  return useMutation({
    mutationFn: recordAirTableDeposit,
  });
};

export default useRecordDeposit;
