import styled from "styled-components"

import { DECIMALS_ETH } from "../constants"
import { components } from "../designSystem/components"
import { formatBigint } from "../utils/math"
import HelpInfo from "./HelpInfo"
import TooltipExplanation from "./TooltipExplanation"

const YieldExplainerTitle = styled.div<{ color: string }>`
  color: ${(props) => props.color};
  fontsize: 14px;
  display: flex;
  justify-content: space-between;
  margin-bottom: 8px;
  min-width: 240px;

  > span {
    &:last-child {
    }
  }
`

const YieldExplainerStat = styled.div`
  fontsize: 14px;
  display: flex;
  justify-content: space-between;
  margin-bottom: 8px;
  margin-left: 8px;

  > span {
    &:first-child {
      margin-left: 8px;
    }

    &:last-child {
    }
  }
  gap: 8px;
`

interface WithdrawInfoTooltipProps {
  placement?: "bottom" | "top"
  depositReceipt: bigint
  vaultAccountBalance: bigint
}

export function WithdrawInfoTooltip({
  placement = "top",
  depositReceipt,
  vaultAccountBalance,
}: WithdrawInfoTooltipProps) {
  return (
    <TooltipExplanation
      maxWidth={components.modalWidth}
      placement={placement}
      explanation={
        <div
          style={{
            gap: "12px",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              width: "100%",
              fontSize: "12px",
            }}
          >
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <span style={{ width: "70%" }}>Withdrawable now:</span>
              <span>
                {formatBigint({
                  n: depositReceipt,
                  decimals: DECIMALS_ETH,
                })}{" "}
                weETH
              </span>
            </div>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <span style={{ width: "70%" }}>
                Withdrawable after cooldown period (~7d):
              </span>
              <span>
                ~
                {formatBigint({
                  n: vaultAccountBalance,
                  decimals: DECIMALS_ETH,
                })}{" "}
                weETH
              </span>
            </div>
          </div>
          <div>
            If you would like to withdraw the liquid balance, simply click
            withdraw. If you would also like to withdraw the locked balance, you
            will be prompted to first withdraw the liquid portion. Afterwards
            you will have the option to initiate a withdraw.
          </div>
        </div>
      }
      renderContent={({ ref, ...triggerHandler }) => (
        <HelpInfo containerRef={ref} {...triggerHandler}>
          i
        </HelpInfo>
      )}
    />
  )
}
