import Amplol from "../assets/png/assets/amplol.png"
import Eigenlayer from "../assets/png/assets/eigenlayer.png"
import Eth from "../assets/png/assets/eth.png"
import Etherfi from "../assets/png/etherfi.png"
import Weeth from "../assets/png/assets/weeth.png"
import Logo from "../assets/svg/logo.svg"

const IMG_SIZE = 18

export function StackedLogos() {
  return (
    <div
      style={{
        position: "relative",
        height: IMG_SIZE,
        width: IMG_SIZE,
        marginLeft: 64,
      }}
    >
      <img
        src={Etherfi}
        alt="Etherfi"
        height={IMG_SIZE}
        width={IMG_SIZE}
        style={{ position: "absolute", right: 0, zIndex: 1 }}
      />
      <img
        src={Eigenlayer}
        alt="Eigenlayer"
        height={IMG_SIZE}
        width={IMG_SIZE}
        style={{ position: "absolute", right: 12, zIndex: 2, borderRadius: 24 }}
      />
      <img
        src={Amplol}
        alt="Logo"
        height={IMG_SIZE}
        width={IMG_SIZE}
        style={{ position: "absolute", right: 24, zIndex: 3 }}
      />
      <img
        src={Eth}
        alt="Eth"
        height={IMG_SIZE}
        width={IMG_SIZE}
        style={{ position: "absolute", right: 36, zIndex: 4 }}
      />
    </div>
  )
}
