import React from "react"
import { Toast } from "react-bootstrap"
import { formatEther } from "viem"

import { Asset, getAssetImg } from "../../../../utils/asset"
import { AmplolLogo } from "../../../Logo"

interface WithdrawToastProps {
  show: boolean
  onClose: () => void
  amountBn: bigint
}

const WithdrawToast: React.FC<WithdrawToastProps> = ({
  show,
  onClose,
  amountBn,
}) => {
  return (
    <Toast
      onClose={onClose}
      show={show}
      delay={5000}
      autohide
      style={{ backgroundColor: "black", color: "white" }}
    >
      <Toast.Header>
        <strong className="me-auto">Withdrawn</strong>
      </Toast.Header>
      <Toast.Body>
        <p>
          Withdrawn {parseFloat(formatEther(amountBn)).toFixed(3)}{" "}
          <img
            src={getAssetImg(Asset.WEETH)}
            alt={Asset.WEETH}
            height={16}
            width={16}
          />{" "}
          weETH
        </p>
      </Toast.Body>
    </Toast>
  )
}

export default WithdrawToast
