import { Modal as BootstrapModal } from "react-bootstrap"
import styled, { css, keyframes } from "styled-components"

import colors from "../designSystem/colors"
import sizes from "../designSystem/sizes"
import theme from "../designSystem/theme"
import Link from "./Link"

export const BaseModal = styled(BootstrapModal)<{ backgroundColor?: string }>`
  .modal-content {
    background-color: ${(props) =>
      props.backgroundColor || colors.background.two};
    border: none;
    border-radius: ${theme.border.radius};

    button.close {
      color: white;
      opacity: 1;

      &:hover {
        opacity: ${theme.hover.opacity};
      }
    }
  }
`

export const BaseModalHeader = styled(BootstrapModal.Header)`
  border-bottom: unset;
`

export const BaseIndicator = styled.div<{
  size: number
  color: string
  blink?: boolean
}>`
  width: ${(props) => props.size}px;
  height: ${(props) => props.size}px;
  border-radius: ${(props) => props.size / 2}px;
  background: ${(props) => props.color};
  ${(props) => {
    if (props.blink) {
      return css`
        animation: ${blinkAnimation} 1.5s infinite;
      `
    }
  }}
`

export const BaseButton = styled.div`
  display: flex;
  border-radius: ${theme.border.radius};
  padding: 12px 16px;
`

const BaseText = styled.span`
  color: ${colors.text};
  font-size: 16px;
  color: white;
`

export const BaseLink = styled(Link)`
  &:hover {
    text-decoration: none;
  }
`

export const BaseInputContainer = styled.div<{ error?: boolean }>`
  position: relative;
  width: 100%;
  height: 72px;
  background: rgba(255, 255, 255, 0.04);
  border-radius: 4px;
  margin-top: 8px;
  padding: 0 4px;
  border: ${theme.border.width} ${theme.border.style}
    ${(props) => (props.error ? colors.red : `transparent`)};
  transition: border 0.25s;
`

export const BaseInput = styled.input<{
  inputWidth?: string
  fontSize?: number
  $lineHeight?: number
}>`
  width: ${(props) => props.inputWidth || "80%"};
  height: 100%;
  font-size: ${(props) => props.fontSize || 40}px;
  line-height: ${(props) => props.$lineHeight || 64}px;
  color: ${colors.primaryText};
  border: none;
  background: none;

  &:focus {
    color: ${colors.primaryText};
    background: none;
    border: none;
    box-shadow: 0 0 0 0 rgba(255, 255, 255, 0);
    border: rgba(255, 255, 255, 0);
  }

  &:disabled {
    background-color: transparent !important;
  }
`

export const BaseUnderlineLink = styled(BaseLink)`
  text-decoration: underline;
  color: ${colors.text};

  &:hover {
    text-decoration: none;
    color: ${colors.text};
  }
`

export const BaseModalContentColumn = styled.div<{
  $marginTop?: number | "auto"
}>`
  display: flex;
  justify-content: center;
  z-index: 1;
  margin-top: ${(props) =>
    props.$marginTop === "auto"
      ? props.$marginTop
      : `${props.$marginTop === undefined ? 24 : props.$marginTop}px`};
  border-radius: 8px;
`

export const Title = styled.span<{
  color?: string
  fontSize?: number
  $lineHeight?: number
  letterSpacing?: number
  $normalCased?: boolean
}>`
  color: ${(props) => props.color || colors.primaryText};
  font-style: normal;
  font-weight: normal;
  ${(props) => (!props.$normalCased ? `text-transform: uppercase;` : ``)}
  ${(props) => (props.fontSize ? `font-size: ${props.fontSize}px;` : ``)}
  ${(props) =>
    props.$lineHeight ? `line-height: ${props.$lineHeight}px;` : ``}
  ${(props) =>
    props.letterSpacing ? `letter-spacing: ${props.letterSpacing}px;` : ""}
`

export const Subtitle = styled.span`
  color: ${colors.primaryText};

  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  letter-spacing: 1.5px;
`

export const PrimaryText = styled(BaseText)<{
  color?: string
  fontSize?: number
  $lineHeight?: number
  fontWeight?: number
}>`
  font-style: normal;
  font-weight: ${(props) => props.fontWeight || 400};
  font-size: ${(props) => props.fontSize || 16}px;
  line-height: ${(props) => props.$lineHeight || 24}px;
  color: ${(props) => props.color || colors.primaryText};
`

export const SecondaryText = styled.div<{
  color?: string
  fontSize?: number
  $lineHeight?: number
  fontWeight?: number
}>`
  font-style: normal;
  font-weight: ${(props) => props.fontWeight || 400};
  font-size: ${(props) => props.fontSize || 14}px;
  line-height: ${(props) => props.$lineHeight || 20}px;
  color: ${(props) => props.color || colors.text};
`

// ============================== ANIMATIONS ==============================

export const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`

export const delayedFade = css<{ delay?: number; duration?: number }>`
  opacity: 0;
  animation: ${fadeIn} ${({ duration }) => duration || 1}s ease-in-out forwards;
  animation-delay: ${({ delay }) => `${delay || 0}s`};
`

export const blinkAnimation = keyframes`
  0% {
    opacity: 1;
  }

  50% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
`

export const upwards = keyframes`
  from {
    opacity: 0;
    transform: translateY(15px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`

export const delayedUpwardFade = css<{ delay?: number; duration?: number }>`
  opacity: 0;
  animation: ${upwards} ${({ duration }) => duration || 0.5}s ease-in-out
    forwards;
  animation-delay: ${({ delay }) => `${delay || 0}s`} !important;
`

export const FormButtonFade = styled.div<{
  $show?: boolean
  $delay?: number
  $triggerAnimation: boolean
}>`
  ${({ $show, $delay, $triggerAnimation }) => {
    return (
      $show &&
      $triggerAnimation &&
      css`
        opacity: 0;

        &:disabled {
          opacity: 0;
        }

        animation: ${fadeIn} 1s ease-in-out forwards;
        animation-delay: ${$delay || 0}s;
      `
    )
  }}
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
`

export const Button = styled.button`
  border: none;
  cursor: pointer;
  outline: none;
  box-shadow: none;
  text-align: center;
  pointer-events: auto;
  border: 2px solid ${colors.primaryText};
  box-sizing: border-box;
  border-radius: 8px;
  background: transparent;
  color: ${colors.primaryText};
  padding-top: 12px;
  padding-bottom: 12px;
  padding-left: 30px;
  padding-right: 30px;
  text-transform: uppercase;
`

export const ActionButton = styled(Button)`
  color: ${colors.background.one};
  background: ${colors.primaryText};
  border: 2px solid ${colors.primaryText};
  height: fit-content;
  width: 80%;
  border-radius: 0;
  font-size: 14px;
  &:disabled {
    color: ${colors.tertiaryText};
    background: ${colors.background.one};
    border: 2px solid ${colors.tertiaryText};
    pointer-events: none;
    cursor: not-allowed;
  }

  @media (max-width: ${sizes.md}px) {
    height: 100%;
  }
`
