import React, { useEffect, useState } from "react"
import { Row } from "react-bootstrap"
import styled from "styled-components"

import { ReactComponent as CloseIcon } from "../../../assets/svg/close.svg"
import { VAULT_INFO, VaultsEnum } from "../../../constants"
import colors from "../../../designSystem/colors"
import { components } from "../../../designSystem/components"
import sizes from "../../../designSystem/sizes"
import { Asset } from "../../../utils/asset"
import ActionModalFooter from "./ActionModalFooter"
import ActionModalHeader from "./ActionModalHeader"
import ActionModalHero from "./ActionModalHero"
import { ActionModalEnum, ActionType } from "./types"

const borderStyle = `1px solid ${colors.primaryText}1F`

const FixedContainer = styled.div`
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  background: black;
  z-index: 100;
  height: 100%;
  // top: ${components.header}px;
  left: ${components.sidebar}px;
  @media (max-width: ${sizes.lg}px) {
    left: 0;
  }
  right: 0;
`

const HeroContainer = styled.div`
  display: flex;
  height: 100%;
  flex-direction: column;
  width: 100%;

  @media (max-width: ${sizes.lg}px) {
    width: 100%;
  }

  > .row {
    margin-left: 0 !important;
    width: 100%;
  }
`

const Content = styled(Row)`
  @media (max-width: ${sizes.lg}px) {
    height: 100%;
  }

  > *:not(:last-child) {
    border-right: 1px solid ${colors.border};
  }
`

const CloseButton = styled.button`
  position: absolute;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background: none;
  border: none;
  width: ${components.header}px;
  height: ${components.header}px;
  border-left: ${borderStyle};
`

interface ActionModalProps {
  show: boolean
  actionType: ActionType
  onHide: () => void
  vault: VaultsEnum
}

const ActionModal: React.FC<ActionModalProps> = ({
  show,
  actionType,
  onHide,
  vault,
}) => {
  const [page, setPage] = useState<ActionModalEnum>(ActionModalEnum.PREVIEW)
  const [triggerAnimation, setTriggerAnimation] = useState<boolean>(true)
  const [depositAsset, setDepositAsset] = useState<Asset>(
    VAULT_INFO[vault].depositableAssets[0]
  )

  // stop trigger animation on rerenders
  useEffect(() => {
    let timeout: NodeJS.Timeout | null = null
    if (show) {
      timeout = setTimeout(() => {
        setTriggerAnimation(false)
      }, 1800)
    } else {
      clearTimeout(timeout!)
      setTriggerAnimation(true)
    }

    return () => {
      clearTimeout(timeout!)
    }
  }, [show])

  const [txhash, setTxhashMain] = useState<string>()
  return show ? (
    <FixedContainer>
      <HeroContainer>
        <ActionModalHeader page={page} actionType={actionType}>
          <CloseButton onClick={() => onHide()}>
            <CloseIcon />
          </CloseButton>
        </ActionModalHeader>
        <Content>
          <ActionModalHero
            actionType={actionType}
            vault={vault}
            page={page}
            setPage={setPage}
            setTxhashMain={setTxhashMain}
            onHide={() => onHide()}
            show={show}
            triggerAnimation={triggerAnimation}
            depositAsset={depositAsset}
            setDepositAsset={setDepositAsset}
          />
        </Content>
        <ActionModalFooter
          actionType={actionType}
          depositAsset={depositAsset}
          vault={vault}
          page={page}
          txhash={txhash}
          show={show}
        />
      </HeroContainer>
    </FixedContainer>
  ) : (
    <></>
  )
}

export default ActionModal
