import styled from "styled-components"

import colors from "../../designSystem/colors"

const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 60%;
  justify-content: center;
`

const GifImage = styled.img`
  width: 330px;
  height: auto;
`

const Description = styled.p`
  color: ${colors.secondaryText};
  font-size: 0.875rem; /* 14px */
  width: 330px;
  text-align: left;
  margin-top: 1rem; /* 16px */
`

interface OnboardingGifProps {
  gifSrc?: string
  description: React.ReactNode
}

export const OnboardingGif: React.FC<OnboardingGifProps> = ({
  gifSrc,
  description,
}) => {
  return (
    <Container>
      {gifSrc && <GifImage src={gifSrc} alt="Onboarding GIF" />}
      <Description>{description}</Description>
    </Container>
  )
}
