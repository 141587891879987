import { keyframes, styled } from "styled-components"

import colors from "../../../designSystem/colors"
import { components } from "../../../designSystem/components"
import sizes from "../../../designSystem/sizes"
import HeroContent from "../../HeroContent"

const livelyAnimation = (position: "top" | "bottom") => keyframes`
  0% {
    background-position-x: ${position === "top" ? 0 : 100}%;
  }

  50% {
    background-position-x: ${position === "top" ? 100 : 0}%;
  }

  100% {
    background-position-x: ${position === "top" ? 0 : 100}%;
  }
`

const ModalContainer = styled.div`
  height: calc(100vh - ${components.header}px - ${components.footer}px);
  @media (max-width: ${sizes.lg}px) {
    height: 100%;
  }
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: relative; // for ActionModalNote
`

const FrameBar = styled.div<{
  color: string
  position: "top" | "bottom"
  height: number
}>`
  display: flex;
  width: 100%;
  height: ${(props) => props.height}px;
  background: ${(props) => `linear-gradient(
    270deg,
    ${props.color}00 8%,
    ${props.color} 50%,
    ${props.color}00 92%
  )`};

  box-shadow: 4px 8px 80px 4px rgba(62, 115, 196, 0.43);
  background-size: 200%;
  animation: 10s ${(props) => livelyAnimation(props.position)} linear infinite;
`

interface ActionModalTransactionProps {
  actionType: "deposit" | "withdraw"
}

export function ActionModalTransaction({
  actionType,
}: ActionModalTransactionProps) {
  return (
    <ModalContainer>
      <div
        style={{
          display: "flex",
          height: "100%",
          width: "100%",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <FrameBar color={colors.asset.USDC} position="top" height={4} />
        <HeroContent
          word={actionType === "deposit" ? "Depositing" : "Withdrawing"}
          size={{
            desktop: 64,
            mobile: 36,
          }}
        />
        <FrameBar color={colors.asset.USDC} position="bottom" height={4} />
      </div>
    </ModalContainer>
  )
}
