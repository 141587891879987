import React from "react"
import { Toast } from "react-bootstrap"
import { formatEther } from "viem"

import { Asset, getAssetImg } from "../../../../utils/asset"
import { AmplolLogo } from "../../../Logo"

interface DepositToastProps {
  show: boolean
  onClose: () => void
  amountBn: bigint
}

const DepositToast: React.FC<DepositToastProps> = ({
  show,
  onClose,
  amountBn,
}) => {
  return (
    <Toast
      onClose={onClose}
      show={show}
      delay={5000}
      autohide
      style={{ backgroundColor: "black", color: "white" }}
    >
      <Toast.Header>
        <strong className="me-auto">Deposited</strong>
      </Toast.Header>
      <Toast.Body>
        <div>
          <div style={{ display: "flex", alignItems: "center", gap: 8 }}>
            Deposited
            <img
              src={getAssetImg(Asset.WEETH)}
              alt={Asset.WEETH}
              height={16}
              width={16}
            />
            {parseFloat(formatEther(amountBn)).toFixed(3)} weETH
          </div>
        </div>
        <div>
          <div style={{ display: "flex", alignItems: "center", gap: 8 }}>
            Minted
            <AmplolLogo size={16} />{" "}
            {parseFloat(formatEther(amountBn * 8888n)).toFixed(3)}
          </div>
        </div>
      </Toast.Body>
    </Toast>
  )
}

export default DepositToast
