import { useEffect } from "react"
import { useNavigate } from "react-router-dom"
import { parseEther } from "viem"
import { useWaitForTransactionReceipt, useWriteContract } from "wagmi"

import { DECIMALS_ETH } from "../../../constants"
import useLoadingText from "../../../hooks/useLoadingText"
import useVault from "../../../hooks/useVault"
import { useThreeJaneContext } from "../../../store"
import { getContractAbi, getContractAddr } from "../../../utils/address"
import { BrandModalConnectWalletButton } from "../../BrandConnectWalletButton"
import { ActionButton, FormButtonFade } from "../../common"
import {
  CompleteWithdrawBalance,
  InitiateWithdrawBalance,
  InstantWithdrawBalance,
} from "./ActionModalBalance"
import { ActionModalEnum } from "./types"

const etherfiAbiAddress = {
  abi: getContractAbi("EtherfiVault"),
  address: getContractAddr("EtherfiVault"),
} as const

interface ActionModalWithdrawBaseProps {
  show: boolean
  triggerAnimation: boolean
  hasError: boolean
  setPage: (page: ActionModalEnum) => void
  setTxhashMain: (txhash: string) => void
  setInputAmount: (amount: string) => void
  setPermittedAmount: (amount: bigint | undefined) => void
  isNetworkOk: boolean
}

interface ActionModalWithdrawProps extends ActionModalWithdrawBaseProps {
  amountBn: bigint
  initiateWithdrawableAmount: bigint
  instantWithdrawableAmount: bigint
}

export function ActionModalInstantWithdraw({
  amountBn,
  instantWithdrawableAmount,
  initiateWithdrawableAmount,
  show,
  triggerAnimation,
  hasError,
  setPage,
  setTxhashMain,
  setInputAmount,
  setPermittedAmount,
  isNetworkOk,
}: ActionModalWithdrawProps) {
  const { setTxError, setShowToast, setAction } = useThreeJaneContext((s) => ({
    setTxError: s.setTxError,
    setShowToast: s.setShowToast,
    setAction: s.setAction,
  }))

  const navigate = useNavigate()

  const loadingTextApprove = useLoadingText("withdrawing")

  const {
    data: withdrawInstantlyHash,
    writeContract: withdrawInstantly,
    isPending: withdrawInstantlyPending,
    isSuccess: withdrawInstantlySuccess,
  } = useWriteContract()
  const {
    isLoading: withdrawInstantlyConfirming,
    error: withdrawInstantlyError,
  } = useWaitForTransactionReceipt({
    hash: withdrawInstantlyHash,
  })

  const withdrawHash = withdrawInstantlyHash
  const withdrawIsPending = withdrawInstantlyPending
  const withdrawConfirming = withdrawInstantlyConfirming
  const withdrawSuccess = withdrawInstantlySuccess
  const txError = withdrawInstantlyError

  useEffect(() => {
    if (txError) {
      console.error("Tx failed", txError)
      setShowToast(true)
      setTxError(true)
    }
  }, [txError, setTxError, setShowToast])

  useEffect(() => {
    if (!withdrawHash) {
      return
    }
    if (withdrawSuccess) {
      setShowToast(true)
      setTxhashMain("")
      setInputAmount("")
      setPermittedAmount(undefined)
      navigate("/")
    } else {
      setPage(ActionModalEnum.TRANSACTION_STEP)
    }
  }, [
    withdrawConfirming,
    withdrawHash,
    setPage,
    setTxhashMain,
    setShowToast,
    navigate,
    withdrawSuccess,
    setInputAmount,
    setPermittedAmount,
  ])

  async function handleWithdraw() {
    setAction("withdraw")
    if (amountBn <= instantWithdrawableAmount) {
      withdrawInstantly({
        ...etherfiAbiAddress,
        functionName: "withdrawInstantly",
        args: [amountBn],
      })
    }
  }

  return !isNetworkOk ? (
    <BrandModalConnectWalletButton
      show={show}
      triggerAnimation={triggerAnimation}
    />
  ) : (
    <>
      <FormButtonFade
        $show={show}
        $triggerAnimation={triggerAnimation}
        $delay={0.4}
      >
        <ActionButton
          disabled={hasError || withdrawIsPending || amountBn === 0n}
          onClick={handleWithdraw}
        >
          {withdrawIsPending ? loadingTextApprove : "Withdraw"}
        </ActionButton>
      </FormButtonFade>
      <InstantWithdrawBalance
        instantWithdrawableAmount={instantWithdrawableAmount}
        initiateWithdrawableAmount={initiateWithdrawableAmount}
        assetDecimals={DECIMALS_ETH}
      />
    </>
  )
}

export function ActionModalInitiateWithdraw({
  amountBn,
  initiateWithdrawableAmount,
  instantWithdrawableAmount,
  show,
  triggerAnimation,
  hasError,
  setPage,
  setTxhashMain,
  setInputAmount,
  setPermittedAmount,
  isNetworkOk,
}: ActionModalWithdrawProps) {
  const { setTxError, setShowToast, setAction } = useThreeJaneContext((s) => ({
    setTxError: s.setTxError,
    setShowToast: s.setShowToast,
    setAction: s.setAction,
  }))

  const { pricePerShare } = useVault()
  const navigate = useNavigate()

  const loadingTextApprove = useLoadingText("withdrawing")

  const {
    data: withdrawInitiateHash,
    writeContract: withdrawInitiate,
    isPending: withdrawInitiatePending,
    isSuccess: withdrawInitiateSuccess,
  } = useWriteContract()
  const {
    isLoading: withdrawInitiateConfirming,
    error: withdrawInitiateError,
  } = useWaitForTransactionReceipt({
    hash: withdrawInitiateHash,
  })

  const withdrawHash = withdrawInitiateHash
  const withdrawIsPending = withdrawInitiatePending
  const withdrawConfirming = withdrawInitiateConfirming
  const withdrawSuccess = withdrawInitiateSuccess
  const txError = withdrawInitiateError

  useEffect(() => {
    if (txError) {
      console.error("Tx failed", txError)
      setShowToast(true)
      setTxError(true)
    }
  }, [txError, setTxError, setShowToast])

  useEffect(() => {
    if (!withdrawHash) {
      return
    }
    if (withdrawSuccess) {
      setShowToast(true)
      setTxhashMain("")
      setInputAmount("")
      setPermittedAmount(undefined)
      navigate("/")
    } else {
      setPage(ActionModalEnum.TRANSACTION_STEP)
    }
  }, [
    withdrawConfirming,
    withdrawHash,
    withdrawInitiateConfirming,
    withdrawInitiateHash,
    setPage,
    setTxhashMain,
    setShowToast,
    navigate,
    withdrawSuccess,
    setInputAmount,
    setPermittedAmount,
  ])

  async function handleWithdraw() {
    setAction("withdraw")
    const shares = (amountBn * parseEther("1")) / (pricePerShare ?? 1n)
    withdrawInitiate({
      ...etherfiAbiAddress,
      functionName: "initiateWithdraw",
      args: [shares],
    })
  }

  return !isNetworkOk ? (
    <BrandModalConnectWalletButton
      show={show}
      triggerAnimation={triggerAnimation}
    />
  ) : (
    <>
      <FormButtonFade
        $show={show}
        $triggerAnimation={triggerAnimation}
        $delay={0.4}
      >
        <ActionButton
          disabled={hasError || withdrawIsPending || amountBn === 0n}
          onClick={handleWithdraw}
        >
          {withdrawIsPending ? loadingTextApprove : "Initiate Withdraw"}
        </ActionButton>
      </FormButtonFade>
      <InitiateWithdrawBalance
        instantWithdrawableAmount={instantWithdrawableAmount}
        initiateWithdrawableAmount={initiateWithdrawableAmount}
        assetDecimals={DECIMALS_ETH}
      />
    </>
  )
}

interface ActionModalCompleteWithdrawProps
  extends ActionModalWithdrawBaseProps {
  withdrawAmount: bigint
  withdrawalShares: bigint
}

export function ActionModalCompleteWithdraw({
  withdrawAmount,
  show,
  triggerAnimation,
  hasError,
  setPage,
  setTxhashMain,
  setInputAmount,
  setPermittedAmount,
  isNetworkOk,
  withdrawalShares,
}: ActionModalCompleteWithdrawProps) {
  const { setTxError, setShowToast, setAction } = useThreeJaneContext((s) => ({
    setTxError: s.setTxError,
    setShowToast: s.setShowToast,
    setAction: s.setAction,
  }))

  const navigate = useNavigate()

  const loadingTextApprove = useLoadingText("withdrawing")

  const {
    data: withdrawCompleteHash,
    writeContract: withdrawComplete,
    isPending: withdrawCompletePending,
    isSuccess: withdrawCompleteSuccess,
  } = useWriteContract()
  const {
    isLoading: withdrawCompleteConfirming,
    error: withdrawCompleteError,
  } = useWaitForTransactionReceipt({
    hash: withdrawCompleteHash,
  })

  const withdrawHash = withdrawCompleteHash
  const withdrawIsPending = withdrawCompletePending
  const withdrawConfirming = withdrawCompleteConfirming
  const withdrawSuccess = withdrawCompleteSuccess
  const txError = withdrawCompleteError

  useEffect(() => {
    if (txError) {
      console.error("Tx failed", txError)
      setShowToast(true)
      setTxError(true)
    }
  }, [txError, setTxError, setShowToast])

  useEffect(() => {
    if (!withdrawHash) {
      return
    }
    if (withdrawSuccess) {
      setShowToast(true)
      setTxhashMain("")
      setInputAmount("")
      setPermittedAmount(undefined)
      navigate("/")
    } else {
      setPage(ActionModalEnum.TRANSACTION_STEP)
    }
  }, [
    withdrawConfirming,
    withdrawHash,
    withdrawCompleteConfirming,
    withdrawCompleteHash,
    setPage,
    setTxhashMain,
    setShowToast,
    navigate,
    withdrawSuccess,
    setInputAmount,
    setPermittedAmount,
  ])

  async function handleWithdraw() {
    setAction("withdraw")
    withdrawComplete({
      ...etherfiAbiAddress,
      functionName: "completeWithdraw",
    })
  }

  return !isNetworkOk ? (
    <BrandModalConnectWalletButton
      show={show}
      triggerAnimation={triggerAnimation}
    />
  ) : (
    <>
      <FormButtonFade
        $show={show}
        $triggerAnimation={triggerAnimation}
        $delay={0.4}
      >
        <ActionButton
          disabled={hasError || withdrawIsPending || withdrawAmount === 0n}
          onClick={handleWithdraw}
        >
          {withdrawIsPending ? loadingTextApprove : "Withdraw"}
        </ActionButton>
      </FormButtonFade>
      <CompleteWithdrawBalance
        amount={withdrawalShares}
        assetDecimals={DECIMALS_ETH}
      />
    </>
  )
}
