export const EtherfiDepositHelperAbi = [
  {
    inputs: [
      { internalType: "address", name: "_etherfiVault", type: "address" },
    ],
    stateMutability: "nonpayable",
    type: "constructor",
  },
  {
    inputs: [
      { internalType: "contract IERC20", name: "_asset", type: "address" },
      { internalType: "uint256", name: "_amount", type: "uint256" },
    ],
    name: "deposit",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [],
    name: "depositETH",
    outputs: [],
    stateMutability: "payable",
    type: "function",
  },
  {
    inputs: [
      { internalType: "contract IERC20", name: "_asset", type: "address" },
      { internalType: "uint256", name: "_amount", type: "uint256" },
      { internalType: "uint256", name: "_deadline", type: "uint256" },
      { internalType: "uint8", name: "_v", type: "uint8" },
      { internalType: "bytes32", name: "_r", type: "bytes32" },
      { internalType: "bytes32", name: "_s", type: "bytes32" },
    ],
    name: "depositWithPermit",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        components: [
          {
            internalType: "contract IStrategy[]",
            name: "strategies",
            type: "address[]",
          },
          { internalType: "uint256[]", name: "shares", type: "uint256[]" },
          { internalType: "address", name: "depositor", type: "address" },
          {
            components: [
              { internalType: "address", name: "withdrawer", type: "address" },
              { internalType: "uint96", name: "nonce", type: "uint96" },
            ],
            internalType: "struct IStrategyManager.WithdrawerAndNonce",
            name: "withdrawerAndNonce",
            type: "tuple",
          },
          {
            internalType: "uint32",
            name: "withdrawalStartBlock",
            type: "uint32",
          },
          {
            internalType: "address",
            name: "delegatedAddress",
            type: "address",
          },
        ],
        internalType: "struct IStrategyManager.QueuedWithdrawal",
        name: "_queuedWithdrawal",
        type: "tuple",
      },
    ],
    name: "depositWithQueuedWithdrawal",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [],
    name: "etherfiVault",
    outputs: [
      { internalType: "contract IRibbonThetaVault", name: "", type: "address" },
    ],
    stateMutability: "view",
    type: "function",
  },
] as const
