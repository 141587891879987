export type ActionType = "deposit" | "withdraw"

const PoolValidationErrorList = [
  "insufficientBalance",
  "withdrawLimitExceeded",
  "insufficientLiquidity",
] as const

export type PoolValidationErrors = (typeof PoolValidationErrorList)[number]

export enum ActionModalEnum {
  PREVIEW,
  TRANSACTION_STEP,
}
