import styled from "styled-components"
import { formatUnits } from "viem"

import colors from "../../../designSystem/colors"
import { formatAmount } from "../../../utils/math"
import { delayedFade } from "../../common"
import { WithdrawInfoTooltip } from "../../WithdrawInfoTooltip"

const BalanceWrapper = styled.div<{ delay?: number }>`
  display: flex;
  flex-direction: column;
  color: ${colors.secondaryText};
  padding: 0 20px;
  gap: 8px;
  justify-content: center;
  width: 100%;
  display: flex;
  justify-content: space-between;
  ${delayedFade};
`

const Flex = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 24px;
`

const BalanceLabel = styled.span`
  color: ${colors.tertiaryText};
  font-size: 14px;
`

const BalanceValue = styled.span<{ $error: boolean }>`
  font-size: 14px;
  color: ${(props) => (props.$error ? colors.red : "white")};
`

const BalanceTooltipWrapper = styled.div`
  gap: 4px;
  display: flex;
  align-items: center;
`

interface DepositBalanceProps {
  depositAsset: string
  userAssetBalance: bigint
  assetDecimals: number
}

export const DepositBalance = ({
  depositAsset,
  userAssetBalance,
  assetDecimals,
}: DepositBalanceProps) => {
  return (
    <Flex>
      <BalanceLabel>{`${depositAsset} Wallet Balance `}</BalanceLabel>
      <BalanceValue $error={false}>
        {formatAmount(
          parseFloat(formatUnits(userAssetBalance, assetDecimals)),
          true
        )}
      </BalanceValue>
    </Flex>
  )
}

interface CompleteWithdrawBalanceProps {
  amount: bigint
  assetDecimals: number
}

export const CompleteWithdrawBalance = ({
  amount,
  assetDecimals,
}: CompleteWithdrawBalanceProps) => {
  return (
    <Flex>
      <BalanceTooltipWrapper>
        <BalanceLabel>Shares</BalanceLabel>
      </BalanceTooltipWrapper>
      <BalanceValue $error={false}>
        {formatAmount(parseFloat(formatUnits(amount, assetDecimals)), true)}
      </BalanceValue>
    </Flex>
  )
}

interface InstantWithdrawBalanceProps {
  instantWithdrawableAmount: bigint
  initiateWithdrawableAmount: bigint
  assetDecimals: number
}

export const InstantWithdrawBalance = ({
  instantWithdrawableAmount,
  initiateWithdrawableAmount,
  assetDecimals,
}: InstantWithdrawBalanceProps) => {
  return (
    <BalanceWrapper>
      <Flex>
        <BalanceTooltipWrapper>
          <BalanceLabel>Pending Deposits</BalanceLabel>
          <WithdrawInfoTooltip
            depositReceipt={instantWithdrawableAmount}
            vaultAccountBalance={initiateWithdrawableAmount}
          />
        </BalanceTooltipWrapper>
        <BalanceValue $error={false}>
          {formatAmount(
            parseFloat(formatUnits(instantWithdrawableAmount, assetDecimals)),
            true
          )}
        </BalanceValue>
      </Flex>
    </BalanceWrapper>
  )
}

interface InitiateWithdrawBalanceProps {
  instantWithdrawableAmount: bigint
  initiateWithdrawableAmount: bigint
  assetDecimals: number
}

export const InitiateWithdrawBalance = ({
  instantWithdrawableAmount,
  initiateWithdrawableAmount,
  assetDecimals,
}: InitiateWithdrawBalanceProps) => {
  return (
    <BalanceWrapper>
      <Flex>
        <BalanceTooltipWrapper>
          <BalanceLabel>Vault Balance</BalanceLabel>
          <WithdrawInfoTooltip
            depositReceipt={instantWithdrawableAmount}
            vaultAccountBalance={initiateWithdrawableAmount}
          />
        </BalanceTooltipWrapper>
        <BalanceValue $error={false}>
          {formatAmount(
            parseFloat(formatUnits(initiateWithdrawableAmount, assetDecimals)),
            true
          )}
        </BalanceValue>
      </Flex>
    </BalanceWrapper>
  )
}
