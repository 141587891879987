import StepsCarousel from "./StepsCarousel"

const stepNumbers: string[] = ["01", "02", "03"]

const stepExplanations: string[] = [
  "Earn Derivatives Yield",
  "Trustless Collateralization",
  "Built on EigenLayer",
]

export const StepsCarouselVault: React.FC = () => {
  return (
    <StepsCarousel
      stepNumbers={stepNumbers}
      stepExplanations={stepExplanations}
    />
  )
}
