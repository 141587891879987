import { request } from "graphql-request"
import _ from "lodash"
import { z } from "zod"

import { annualizedWeeklyYield } from "../utils/math"

const SUBGRAPH_URL =
  "https://api.goldsky.com/api/public/project_clvvvr5shxt6301t7b2zn04ii/subgraphs/3jane/1.4.2/gn"

const YieldEarnedResponseSchema = z
  .object({
    vault: z.object({
      vaultAccounts: z.array(
        z.object({
          totalYieldEarned: z.string().transform((s) => BigInt(s)),
        })
      ),
    }),
  })
  .or(
    z.object({
      vault: z.null(),
    })
  )

export async function fetchUserYieldEarned({
  vaultId,
  account,
}: {
  vaultId: string
  account: string
}) {
  try {
    const res = await request(
      SUBGRAPH_URL,
      `
        query YieldEarnedQuery($vaultId: String!, $account: String!) {
          vault(id: $vaultId) {
            vaultAccounts(where: {account: $account}) {
              totalYieldEarned
            }
          }
        }
      `,
      { vaultId: vaultId.toLowerCase(), account: account.toLowerCase() }
    )
    const parsed = YieldEarnedResponseSchema.safeParse(res)
    if (!parsed.success) {
      throw new Error("Failed to parse response")
    }
    if (parsed.data.vault === null) {
      return 0n
    }
    return parsed.data.vault?.vaultAccounts[0]?.totalYieldEarned
  } catch (error) {
    throw new Error("Failed to fetch user yield earned")
  }
}

const MintAmountResponseSchema = z.object({
  amplolTokenMinteds: z.union([
    z.array(
      z.object({ mintAmountTvl: z.string().transform((s) => BigInt(s)) })
    ),
    z.null(),
  ]),
})

export async function fetchMintAmount({ account }: { account: string }) {
  try {
    const res = await request(
      SUBGRAPH_URL,
      `
        query AmplolMintedQuery($account: String!) {
          amplolTokenMinteds(
            where: {account: $account}
          ) {
            mintAmountTvl
          }
        }
      `,
      { account: account.toLowerCase() }
    )
    const parsed = MintAmountResponseSchema.safeParse(res)
    if (!parsed.success) {
      throw new Error("Failed to parse response")
    }
    if (parsed.data.amplolTokenMinteds === null) {
      return 0n
    }
    return parsed.data.amplolTokenMinteds[0].mintAmountTvl
  } catch (error) {
    throw new Error("Failed to fetch mint amount")
  }
}

const VaultOfferSettledResponseSchema = z.object({
  vaultOptionTrades: z.union([
    z.array(z.object({ timestamp: z.string().transform((s) => Number(s)) })),
    z.null(),
  ]),
})

export async function fetchVaultOfferLastSettled() {
  try {
    const res = await request(
      SUBGRAPH_URL,
      `
        query VaultOfferSettledsQuery {
          vaultOptionTrades(first: 1, orderBy: timestamp, orderDirection: desc) {
            timestamp
          }
        }
      `
    )
    const parsed = VaultOfferSettledResponseSchema.safeParse(res)
    if (!parsed.success) {
      throw new Error("Failed to parse response")
    }
    if (parsed.data.vaultOptionTrades === null) {
      return 0
    }
    return parsed.data.vaultOptionTrades[0].timestamp
  } catch (error) {
    throw new Error("Failed to fetch vault offer last settled")
  }
}

const ApyResponseSchema = z.object({
  vaultOptionTrades: z.union([
    z.array(
      z.object({
        premium: z.string().transform((s) => BigInt(s)),
        vault: z.object({
          totalBalance: z.string().transform((s) => BigInt(s)),
        }),
      })
    ),
    z.null(),
  ]),
})

export async function fetchApy({
  vaultId = "0xacd147a5bbcb7166c5bb13a9354ad7a59b99fb4d",
}: {
  vaultId?: string
}) {
  try {
    const res = await request(
      SUBGRAPH_URL,
      `
        query PremiumsQuery($id: ID = "") {
          vaultOptionTrades(
            first: 4
            orderBy: timestamp
            orderDirection: desc
            where: { vault_: { id: $id } }
        ) {
          premium
          vault {
            totalBalance
            }
          }
        }
      `,
      { id: vaultId }
    )
    const parsed = ApyResponseSchema.safeParse(res)
    if (!parsed.success) {
      throw new Error("Failed to parse response")
    }
    const trades = parsed.data.vaultOptionTrades
    if (trades === null || trades.length === 0) {
      return 0
    }

    const avgWeeklyRet = _.mean(
      trades.map(
        (trade) => Number(trade.premium) / Number(trade.vault.totalBalance)
      )
    )
    return annualizedWeeklyYield(avgWeeklyRet)
  } catch (error) {
    throw new Error("Failed to fetch APY")
  }
}
