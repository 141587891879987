import React, { useState } from "react"
import { OverlayTrigger } from "react-bootstrap"
import { OverlayTriggerRenderProps } from "react-bootstrap/esm/OverlayTrigger"
import { Placement } from "react-bootstrap/esm/types"

import TooltipExplanationContent from "./TooltipExplanationContent"

interface TooltipExplanationProps {
  title?: string
  explanation: React.ReactNode
  learnMoreURL?: string
  maxWidth?: number
  renderContent: (
    props: OverlayTriggerRenderProps & React.HTMLAttributes<HTMLElement>
  ) => React.ReactNode
  placement?: Placement
}

const TooltipExplanation: React.FC<TooltipExplanationProps> = ({
  title,
  explanation,
  learnMoreURL,
  maxWidth,
  renderContent,
  placement,
}) => {
  const [show, setShow] = useState(false)

  return (
    <OverlayTrigger
      placement={placement}
      overlay={(props) => (
        <TooltipExplanationContent
          title={title}
          explanation={explanation}
          overlayInjectedProps={props}
          learnMoreURL={learnMoreURL}
          maxWidth={maxWidth}
          setShow={setShow}
        />
      )}
      show={show}
    >
      {(props) =>
        renderContent({
          ...props,
          onMouseOver: () => setShow(true),
          onMouseOut: () => setShow(false),
        })
      }
    </OverlayTrigger>
  )
}

export default TooltipExplanation
