import currency from "currency.js"
import { formatUnits, parseUnits } from "viem"

import { getDefaultSignificantDecimalsFromAssetDecimals } from "./asset"

const isPracticallyZero = (
  num: bigint,
  decimals: number,
  marginString?: string
) => {
  const defaultSignificantDecimals =
    getDefaultSignificantDecimalsFromAssetDecimals(decimals)
  const _marginString =
    marginString ||
    (1 / 10 ** defaultSignificantDecimals).toFixed(defaultSignificantDecimals)
  const margin = parseUnits(_marginString, decimals)
  return num < margin
}

const formatBigNumber = (
  num: bigint,
  decimals: number = 18,
  significantDecimals?: number
) => {
  const _significantDecimals =
    significantDecimals ||
    getDefaultSignificantDecimalsFromAssetDecimals(decimals)
  return parseFloat(formatUnits(num, decimals)).toLocaleString(undefined, {
    maximumFractionDigits: _significantDecimals,
  })
}

export const annualizedWeeklyYield = (yieldPercent: number) => {
  return (yieldPercent + 1) ** 52 - 1
}

/**
 *
 * @param n Number
 * @param alwaysShowDecimals Optional.
 * If true, always show decimals. If false, clip decimals if required.
 * eg. with n of 10.1000, value of false will return 10.1.
 * @returns
 */
export const formatAmount = (
  n: number,
  alwaysShowDecimals?: boolean
): string => {
  if (n < 1e4) {
    if (alwaysShowDecimals) {
      return `${currency(n, { separator: ",", symbol: "" }).format()}`
    }
    return `${currency(n, {
      separator: ",",
      symbol: "",
      precision: 0,
    }).format()}`
  }
  if (n >= 1e4 && n < 1e6) {
    const v = alwaysShowDecimals
      ? (n / 1e3).toFixed(2)
      : parseFloat((n / 1e3).toFixed(2))
    return `${v}K`
  }
  if (n >= 1e6 && n < 1e9) {
    const v = alwaysShowDecimals
      ? (n / 1e6).toFixed(2)
      : parseFloat((n / 1e6).toFixed(2))
    return `${v}M`
  }
  if (n >= 1e9 && n < 1e12) {
    const v = alwaysShowDecimals
      ? (n / 1e9).toFixed(2)
      : parseFloat((n / 1e9).toFixed(2))
    return `${v}B`
  }
  if (n >= 1e12 && n < 1e15) {
    const v = alwaysShowDecimals
      ? (n / 1e12).toFixed(2)
      : parseFloat((n / 1e12).toFixed(2))
    return `${v}T`
  }
  if (n >= 1e15) {
    return "∞"
  }

  return ""
}

export const formatBigint = ({
  n,
  decimals,
  showDecimals = true,
}: {
  n: bigint
  decimals: number
  showDecimals?: boolean
}) => {
  return formatAmount(Number(formatUnits(n, decimals)), showDecimals)
}

const getRange = (start: number, stop: number, step: number) => {
  const a = [start]
  let b = start
  while (b < stop) {
    a.push((b += step || 1))
  }
  return a
}

export function sharesToAsset({
  shares,
  assetPerShare,
  decimals,
}: {
  shares: bigint
  assetPerShare: bigint
  decimals: number
}) {
  const PLACEHOLDER_UINT = 1n // Define PLACEHOLDER_UINT as per your requirement

  if (assetPerShare <= PLACEHOLDER_UINT) {
    return 0n
  }

  return (shares * assetPerShare) / BigInt(10 ** decimals)
}
