import { motion } from "framer-motion"
import { Row } from "react-bootstrap"
import { Link } from "react-router-dom"
import styled, { keyframes } from "styled-components"

import { MAX_VAULT_CAP, VAULT_INFO, VaultsEnum } from "../../constants"
import colors from "../../designSystem/colors"
import sizes from "../../designSystem/sizes"
import { useApy } from "../../hooks/useApy"
import { useVaultSize } from "../../hooks/useVaultSize"
import { formatAmount } from "../../utils/math"
import { getVaultImg } from "../../utils/vault"
import { BaseButton, fadeIn, Title } from "../common"
import { StackedLogos } from "../StackedLogos"
import TvlProgressBar from "./TvlProgressBar"

const statSideContainer: number = 120

const Stat = styled.div`
  margin: auto 0;
  height: fit-content;

  > * {
    display: flex;
  }
`

const ListRow = styled(Row)`
  display: block;
  padding: 0;
  @media (max-width: ${sizes.md}px) {
    border-top: 1px solid ${colors.border};
  }

  > * {
    padding: 0;
  }
`

const VaultLogo = styled.div`
  width: ${statSideContainer}px;
  height: ${statSideContainer}px;

  border-right: 1px solid ${colors.border};
  display: inline-flex;

  > * {
    overflow: hidden;
  }

  img {
    margin: auto;
    width: 56px;
    height: 56px;
  }
`

const VaultButton = styled(BaseButton)`
  opacity: 0;
  width: 0;
  padding: 0;
  border-radius: 0;
  border-left: 1px solid ${colors.border};
  > * {
    overflow: hidden;
  }

  i {
    text-align: center;
    margin: auto;
    width: 24px;
    height: 24px;
    color: white;
  }
`

const slide = keyframes`
  0% {
    opacity: 1;
    width: ${statSideContainer}px;
  }

  50% {
    opacity: 0;
  }

  100% {
    width: 0;
    opacity: 0;
  }
`

const reverseSlide = keyframes`
  0% {
    width: 0;
  }

  100% {
    width: ${statSideContainer}px;
    opacity: 1;
  }
`

const VaultWrapper = styled(Link)`
  height: ${statSideContainer}px;
  width: 100%;
  border-bottom: 1px solid ${colors.border};
  display: flex;

  &:hover {
    transition: 0.25s;
    background: ${colors.background.two};

    ${VaultLogo} {
      animation: 0.5s ${slide} forwards;
    }

    ${VaultButton} {
      animation: 0.5s ${reverseSlide} forwards;
      width: ${statSideContainer}px;
      height: ${statSideContainer}px;
    }
  }

  &:not(:hover) {
    ${VaultLogo} {
      animation: 0.5s ${reverseSlide} forwards;

      > img {
        animation: 0.5s ${fadeIn};
      }
    }

    ${VaultButton} {
      transition: 0.5s ease-in-out;
      animation: 0.5s ${slide};
    }
  }
`

const VaultStats = styled.div`
  height: ${statSideContainer}px;
  cursor: pointer;
  display: flex;
  justify-content: space-between;

  // 1px to account for border
  width: calc(100% - ${statSideContainer - 1}px);
  padding: 16px 32px;

  @media (max-width: ${sizes.md}px) {
    padding: 16px;
  }

  > ${Stat}:last-of-type {
    > * {
      display: flex;
      justify-content: flex-end;
    }
  }
`

const Value = styled.span`
  display: flex;
  color: ${colors.primaryText};

  > * {
    margin: auto 0;
  }

  svg {
    width: 20px;
    height: 20px;
    margin-right: 8px;
  }
`

const StyledTitle = styled(Title)`
  font-size: 14px;
  line-height: 36px;

  svg {
    width: fit-content;
    height: fit-content;
    margin: auto;
    margin-right: 8px;
  }
`

const StyledSubtitle = styled.div<{ color?: string }>`
  font-size: 12px;
  line-height: 16px;
  text-transform: uppercase;
  color: ${({ color }) => color ?? colors.tertiaryText};
`

const MotionWrapper = ({
  children,
  index,
}: {
  children: React.ReactNode
  index: number
}) => (
  <motion.div
    transition={{
      duration: 0.5,
      delay: (index + 1) / 10,
      type: "keyframes",
      ease: "easeInOut",
    }}
    initial={{
      y: 50,
      opacity: 0,
    }}
    animate={{
      y: 0,
      opacity: 1,
    }}
    exit={{
      y: 50,
      opacity: 0,
    }}
  >
    {children}
  </motion.div>
)

type IVaultsRowProps = {
  vault: VaultsEnum
  index: number
}

export const VaultsRow = ({ vault, index }: IVaultsRowProps) => {
  const info = VAULT_INFO[vault]
  const { totalBalanceUSD, isLoading } = useVaultSize({
    asset: info.nativeAsset,
    vault,
  })
  const fillPct = Math.round((totalBalanceUSD / MAX_VAULT_CAP) * 100)

  const apy = useApy()

  if (vault === VaultsEnum.ETHENA) {
    return (
      <ListRow>
        <MotionWrapper index={index}>
          <VaultWrapper
            to={`#`}
            style={{
              cursor: "not-allowed",
            }}
          >
            <VaultLogo>
              <img src={getVaultImg(vault)} alt={`${info.fullName} logo`} />
            </VaultLogo>
            <VaultStats>
              <Stat>
                <StyledTitle
                  style={{
                    color: colors.tertiaryText,
                  }}
                >
                  {/* {info.fullName} */}
                </StyledTitle>
              </Stat>
              <Stat>
                <Value
                  style={{
                    color: colors.tertiaryText,
                  }}
                >
                  Coming Soon
                </Value>
              </Stat>
            </VaultStats>
          </VaultWrapper>
        </MotionWrapper>
      </ListRow>
    )
  }

  return (
    <ListRow>
      <MotionWrapper index={index}>
        <VaultWrapper to={`/vault/${vault}`}>
          <VaultLogo>
            <img src={getVaultImg(vault)} alt={`${info.fullName} logo`} />
          </VaultLogo>
          <VaultStats>
            <Stat style={{ flex: 1 }}>
              <StyledTitle
                style={{
                  color: colors.primaryText,
                }}
              >
                {info.fullName}
              </StyledTitle>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  width: "100%",
                  maxWidth: "200px",
                }}
              >
                <TvlProgressBar percent={fillPct} isLoading={isLoading} />
                <span
                  style={{
                    marginLeft: "8px",
                    color: colors.primaryText,
                    fontSize: "14px",
                  }}
                >
                  ${formatAmount(totalBalanceUSD)} ({fillPct}%)
                </span>
              </div>
            </Stat>
            <Stat>
              <StyledSubtitle
                style={{
                  display: "flex",
                  alignItems: "center",
                  marginLeft: "auto",
                  gap: 12,
                  fontSize: "14px",
                  flexDirection: "column",
                  marginTop: 8,
                }}
              >
                <Value color={colors.green}>
                  <span style={{ color: colors.green, fontSize: "14px" }}>
                    +{apy.total} APY
                  </span>
                </Value>
                <StackedLogos />
              </StyledSubtitle>
            </Stat>
          </VaultStats>
          <VaultButton>
            <i className="fas fa-arrow-right" />
          </VaultButton>
        </VaultWrapper>
      </MotionWrapper>
    </ListRow>
  )
}
