import { Address } from "viem"
import { english, generateMnemonic, mnemonicToAccount } from "viem/accounts"

import addresses from "../addresses.json"
import { AmplolTokenAbi } from "../constants/abis/AmplolTokenAbi"
import { EtherfiDepositHelperAbi } from "../constants/abis/EtherfiDepositHelperAbi"
import { ManualStrikeSelectionAbi } from "../constants/abis/ManualStrikeSelectionAbi"
import { OTokenAbi } from "../constants/abis/OTokenAbi"
import { RibbonThetaVaultWithSwapAbi } from "../constants/abis/RibbonThetaVaultWithSwapAbi"
import { WeETHAbi } from "../constants/abis/WeETHAbi"

export const truncateAddress = (address: string) => {
  return address.slice(0, 6) + "..." + address.slice(address.length - 4)
}

const mnemonic = generateMnemonic(english)
export const PLACEHOLDER_ACCOUNT = mnemonicToAccount(mnemonic).address

export const getContractAddr = (contract: string) => {
  if (!addresses[contract as keyof typeof addresses]) {
    throw new Error(`Contract ${contract} not found in addresses`)
  }
  return addresses[contract as keyof typeof addresses] as Address
}

export const getContractAbi = (contract: string) => {
  switch (contract) {
    case "AMPLOL":
      return AmplolTokenAbi
    case "ManualStrikeSelection":
      return ManualStrikeSelectionAbi
    case "OToken":
      return OTokenAbi
    case "EtherfiVault":
      return RibbonThetaVaultWithSwapAbi
    case "WEETH":
      return WeETHAbi
    case "DepositHelper":
      return EtherfiDepositHelperAbi
    default:
      throw new Error(`Contract ${contract} ABI not found`)
  }
}
