import { useEffect, useState } from "react"
import { parseEther } from "viem"

export const useTickingTotalSupply = ({
  totalSupply,
}: {
  totalSupply: bigint
}) => {
  const [displayedTotalSupply, setDisplayedTotalSupply] = useState(0n)

  useEffect(() => {
    if (totalSupply > 0n) {
      setDisplayedTotalSupply((totalSupply * 90n) / 100n)
    }
  }, [totalSupply])

  useEffect(() => {
    const interval = setInterval(() => {
      setDisplayedTotalSupply((prev) => {
        const increment = parseEther((Math.random() * (3 - 1) + 1).toFixed(3))
        const newValue = prev + increment
        return newValue >= totalSupply ? totalSupply : newValue
      })
    }, 1_500)

    return () => clearInterval(interval)
  }, [totalSupply])

  return { displayedTotalSupply }
}
