import styled from "styled-components"

import { ReactComponent as Logo } from "../../assets/svg/logo.svg"
import { Title } from "../../components/common"
import sizes from "../../designSystem/sizes"
import useScreenSize from "../../hooks/useScreenSize"

const FloatingContainer = styled.div<{ height: number }>`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  @media (max-width: ${sizes.md}px) {
    flex-direction: column;
  }
`

const JLogo = styled(Logo)`
  margin-right: 32px;

  @media (max-width: ${sizes.md}px) {
    margin-right: unset;
  }
`

const NotFound = () => {
  const { height } = useScreenSize()
  return (
    <FloatingContainer height={height}>
      <JLogo height={120} width={120} />
      <Title fontSize={160} $lineHeight={160}>
        404
      </Title>
    </FloatingContainer>
  )
}

export default NotFound
