import { useReadContracts } from "wagmi"

import { getContractAbi, getContractAddr } from "../utils/address"

const amplolAbiAddress = {
  abi: getContractAbi("AMPLOL"),
  address: getContractAddr("AMPLOL"),
} as const

export const useAmplol = () => {
  const { data, isLoading } = useReadContracts({
    contracts: [
      {
        ...amplolAbiAddress,
        functionName: "pRebase",
      },
      {
        ...amplolAbiAddress,
        functionName: "totalSupply",
      },
      {
        ...amplolAbiAddress,
        functionName: "tvl",
      },
    ],
  })

  const pRebase = data?.[0]?.result ?? 0n
  const totalSupply = data?.[1]?.result ?? 0n
  const tvl = data?.[2]?.result ?? 0n

  const amplolRebase = Date.now() / 1_000 - Number(pRebase)
  const amplolRebaseMinutes = Math.floor(amplolRebase / 60)
  const amplolRebaseDisplay =
    amplolRebaseMinutes >= 60
      ? `${Math.floor(amplolRebaseMinutes / 60)} hrs ago`
      : `${amplolRebaseMinutes} min ago`

  return {
    isLoading,
    totalSupply,
    amplolRebaseDisplay,
    tvl,
  }
}
