import React, { PropsWithChildren } from "react"
import { AnimatePresence, motion } from "framer-motion"
import styled from "styled-components"

import colors from "../../designSystem/colors"
import sizes from "../../designSystem/sizes"
import { Title } from "../common"
import VideoBackground from "./VideoBackground"

const ContentContainer = styled.div`
  background-color: black;
  position: relative;
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
`

const SpecialText = styled(Title).attrs({
  $normalCased: true,
})<{ size: number }>`
  color: ${colors.primaryText};
  font-size: ${(props) => props.size}px;
  line-height: ${(props) => props.size}px;
  margin-bottom: 25px;
  @media (max-width: ${sizes.lg}px) {
    margin-bottom: 8px;
  }
`

const SpecialSubText = styled.span`
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
  @media (max-width: ${sizes.lg}px) {
    font-size: 16px;
  }
  z-index: 1000;
  color: ${colors.primaryText};
  font-size: 24px;
  line-height: 24px;
`

const HeroHeaderContainer = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
  @media (max-width: ${sizes.lg}px) {
    display: none;
  }
  z-index: 1000;
`

const HeroHeaderMobileContainer = styled.div`
  display: none;
  @media (max-width: ${sizes.lg}px) {
    position: fixed;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    z-index: 1001;
    margin: auto 0;
  }
`

const HeroHeader: React.FC<PropsWithChildren> = ({ children }) => {
  return (
    <HeroHeaderContainer>
      <AnimatePresence mode="wait">
        <motion.div
          transition={{
            delay: 0,
            duration: 0.5,
            type: "keyframes",
            ease: "easeOut",
          }}
          initial={{
            opacity: 0,
          }}
          animate={{
            opacity: 1,
          }}
          exit={{
            opacity: 0,
          }}
        >
          {children}
        </motion.div>
      </AnimatePresence>
    </HeroHeaderContainer>
  )
}

const HeroHeaderMobile: React.FC<PropsWithChildren> = ({ children }) => {
  return (
    <HeroHeaderMobileContainer>
      <AnimatePresence mode="wait">
        <motion.div
          transition={{
            delay: 0,
            duration: 0.5,
            type: "keyframes",
            ease: "easeOut",
          }}
          initial={{
            opacity: 0,
          }}
          animate={{
            opacity: 1,
          }}
          exit={{
            opacity: 0,
          }}
        >
          {children}
        </motion.div>
      </AnimatePresence>
    </HeroHeaderMobileContainer>
  )
}

interface HeroContentInterface {
  word: string
  size?: {
    mobile: number
    desktop: number
  }
  subtext?: React.ReactNode
}

const HeroContent: React.FC<HeroContentInterface> = ({
  word,
  size: { mobile = 96, desktop = 256 } = {},
  subtext,
}) => {
  return (
    <>
      <ContentContainer>
        <VideoBackground />
        <HeroHeaderMobile>
          <SpecialText size={mobile}>{word}</SpecialText>
          {subtext ? <SpecialSubText>{subtext}</SpecialSubText> : null}
        </HeroHeaderMobile>
        <div className="d-flex align-items-center justify-content-between w-full h-full">
          <HeroHeader>
            <SpecialText size={desktop}>{word}</SpecialText>
            {subtext ? <SpecialSubText>{subtext}</SpecialSubText> : null}
          </HeroHeader>
        </div>
      </ContentContainer>
    </>
  )
}
export default HeroContent
