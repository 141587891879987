import styled from "styled-components"

import colors from "../../../designSystem/colors"
import { components } from "../../../designSystem/components"
import sizes from "../../../designSystem/sizes"
import { delayedFade } from "../../common"

interface ActionModalNoteProps {
  isCapReached: boolean
  showDepositNote: boolean
}

const NoteContainer = styled.p<{ delay?: number }>`
  margin-top: 64px;
  text-align: center;
  color: ${colors.secondaryText};
  font-size: 14px;
  line-height: 1;
  @media (max-width: ${sizes.lg}px) {
    margin-bottom: -36px;
    margin-top: 0px;
  }
  text-wrap: balance;
  display: flex;
  flex-direction: column;
  gap: 12px;
  width: ${components.modalWidth}px;
  ${delayedFade};
`

export function ActionModalNote({
  isCapReached,
  showDepositNote,
}: ActionModalNoteProps) {
  return (
    <NoteContainer delay={0.5}>
      {isCapReached && (
        <span>Cap has been reached! We will open up the vaults soon.</span>
      )}
      <span>
        <span style={{ fontWeight: 800, color: colors.primaryText }}>
          Note:
        </span>{" "}
        ~7 days withdrawal cooldown
      </span>
    </NoteContainer>
  )
}
