import styled from "styled-components"

import { components } from "../../designSystem/components"
import { AmplolInfo } from "./AmplolInfo"
import { BalanceInfo } from "./BalanceInfo"

const BalanceWrapper = styled.div`
  height: calc(100vh - ${components.header}px - ${components.footer}px);
  display: flex;
  align-items: center;
  flex-direction: column;
`

const BalanceInfoWrapper = styled.div`
  width: 100%;
  display: flex;
  height: 36vh;
  justify-content: center;
`

const AmplolInfoWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 12vh;
`

export const Balance = () => {
  return (
    <BalanceWrapper>
      <BalanceInfoWrapper>
        <BalanceInfo />
      </BalanceInfoWrapper>
      <AmplolInfoWrapper>
        <AmplolInfo />
      </AmplolInfoWrapper>
    </BalanceWrapper>
  )
}
