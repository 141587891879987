import { useCallback } from "react"
import { useQueries } from "@tanstack/react-query"
import currency from "currency.js"
import { Col, Row } from "react-bootstrap"
import styled from "styled-components"
import { parseUnits, zeroAddress } from "viem"
import { useReadContracts } from "wagmi"

import Amplol from "../../../assets/png/assets/amplol.png"
import Eigenlayer from "../../../assets/png/assets/eigenlayer.png"
import Eth from "../../../assets/png/assets/eth.png"
import Etherfi from "../../../assets/png/etherfi.png"
import {
  DECIMALS_OPTIONS,
  LINKS,
  VAULT_INFO,
  VaultsEnum,
} from "../../../constants"
import colors from "../../../designSystem/colors"
import { fetchVaultOfferLastSettled } from "../../../gql/queries"
import { useApy } from "../../../hooks/useApy"
import { useAssetPrices } from "../../../hooks/useAssetPrices"
import { useAssetPricesTimestamp } from "../../../hooks/useAssetPriceTimestamp"
import useLoadingText from "../../../hooks/useLoadingText"
import useVault from "../../../hooks/useVault"
import { useVaultSize } from "../../../hooks/useVaultSize"
import { getContractAbi, getContractAddr } from "../../../utils/address"
import { formatAmount } from "../../../utils/math"
import { titleCase } from "../../../utils/string"
import { getVaultParams, IVaultParams } from "../../../utils/vault"
import { SecondaryText, Title } from "../../common"
import { ContractAddress } from "../../ContractAddress"
import { LinkWithIcon } from "../../LinkWithIcon"

const IMG_SIZE = 18

const ExplainerTitle = styled.div<{ color: string; $marginTop?: number }>`
  display: flex;
  font-size: 12px;
  width: 100%;
  color: ${colors.tertiaryText};
  margin-top: ${(props) =>
    props.$marginTop !== undefined ? `${props.$marginTop}px` : `16px`};
`

const ParagraphText = styled(SecondaryText)<{ $marginTop?: number }>`
  color: ${colors.secondaryText};
  font-weight: 400;
  font-size: 16px;
  font-family: Inter;
  line-height: 24px;
  margin-top: ${(props) =>
    props.$marginTop ? `${props.$marginTop}px` : `0px`};
  display: flex;
  flex-direction: column;

  > span:not(:last-child) {
    margin-bottom: 16px;
  }
`

const StyledTitle = styled(Title)<{ $marginTop: number }>`
  margin-top: ${(props) => props.$marginTop}px;
  text-transform: capitalize;
  font-family: Inter;
`

const HighlightedText = styled.span`
  color: ${colors.primaryText};
`

interface IGenericDetailsProps {
  vault: VaultsEnum
}

export const VaultParams: React.FC<IGenericDetailsProps> = ({ vault }) => {
  return <Params vault={vault} />
}

export const VaultDescription: React.FC<IGenericDetailsProps> = ({ vault }) => {
  const renderDescription = useCallback(() => {
    switch (vault) {
      case VaultsEnum.ETHERFI:
        return (
          <ParagraphText>
            <span></span>
            <span>
              Etherfi EETH-X-C earns ETH options yield on top of restaking by
              selling derivatives contracts collateralized by restaked ETH. The
              vault sells deep out-of-the-money call options to institutional
              crypto options market makers and accrues eETH options premiums.
              The vault reinvests the yield earned, effectively compounding the
              yields for depositors over time.
            </span>
            <span>
              Each week depositors will earn yield if the ETH price is{" "}
              <HighlightedText>below</HighlightedText> the option strike price
              on expiry (see parameter section). There is a{" "}
              <HighlightedText>~95%</HighlightedText> chance that the ETH price
              is <HighlightedText>below</HighlightedText> the strike price on
              expiry.
            </span>
            <span>
              <HighlightedText>
                Note: This is not a risk-free vault.{" "}
              </HighlightedText>
              Please read the <a href={"#risks"}>risks</a> associated with
              EETH-X-C.
            </span>
          </ParagraphText>
        )
    }
  }, [vault])

  return <>{renderDescription()}</>
}

const HighlightedParamText = styled.span`
  color: ${colors.ribbonGreen};
`

const Params: React.FC<IGenericDetailsProps> = ({ vault }) => {
  const dataSubgraph = useQueries({
    queries: [
      {
        queryKey: ["vault-offer-last-timestamp"],
        queryFn: async () => {
          return fetchVaultOfferLastSettled()
        },
      },
    ],
  })

  const timestamp = dataSubgraph[0].data ?? 0
  const { data: ethPriceAtSettled } = useAssetPricesTimestamp({ timestamp })

  const p = getVaultParams(vault)
  const info = VAULT_INFO[vault]

  const { data: ethPrice, isLoading: ethPriceLoading } = useAssetPrices(
    info.nativeAsset
  )
  const price = ethPrice ?? 0

  const { period, currentOption } = useVault()

  const currentOptionAddr = currentOption ?? zeroAddress
  const isValidOption = currentOptionAddr !== zeroAddress

  const { data: currentOptionData } = useReadContracts({
    contracts: [
      {
        abi: getContractAbi("OToken"),
        address: currentOptionAddr,
        functionName: "expiryTimestamp",
      },
    ],
    query: {
      enabled: currentOptionAddr !== zeroAddress,
    },
  })

  const loadingText = useLoadingText()
  const currentOptionExpiry = currentOptionData?.[0]?.result ?? 0n
  const { data: currentOptionStrikePrice } = useReadContracts({
    contracts: [
      {
        abi: getContractAbi("ManualStrikeSelection"),
        address: getContractAddr("ManualStrikeSelection"),
        functionName: "getStrikePrice",
        args: [currentOptionExpiry, true],
      },
    ],
    query: {
      enabled: currentOptionExpiry !== 0n,
    },
  })
  const [_strikePrice, _delta] = currentOptionStrikePrice?.[0]?.result ?? [
    0n,
    0n,
  ]
  const strikePrice = _strikePrice / parseUnits("1", DECIMALS_OPTIONS)

  const paramsArray = Object.keys(p).map((k) => {
    const paramKey = k as keyof IVaultParams
    let title = titleCase(paramKey)
    let value = p[paramKey] as React.ReactNode
    let isHighlight = false

    switch (paramKey) {
      case "optionType":
        title = "Option Type"
        break
      case "underlyingAsset":
        title = "Underlying Asset"
        break
      case "optionStrikePrice":
        title = "Option Strike Price"
        value = !isValidOption ? "---" : currency(Number(strikePrice)).format()
        isHighlight = true
        break
      case "spotPrice":
        title = "Spot Price"
        value = ethPriceLoading
          ? loadingText
          : currency(price as number).format()
        isHighlight = true
        break
      case "optionDelta":
        title = "Option Delta"
        value = "5"
        isHighlight = true
        break
      case "chanceITM":
        title = "% Chance In-The-Money"
        value = `${(Number(value) * 100).toFixed(2)}%`
        isHighlight = true
        break
      case "cycle":
        title = "Cycle"
        value = period?.toString() ?? "N/A"
        break
      case "endOfCycle":
        title = "End of Cycle"
        value = !isValidOption
          ? "---"
          : `${new Date(Number(currentOptionExpiry) * 1_000).toLocaleDateString(
              "en-US",
              {
                year: "numeric",
                month: "long",
                day: "numeric",
              }
            )}, ${new Date(
              Number(currentOptionExpiry) * 1_000
            ).toLocaleTimeString("en-US", {
              hour: "numeric",
              minute: "numeric",
              hour12: true,
            })}`
        isHighlight = true
        break
      case "express":
        title = "Express"
        value = "True"
        break
      case "expressKnockOutBarrier":
        title = "Express Down-and-Out KO Barrier"
        value =
          ethPriceAtSettled === undefined
            ? "..."
            : `${currency(Number(ethPriceAtSettled) * 0.95).format()} (${(
                Number(value) * 100
              ).toFixed(2)}%)`
        isHighlight = true
        break
      case "premiumType":
        title = "Premium Type"
        break
      case "expressKnockOutObservation":
        title = "Express Knock-Out Observation"
        break
      case "premiumObservation":
        title = "Premium Observation"
        break
      case "premiumsPaidIn":
        title = "Premiums paid in"
        break
      case "collateralAsset":
        title = "Collateral Asset"
        break
      case "optionStyle":
        title = "Option Style"
        break
      case "settlementType":
        title = "Settlement Type"
        break
      case "performanceFee":
        title = "Performance Fee"
        value = `${(Number(value) * 100).toFixed(2)}%`
        break
      case "managementFee":
        title = "Management Fee"
        value = `${(Number(value) * 100).toFixed(2)}%`
        break
      case "currentOptionToken":
        title = "Current Option Token"
        value = !isValidOption ? (
          <span style={{ color: colors.ribbonGreen }}>---</span>
        ) : (
          <div style={{ display: "flex", alignItems: "center" }}>
            <ContractAddress
              address={currentOptionAddr}
              color={colors.ribbonGreen}
            />
          </div>
        )
        break
    }

    return (
      <div key={paramKey}>
        <ExplainerTitle key={paramKey} color={colors.tertiaryText}>
          <span>{title}</span>
        </ExplainerTitle>
        <StyledTitle $marginTop={4}>
          {isHighlight ? (
            <HighlightedParamText>{value}</HighlightedParamText>
          ) : (
            value
          )}
        </StyledTitle>
      </div>
    )
  })

  const half = Math.ceil(paramsArray.length / 2)
  const firstHalf = paramsArray.slice(0, half)
  const secondHalf = paramsArray.slice(half)

  return (
    <div
      style={{
        gap: 24,
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Row>
        <Col xs={6}>{firstHalf}</Col>
        <Col xs={6}>{secondHalf}</Col>
      </Row>
      <ParagraphText>
        <HighlightedText>Note: </HighlightedText>Vaults are constantly
        evolving and may be improved. As a result, the protocol may make changes
        to the overall option structure, including option barriers, barrier
        observations, premium observations, cycle maturity, and auction
        structure.
      </ParagraphText>
    </div>
  )
}

export const Risks: React.FC<IGenericDetailsProps> = ({ vault }) => {
  return (
    <div
      id="risks"
      style={{
        display: "flex",
        flexDirection: "column",
      }}
    >
      <StyledTitle $marginTop={12}>In-The-Money Principal Risk</StyledTitle>
      <ParagraphText>
        <p>
          Selling options is not a risk-free yield source. This is a trade - in
          the case that the options are in-the-money, the option buyer is
          profitable and may choose to exercise at the strike price. Depositing
          into 3Jane is like making a deal -- you get some extra money now
          upfront and in exchange you give someone the option to buy your ETH at
          a fixed price (strike price) later. If the price doesn&apos;t go up
          much, you keep your ETH and the extra money. If the price goes up a
          lot (in-the-money), you sell your ETH at the agreed price and keep the
          extra money. It&apos;s a way to earn some extra income, but it means
          you might miss out if the price skyrockets. The option seller may{" "}
          <span style={{ color: "white" }}>lose principal</span> as a result.
        </p>
        <p>
          Read more in the{" "}
          <LinkWithIcon
            color={colors.secondaryText}
            href={LINKS.risks}
            shiftChildren
            noUnderline
          >
            risk docs
          </LinkWithIcon>
          .
        </p>
      </ParagraphText>
      <StyledTitle $marginTop={12}>Smart Contract Risk</StyledTitle>
      <ParagraphText>
        <p>
          3Jane V1 contracts are a fork of the Ribbon Theta Vaults. Ribbon
          vaults have been audited by{" "}
          <LinkWithIcon
            noUnderline
            shiftChildren
            href="https://blog.openzeppelin.com/ribbon-finance-audit"
          >
            OpenZeppelin
          </LinkWithIcon>{" "}
          and{" "}
          <LinkWithIcon
            noUnderline
            shiftChildren
            href="https://github.com/ribbon-finance/audit/blob/master/reports/RibbonThetaVault%20V2%20Smart%20Contract%20Review%20And%20Verification.pdf"
          >
            ChainSafe
          </LinkWithIcon>
          . 3Jane includes enhancements to the codebase which were further
          audited by{" "}
          <LinkWithIcon
            noUnderline
            shiftChildren
            href="https://github.com/3jane-protocol/audit"
          >
            Veridise
          </LinkWithIcon>
          .
        </p>
      </ParagraphText>
      <StyledTitle $marginTop={12}>Collateral Risk</StyledTitle>
      <ParagraphText>
        <p>
          eETH is not a risk-free collateral asset. Read{" "}
          <LinkWithIcon
            href={"https://info.3jane.xyz/3jane/key-terms"}
            noUnderline
            shiftChildren
          >
            more
          </LinkWithIcon>
          .
        </p>
      </ParagraphText>
      <StyledTitle $marginTop={12}>Other Risks</StyledTitle>
      <ParagraphText>
        <p>
          You are aware of and accept the risks further detailed in the{" "}
          <LinkWithIcon href={"/terms"} noUnderline shiftChildren>
            Terms and Conditions
          </LinkWithIcon>
          {", "}
          <LinkWithIcon href={"/policy"} noUnderline shiftChildren>
            Privacy Policy
          </LinkWithIcon>
          {" and the "}
          <LinkWithIcon href={"/disclaimer"} noUnderline shiftChildren>
            Disclaimers
          </LinkWithIcon>
          .
        </p>
      </ParagraphText>
    </div>
  )
}

const Flex = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
  color: ${colors.primaryText};
`

const textStyle = {
  minWidth: '160px', // Adjust the width as needed to align the elements
  display: 'inline-block',
};

export const Yield = ({ vault }: { vault: VaultsEnum }) => {
  const { multiple } = useVaultSize({})
  const apy = useApy()

  return (
    <div>
      <br />
      <Flex>
        <span style={textStyle}>Options Yield </span>
        <div>
        <img src={Eth} alt="Eth" height={IMG_SIZE} width={IMG_SIZE} style={{ marginRight: "3px" }} />
        <span style={{ color: colors.green }}>{apy.options}</span>
        </div>
      </Flex>
      <Flex>
        <span style={textStyle}>AMPLOL</span>
        <div>
        <img src={Amplol} alt="Amplol" height={IMG_SIZE} width={IMG_SIZE} style={{ marginRight:  "3px"  }}/>
        <span style={{ color: colors.green }}>
          {formatAmount(multiple, false)}x
        </span>
        </div>
      </Flex>
      <Flex>
        <span style={textStyle}>EtherFi Yield</span>
        <div>
        <img src={Etherfi} alt="Etherfi" height={IMG_SIZE} width={IMG_SIZE} style={{ marginRight:  "3px"  }}/>
        <span style={{ color: colors.green }}>2x</span>
        </div>
      </Flex>
      <Flex>
        <span style={textStyle}>Eigen Yield</span>
        <div>
        <img
          src={Eigenlayer}
          alt="Eigenlayer"
          height={IMG_SIZE}
          width={IMG_SIZE}
          style={{ borderRadius: 24, marginRight:  "3px" }}
        />
        <span style={{ color: colors.green }}>1x</span>
        </div>
      </Flex>
    </div>
  )
}
