import { useState } from "react"
import { WalletClient, zeroAddress } from "viem"
import { useReadContract, useWalletClient } from "wagmi"

import { STETHAbi } from "../constants/abis/STETHAbi"
import { getContractAddr } from "../utils/address"
import {
  Eip2612Props,
  PermitSignature,
  signPermit,
  SignPermitProps,
} from "./permit"

export function use3JanePermit({
  contractAddress,
  chainId,
  walletClient,
  ownerAddress,
  spenderAddress,
  permitVersion,
}: UsePermitProps) {
  const [signature, setSignature] = useState<PermitSignature | undefined>()
  const [error, setError] = useState<Error>()

  const { data: defaultWalletClient } = useWalletClient()
  const walletClientToUse = walletClient ?? defaultWalletClient
  const ownerToUse =
    ownerAddress ?? walletClientToUse?.account?.address ?? zeroAddress
  const { data: nonce } = useReadContract({
    chainId,
    address: contractAddress,
    abi: STETHAbi,
    functionName: "nonces",
    args: [ownerToUse],
  })
  const { data: name } = useReadContract({
    chainId,
    address: contractAddress,
    abi: STETHAbi,
    functionName: "name",
  })
  const { data: versionFromContract } = useReadContract({
    chainId,
    address: contractAddress,
    abi: STETHAbi,
    functionName: "getContractVersion",
  })
  const version = permitVersion ?? versionFromContract?.toString() ?? "1"
  const finalName =
    contractAddress === getContractAddr("EETH")
      ? "EETH"
      : contractAddress === getContractAddr("WEETH")
      ? "EtherFi wrapped ETH"
      : name
  const ready =
    walletClientToUse !== null &&
    walletClientToUse !== undefined &&
    spenderAddress !== undefined &&
    chainId !== undefined &&
    contractAddress !== undefined &&
    finalName !== undefined &&
    nonce !== undefined

  return {
    signPermit: ready
      ? async (
          props: PartialBy<
            Eip2612Props,
            | "chainId"
            | "ownerAddress"
            | "contractAddress"
            | "spenderAddress"
            | "nonce"
            | "erc20Name"
            | "permitVersion"
          > & {
            walletClient?: WalletClient
          }
        ) => {
          try {
            const signature = await signPermit(
              props.walletClient ?? walletClientToUse,
              {
                chainId,
                ownerAddress:
                  ownerAddress ??
                  props.walletClient?.account?.address ??
                  walletClientToUse?.account?.address ??
                  zeroAddress,
                contractAddress: contractAddress,
                spenderAddress: spenderAddress ?? zeroAddress,
                erc20Name: finalName,
                nonce,
                permitVersion: version,
                ...props,
              }
            )
            setSignature(signature)
            return signature
          } catch (error) {
            setError(error as Error)
            throw error
          }
        }
      : undefined,
    signature,
    error,
  }
}

type UsePermitProps = Partial<SignPermitProps> & {
  walletClient?: WalletClient | null
}

type PartialBy<T, K extends keyof T> = Omit<T, K> & Partial<Pick<T, K>>
