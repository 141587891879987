import currency from "currency.js"
import styled from "styled-components"

import { IVaultInfo, MAX_VAULT_CAP } from "../../constants"
import colors from "../../designSystem/colors"
import useLoadingText from "../../hooks/useLoadingText"
import { getAssetImg } from "../../utils/asset"
import { formatAmount } from "../../utils/math"
import TvlProgressBar from "../Vaults/TvlProgressBar"

const Value = styled.span<{ color?: string }>`
  display: flex;
  color: ${({ color }) => (color ? color : colors.primaryText)};

  > * {
    margin: auto 0;
  }

  svg {
    width: 20px;
    height: 20px;
    margin-right: 8px;
  }
`
const KeyValueWrapper = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  width: 100%;
`

interface VaultSizeProps {
  totalBalanceNum: number
  totalBalanceUSD: number
  info: IVaultInfo
  isLoading: boolean
}

export function VaultSize({
  totalBalanceNum,
  totalBalanceUSD,
  info,
  isLoading,
}: VaultSizeProps) {
  const fillPct = Math.round((totalBalanceUSD / MAX_VAULT_CAP) * 100)
  const loadingText = useLoadingText("")
  return (
    <Value
      style={{
        flexDirection: "column",
        alignItems: "flex-end",
        gap: 8,
        width: "220px",
        fontSize: "14px",
      }}
    >
      <KeyValueWrapper>
      <span ></span>
        <span>
          <img
            src={getAssetImg(info.nativeAsset)}
            alt={info.nativeAsset}
            style={{
              width: "16px",
              height: "16px",
              marginLeft: "1px",
              marginRight: "4px",
            }}
          />
          {`$${formatAmount(totalBalanceUSD)} `}
          {/* (
            {isLoading
              ? loadingText
              : `${currency(totalBalanceNum, {
                  precision: 2,
                  symbol: "",
                }).format()}`}
            ) */}
        </span>
      </KeyValueWrapper>
      <div
        style={{
          width: "100%",
        }}
      >
        <TvlProgressBar percent={fillPct} isLoading={isLoading} />
      </div>
      <KeyValueWrapper>
        <span style={{ color: "#A1A1A9" }}>Cap</span>
        <span>${formatAmount(MAX_VAULT_CAP)}</span>
      </KeyValueWrapper>
    </Value>
  )
}
