import React from "react"
import { Link } from "react-router-dom"
import styled from "styled-components"

import { SOCIAL_LINKS } from "../../constants"
import colors from "../../designSystem/colors"
import sizes from "../../designSystem/sizes"
import { AppLogo } from "../Logo"

const VerticalHeaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  border-right: 1px solid ${colors.border};
  padding: 14px 16px;
  @media (max-width: ${sizes.lg}px) {
    display: none;
  }
  z-index: 10;
  background-color: ${colors.background.one};
`

const LogoContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

const SocialsContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  gap: 16px;
  z-index: 1000;

  i {
    font-size: 20px;
  }
`

const VerticalHeader: React.FC = () => {
  return (
    <>
      <VerticalHeaderContainer>
        <LogoContainer>
          <Link to="/">
            <AppLogo size={36} />
          </Link>
        </LogoContainer>
        <SocialsContainer>
          <a
            href={SOCIAL_LINKS.twitter}
            target="_blank"
            rel="noopener noreferrer"
          >
            <i className="fab fa-twitter" style={{ color: "white" }} />
          </a>
          <a
            href={SOCIAL_LINKS.github}
            target="_blank"
            rel="noopener noreferrer"
          >
            <i className="fab fa-github" style={{ color: "white" }} />
          </a>
          <a
            href={SOCIAL_LINKS.discord}
            target="_blank"
            rel="noopener noreferrer"
          >
            <i className="fab fa-discord" style={{ color: "white" }} />
          </a>
          <a href={SOCIAL_LINKS.docs} target="_blank" rel="noopener noreferrer">
            <i className="fas fa-book" style={{ color: "white" }} />
          </a>
        </SocialsContainer>
      </VerticalHeaderContainer>
    </>
  )
}

export default VerticalHeader
