import { Tab, Tabs } from "react-bootstrap"
import styled from "styled-components"

import colors from "../../../designSystem/colors"
import { components } from "../../../designSystem/components"
import { delayedFade } from "../../common"

interface ActionModalWithdrawTabProps {
  children: React.ReactNode
  tab: "instant" | "initiate"
  setTab: (tab: "instant" | "initiate") => void
  setInputAmount: (amount: string) => void
}

const CustomTabs = styled(Tabs)<{
  delay?: number
}>`
  font-size: 12px;
  width: ${components.modalWidth + 50}px;
  margin-bottom: 16px;

  .nav-link {
    color: ${colors.secondaryText};
    border-radius: 0;
  }

  .nav-link.active {
    color: inherit;
  }

  ${delayedFade}
`

export function ActionModalTab({
  children,
  setInputAmount,
  tab,
  setTab,
}: ActionModalWithdrawTabProps) {
  const handleSelectTab = (selectedTab: string | null) => {
    if (selectedTab === "instant" || selectedTab === "initiate") {
      setTab(selectedTab)
      setInputAmount("")
    }
  }

  return (
    <CustomTabs
      defaultActiveKey={tab}
      id="justify-tab-example"
      justify
      onSelect={handleSelectTab}
    >
      <Tab eventKey="instant" title="Instant">
        {children}
      </Tab>
      <Tab eventKey="initiate" title="Initiate / Complete">
        {children}
      </Tab>
    </CustomTabs>
  )
}
