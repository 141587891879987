import { formatEther } from "viem"
import { useReadContracts } from "wagmi"

import { EXPECTED_TVL, VAULT_INFO, VaultsEnum } from "../constants"
import { getContractAbi, getContractAddr } from "../utils/address"
import { Asset } from "../utils/asset"
import { useAssetPrices } from "./useAssetPrices"
import useVault from "./useVault"

export const useVaultSize = ({
  asset = VAULT_INFO[VaultsEnum.ETHERFI].nativeAsset,
  vault = VaultsEnum.ETHERFI,
}: {
  asset?: Asset
  vault?: VaultsEnum
}) => {
  const { totalBalance } = useVault()
  const { data: ethPrice, isLoading, error } = useAssetPrices(asset)

  const { data } = useReadContracts({
    contracts: [
      {
        abi: getContractAbi("WEETH"),
        address: getContractAddr("WEETH"),
        functionName: "getRate",
      },
    ],
  })
  const getRate = formatEther(data?.[0]?.result || 0n)
  const totalBalanceNum = parseFloat(formatEther(totalBalance || 0n))
  const totalBalanceUSD =
    totalBalanceNum * (ethPrice ?? 0) * parseFloat(getRate)
  const multiple = EXPECTED_TVL / totalBalanceUSD

  return {
    isLoading: isLoading || Boolean(error),
    totalBalanceNum,
    totalBalanceUSD,
    multiple,
  }
}
