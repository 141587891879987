import React from "react"
import { Col, Row } from "react-bootstrap"
import styled from "styled-components"

import { Balance } from "../../components/Balance/Balance"
import { HorizontalHeader } from "../../components/Header/HorizontalHeader"
import { ThreeJaneToast } from "../../components/Modal/ActionModal/ActionModalToast/ThreeJaneToast"
import { OnboardingDialog } from "../../components/Onboarding/OnboardingDialog"
import { Vaults } from "../../components/Vaults"
import { VaultsFooter } from "../../components/Vaults/VaultsFooter"
import colors from "../../designSystem/colors"
import { components } from "../../designSystem/components"
import sizes from "../../designSystem/sizes"
import useScreenSize from "../../hooks/useScreenSize"

const HeroContainer = styled.div`
  display: flex;
  height: 100%;
  flex-direction: column;
  justify-content: space-between;
  width: calc(100% - ${components.sidebar}px);

  @media (max-width: ${sizes.lg}px) {
    width: 100%;
  }

  > .row {
    margin-left: 0 !important;
    width: 100%;
  }

  @media (max-width: ${sizes.md}px) {
    overflow-y: scroll;
  }
`

const Content = styled(Row)`
  height: calc(100% - ${components.header}px - ${components.footer}px);

  @media (max-width: ${sizes.lg}px) {
    height: 100%;
  }

  > *:not(:last-child) {
    border-right: 1px solid ${colors.border};
  }
`

const ScrollableContent = styled(Content)`
  overflow-y: scroll;
  overflow-x: hidden;

  ::-webkit-scrollbar {
    width: 0;
    background: transparent;
  }
`

const StickyCol = styled(Col)`
  display: flex;
  position: sticky;
  height: calc(100vh - ${components.header + components.footer}px);
  top: 0;
  overflow-y: scroll;
  ::-webkit-scrollbar {
    width: 0;
    background: transparent;
  }
  @media (max-width: ${sizes.md}px) {
    position: relative;
    height: fit-content;
  }
`

const BalanceWrapper = styled.div`
  display: flex;
  margin: auto;
  width: 100%;

  > * {
    margin: auto;
  }

  @media (max-width: ${sizes.md}px) {
    margin: 32px auto;
    height: fit-content;

    > * {
      height: fit-content;
      margin: 0 auto;
    }
  }
`

const VaultsPage: React.FC = () => {
  const { width } = useScreenSize()

  return (
    <>
      <OnboardingDialog />
      <HeroContainer>
        <HorizontalHeader />
        <ScrollableContent>
          {width > sizes.md && (
            <StickyCol xs={12} md={6}>
              <BalanceWrapper>
                <Balance />
              </BalanceWrapper>
            </StickyCol>
          )}
          <Col xs={12} md={6}>
            <Vaults />
          </Col>
        </ScrollableContent>
        <VaultsFooter />
      </HeroContainer>
      <ThreeJaneToast />
    </>
  )
}

export default VaultsPage
