import styled from "styled-components"

import colors from "../../designSystem/colors"

const BackgroundBar = styled.div<{ height: number; radius: number }>`
  height: ${(props) => props.height}px;
  width: 100%;
  background: rgba(255, 255, 255, 0.08);
  border-radius: ${(props) => props.radius}px;
  position: relative;
`

const ForegroundBar = styled.div<{
  height: number
  radius: number
  width: number
  color: string
}>`
  height: ${(props) => props.height}px;
  border-radius: ${(props) => props.radius}px;
  width: ${(props) => props.width}%;
  background: ${(props) => props.color};
  position: absolute;
  top: 0;
  left: 0;
`

interface BarConfig {
  height: number
  extraClassNames: string
  radius: number
}

interface TvlProgressBarProps {
  // Number between 0-100
  percent: number
  config?: BarConfig
  color?: string
  marginTop?: number
  isLoading?: boolean
}

const TvlProgressBar = ({
  percent,
  config = {
    height: 16,
    extraClassNames: "",
    radius: 4,
  },
  color = colors.primaryText,
  marginTop,
  isLoading,
}: TvlProgressBarProps) => {
  return isLoading ? (
    <BackgroundBar height={8} radius={4} />
  ) : (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        position: "relative",
        marginTop: marginTop ? marginTop : 0,
        flex: 1,
      }}
      className={config.extraClassNames}
    >
      <BackgroundBar height={8} radius={4}>
        <ForegroundBar
          height={8}
          width={percent}
          color={color}
          radius={config.radius}
        />
      </BackgroundBar>
    </div>
  )
}

export default TvlProgressBar
