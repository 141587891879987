import { Col } from "react-bootstrap"
import styled from "styled-components"

import { VAULT_INFO, VaultsEnum } from "../../constants"
import colors from "../../designSystem/colors"
import { components } from "../../designSystem/components"
import sizes from "../../designSystem/sizes"
import { useApy } from "../../hooks/useApy"
import { useVaultSize } from "../../hooks/useVaultSize"
import { getAssetImg } from "../../utils/asset"
import { formatAmount } from "../../utils/math"
import { ApyTooltip } from "../ApyTooltip"
import { delayedUpwardFade } from "../common"
import { StackedLogos } from "../StackedLogos"
import { VaultSize } from "./VaultSize"

const StickyCol = styled(Col)`
  display: flex;
  position: sticky;
  height: calc(100vh - ${components.header + components.footer}px);
  top: 0;
  overflow-y: scroll;
  ::-webkit-scrollbar {
    width: 0;
    background: transparent;
  }
  @media (max-width: ${sizes.md}px) {
    position: relative;
    height: fit-content;
  }
`

const MakerLogo = styled.div<{ delay: number }>`
  display: flex;
  justify-content: center;
  width: 100%;

  ${delayedUpwardFade}

  img {
    text-align: center;
    margin-bottom: 40px;
  }
`

const StatsWrapper = styled.div`
  display: block;
  width: 100%;
`

const Stat = styled.div<{ delay?: number }>`
  display: flex;
  justify-content: space-between;
  padding: 36px 0;

  ${delayedUpwardFade}

  border-bottom: 1px solid ${colors.border};
  &:last-child {
    border-bottom: none;
  }
`

const Label = styled.span`
  color: ${colors.tertiaryText};
`

const Value = styled.span<{ color?: string }>`
  display: flex;
  color: ${({ color }) => (color ? color : colors.primaryText)};

  > * {
    margin: auto 0;
  }

  svg {
    width: 20px;
    height: 20px;
    margin-right: 8px;
  }
`

const PillButton = styled.a<{ delay: number }>`
  padding-left: 16px;
  padding-right: 16px;
  border: 1px solid ${colors.primaryText};
  background-color: transparent;
  border-radius: 100px;
  height: 48px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: fit-content;
  transition: 0.2s ease-in-out;
  color: ${colors.primaryText} !important;
  ${delayedUpwardFade}

  &:hover {
    cursor: pointer;
    color: ${colors.primaryText} !important;
    text-decoration: none !important;

    svg {
      transition: 0.2s ease-in-out;
      transform: translate(4px, -4px);
    }
  }

  > * {
    margin: auto 0;

    &:not(:last-child) {
      margin-right: 8px;
    }
  }
`

const Details = styled.div<{ delay?: number }>`
  align-items: center;
  padding: 32px;
  width: 100%;

  ${delayedUpwardFade}

  @media (max-width: ${sizes.lg}px) {
    padding: 16px;
  }

  a:not(${PillButton}) {
    color: ${colors.text};
    text-decoration: underline;

    svg {
      margin-left: 8px;
    }

    &:hover {
      svg {
        transition: 0.2s ease-in-out;
        transform: translate(4px, -4px);
      }
    }
  }
`

const UserDetailsWrapper = styled.div`
  display: flex;
  margin: auto;
  width: 100%;

  > ${Details} {
    margin: auto 0;
  }
`

interface VaultSummaryProps {
  vault: VaultsEnum
}

export function VaultSummary({ vault }: VaultSummaryProps) {
  const info = VAULT_INFO[vault]
  const { totalBalanceNum, totalBalanceUSD, multiple, isLoading } =
    useVaultSize({
      asset: info.nativeAsset,
      vault,
    })

  const apy = useApy()

  return (
    <StickyCol xs={12} md={6}>
      <UserDetailsWrapper>
        <Details>
          <MakerLogo delay={0.1}>
            <img
              width={64}
              height={64}
              src={getAssetImg(VAULT_INFO[vault].vaultAsset)}
              alt="eeth"
            />
          </MakerLogo>
          <StatsWrapper>
            <Stat delay={0.5}>
              <Label>Vault Name</Label>
              <Value>3Jane Etherfi {vault.toUpperCase()}</Value>
            </Stat>
            <Stat
              delay={0.5}
              style={{
                alignItems: "center",
              }}
            >
              <Label>Vault TVL</Label>
              <VaultSize
                totalBalanceNum={totalBalanceNum}
                totalBalanceUSD={totalBalanceUSD}
                info={info}
                isLoading={isLoading}
              />
            </Stat>
            <Stat delay={0.6}>
              <div
                className="d-flex justify-content-center align-items-center"
                style={{
                  gap: 6,
                }}
              >
                <Label>APY</Label>
                <ApyTooltip />
              </div>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: 2,
                  flexDirection: "column",
                }}
              >
                <Value color={colors.green}>+{apy.total} APY</Value>
                <StackedLogos />
              </div>
            </Stat>
          </StatsWrapper>
        </Details>
      </UserDetailsWrapper>
    </StickyCol>
  )
}
