import React, { useState } from "react"
import styled from "styled-components"

import { SOCIAL_LINKS } from "../../constants"
import colors from "../../designSystem/colors"
import { components } from "../../designSystem/components"
import sizes from "../../designSystem/sizes"
import theme from "../../designSystem/theme"
import { Title } from "../common"
import Link from "../Link"
import { AppLogo } from "../Logo"
import MobileOverlayMenu from "../MobileOverlayMenu"
import MenuButton from "./MenuButtonD"

const HeaderContainer = styled.div<{ $isMenuOpen: boolean }>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  @media (max-width: ${sizes.lg}px) {
    height: ${components.header}px;
    border-bottom: 1px solid ${colors.border};
    // z-index: ${(props) => (props.$isMenuOpen ? 50 : 10)};
    z-index: 100000;
    // The backdrop for the menu does not show up if we enable the backdrop-filter
    // for the header nav. To get around that, just set 'none'
    ${(props) => {
      if (props.$isMenuOpen) {
        return `
    @media (min-width: ${sizes.lg}px) {
      backdrop-filter: none
    }`
      }

      return `
  backdrop-filter: blur(10px);
    /**
     * Firefox desktop come with default flag to have backdrop-filter disabled
     * Firefox Android also currently has bug where backdrop-filter is not being applied
     * More info: https://bugzilla.mozilla.org/show_bug.cgi?id=1178765
     **/
    @-moz-document url-prefix() {
      background-color: rgba(0, 0, 0, 0.9);
    }
  `
    }}
  }
`

const LogoContainer = styled.div`
  display: none;

  @media (max-width: ${sizes.lg}px) {
    position: relative;
    display: flex;
    justify-content: center;
    margin-left: 16px;
  }
`

const NavItemsContainer = styled.div`
  border-top: 1px solid ${colors.primaryText}1F;

  > div {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: ${colors.background.one};
    width: 100%;
    height: ${components.header}px;
  }
`

const NavItem = styled.div`
  border-bottom: 1px solid ${colors.primaryText}1F;

  &:hover {
    cursor: pointer;
    opacity: ${theme.hover.opacity};
  }
`

const NavLinkText = styled(Title)`
  letter-spacing: 1.5px;
  font-size: 16px;
`

const MobileOnly = styled.div`
  display: none;

  @media (max-width: ${sizes.lg}px) {
    display: flex;
  }
`

const MobileHeader: React.FC = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false)

  const onToggleMenu = () => {
    setIsMenuOpen(!isMenuOpen)
  }

  return (
    <HeaderContainer $isMenuOpen={isMenuOpen}>
      <LogoContainer>
        <Link to="/">
          <AppLogo size={36} />
        </Link>
      </LogoContainer>
      <MobileOnly>
        <MenuButton onToggle={onToggleMenu} isOpen={isMenuOpen} />
        <MobileOverlayMenu
          className="flex-column align-items-center justify-content-start"
          isMenuOpen={isMenuOpen}
          onClick={onToggleMenu}
          boundingDivProps={{
            style: {
              marginRight: "auto",
              width: "100%",
            },
          }}
          style={{
            paddingTop: components.header,
            backgroundColor: "transparent",
          }}
        >
          <NavItemsContainer>
            {Object.entries(SOCIAL_LINKS).map(([key, url]) => {
              return (
                <NavItem
                  key={key}
                  onClick={() => {
                    setIsMenuOpen(false)
                  }}
                >
                  <a href={url} target="_blank" rel="noreferrer noopener">
                    <NavLinkText>{key}</NavLinkText>
                  </a>
                </NavItem>
              )
            })}
          </NavItemsContainer>
        </MobileOverlayMenu>
      </MobileOnly>
    </HeaderContainer>
  )
}

export default MobileHeader
