import { useQuery } from "@tanstack/react-query"
import axios, { AxiosError } from "axios"
import { z } from "zod"

const CoingeckoSchema = z.object({
  prices: z.array(z.tuple([z.number(), z.number()])),
  market_caps: z.array(z.tuple([z.number(), z.number()])),
  total_volumes: z.array(z.tuple([z.number(), z.number()])),
})

const coingeckoUrl =
  "https://pro-api.coingecko.com/api/v3/coins/ethereum/contract/0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2/market_chart/range?vs_currency=usd"

export const useAssetPricesTimestamp = ({
  timestamp,
}: {
  timestamp: number
}) => {
  const start = timestamp - 3_600
  const end = timestamp + 3_600
  const res = useQuery({
    queryKey: ["cg-assetPrice", start, end],
    queryFn: async () => {
      try {
        const ethereumUrl = `${coingeckoUrl}&from=${start}&to=${end}`
        const res = await axios.get(ethereumUrl, {
          headers: {
            "x-cg-pro-api-key": process.env.REACT_APP_COINGECKO_API_KEY,
          },
        })
        const data = res.data
        const price = CoingeckoSchema.parse(data)
        return price.prices[0][1]
      } catch (error) {
        const axiosError = error as AxiosError
        console.error(axiosError)
        return 0
      }
    },
    refetchInterval: 1000 * 60 * 5,
    retry: (failureCount, error) => {
      const axiosError = error as AxiosError
      console.error(axiosError)
      return failureCount < 5
    },
    retryDelay: (attemptIndex) => Math.min(1000 * 2 ** attemptIndex, 30000),
  })
  return res
}
