import styled from "styled-components"

import { ReactComponent as ExternalLinkIcon } from "../assets/svg/externalLink.svg"
import colors from "../designSystem/colors"

interface LinkWithIconProps {
  href: string
  children: React.ReactNode
  color?: string
  noUnderline?: boolean
  shiftChildren?: boolean
}

const LinkWrapper = styled.span<{
  $color?: string
  $noUnderline?: boolean
  $shiftChildren?: boolean
}>`
  color: ${(props) => props.$color || colors.secondaryText};

  svg {
    transition: all 0.2s ease-in-out;
    margin-left: 4px;
    opacity: 0.32;
  }

  > a {
    color: ${(props) => props.$color || colors.secondaryText};
    text-decoration: ${({ $noUnderline }) =>
      $noUnderline ? "none" : "underline"}; // Add this line

    &:hover {
      svg {
        transform: translate(2px, -2px);
      }
    }

    span {
      ${({ $shiftChildren }) =>
        $shiftChildren &&
        `
        margin-left: -7px;
        margin-top: -1px;
        display: inline-flex;
        align-items: center;
      `}
    }
  }
`

export function LinkWithIcon({
  href,
  children,
  color,
  noUnderline,
  shiftChildren,
}: LinkWithIconProps) {
  return (
    <LinkWrapper
      $color={color}
      $noUnderline={noUnderline}
      $shiftChildren={shiftChildren}
    >
      <a
        href={href}
        target="_blank"
        rel="noreferrer noopener"
        style={{
          color: color || colors.secondaryText,
          textDecoration: noUnderline ? "none" : "underline",
        }}
      >
        {children}
        <span>
          <ExternalLinkIcon />
        </span>
      </a>
    </LinkWrapper>
  )
}
