import { useEffect, useState } from "react"
import { Address, maxUint256 } from "viem"
import { mainnet } from "viem/chains"
import { useWalletClient } from "wagmi"

import { PermitSignature } from "../../../hooks/permit"
import { use3JanePermit } from "../../../hooks/use3JanePermit"
import useLoadingText from "../../../hooks/useLoadingText"
import { Asset } from "../../../utils/asset"
import { ActionButton, FormButtonFade } from "../../common"
import { ActionType } from "./types"

interface ActionModalPermitProps {
  setPermitSignature: React.Dispatch<
    React.SetStateAction<PermitSignature | undefined>
  >
  setPermittedAmount: React.Dispatch<React.SetStateAction<bigint | undefined>>
  spender: Address
  contractAddress: Address
  amountBn: bigint
  show: boolean
  triggerAnimation: boolean
  actionType: ActionType
  depositAsset: Asset
  hasError: boolean
}

export function ActionModalPermit({
  setPermitSignature,
  setPermittedAmount,
  spender,
  contractAddress,
  amountBn,
  show,
  triggerAnimation,
  actionType,
  depositAsset,
  hasError,
}: ActionModalPermitProps) {
  const { data: walletClient } = useWalletClient()
  const [isLoading, setIsLoading] = useState(false)
  const [isPermitted, setIsPermitted] = useState(false)
  const loadingTextApprove = useLoadingText("permitting")

  const { signPermit } = use3JanePermit({
    walletClient,
    ownerAddress: walletClient?.account.address,
    chainId: mainnet.id,
    spenderAddress: spender,
    contractAddress: contractAddress,
  })

  const handlePermit = async () => {
    setIsLoading(true)
    try {
      const permitSignature = await signPermit?.({
        value: amountBn,
        deadline: maxUint256,
      })
      setIsPermitted(true)
      setPermitSignature(permitSignature)
      setPermittedAmount(amountBn)
    } catch (error) {
      console.error("Error signing permit", error)
    }
    setIsLoading(false)
  }

  useEffect(() => {
    setIsPermitted(false)
  }, [amountBn])

  if (
    actionType === "withdraw" ||
    depositAsset === Asset.ETH ||
    amountBn === 0n ||
    isPermitted
  ) {
    return null
  } else if (hasError) {
    return (
      <FormButtonFade
        $show={show}
        $triggerAnimation={triggerAnimation}
        $delay={0.4}
      >
        <ActionButton disabled>PERMIT</ActionButton>
      </FormButtonFade>
    )
  }

  return (
    <>
      <FormButtonFade
        $show={show}
        $triggerAnimation={triggerAnimation}
        $delay={0.4}
      >
        <ActionButton
          disabled={isLoading || isPermitted || amountBn === 0n}
          onClick={handlePermit}
        >
          {isLoading
            ? loadingTextApprove
            : isPermitted
              ? "PERMITTED"
              : "PERMIT"}
        </ActionButton>
      </FormButtonFade>
    </>
  )
}
