import React, { useEffect, useState } from "react"
import { AnimatePresence, motion } from "framer-motion"
import styled from "styled-components"

import colors from "../../designSystem/colors"
import { SecondaryText, Title } from "../common"

const StyledTitle = styled(Title)<{
  size?: number
  marginLeft?: number
  $marginRight?: number
  color?: string
}>`
  text-align: center;
  color: ${(props) =>
    props.color === undefined ? `${colors.primaryText}` : `${props.color}`};
  margin-right: ${(props) =>
    props.$marginRight === undefined ? `0px` : `${props.$marginRight}px`};
  margin-left: ${(props) =>
    props.marginLeft === undefined ? `0px` : `${props.marginLeft}px`};
  font-size: ${(props) =>
    props.size === undefined ? `14px` : `${props.size}px`};
  line-height: 20px;
`

const StyledSecondaryText = styled(SecondaryText)<{ color?: string }>`
  color: ${(props) =>
    props.color === undefined ? `${colors.primaryText}` : `${props.color}`};
  font-size: 12px;
`

const StepsHeaderTextContainer = styled.div`
  display: flex;
  align-items: center;
  align-self: center;
  justify-content: center;
  text-align: center;
  height: 64px;
  position: absolute;
  margin: auto;
`

interface StepsCarouselProps {
  stepNumbers: string[]
  stepExplanations: React.ReactNode[]
}

const StepsCarousel: React.FC<StepsCarouselProps> = ({
  stepNumbers,
  stepExplanations,
}) => {
  const [step, setStep] = useState<number>(0)

  useEffect(() => {
    const timer = setTimeout(() => {
      setStep((prevStep) => (prevStep + 1) % stepNumbers.length)
    }, 4000)
    return () => clearTimeout(timer)
  }, [step, stepNumbers.length])

  return (
    <StepsHeaderTextContainer>
      <AnimatePresence mode="wait">
        <motion.div
          key={step}
          transition={{
            delay: 0.25,
            duration: 0.5,
            type: "keyframes",
            ease: "easeOut",
          }}
          initial={{
            opacity: 0,
          }}
          animate={{
            opacity: 1,
          }}
          exit={{
            opacity: 0,
          }}
          style={{ display: "flex", alignItems: "center", gap: 8 }}
        >
          <StyledTitle color={colors.tertiaryText} $marginRight={8} size={12}>
            {stepNumbers[step]}
          </StyledTitle>
          <StyledSecondaryText>{stepExplanations[step]}</StyledSecondaryText>
        </motion.div>
      </AnimatePresence>
    </StepsHeaderTextContainer>
  )
}

export default StepsCarousel
