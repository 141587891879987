import styled from "styled-components"
import { Address } from "viem"

import { LINKS } from "../constants"
import sizes from "../designSystem/sizes"
import { truncateAddress } from "../utils/address"
import { delayedFade } from "./common"
import { LinkWithIcon } from "./LinkWithIcon"

const ContractAddressWrapper = styled.div<{ delay: number }>`
  display: flex;
  gap: 24px;
  justify-content: center;
  height: 100%;

  ${delayedFade}

  > * {
    margin: auto 0;
  }

  @media (max-width: ${sizes.lg}px) {
    display: none;
  }
`

interface ContractAddressProps {
  address: Address
  color?: string
}

export function ContractAddress({ address, color }: ContractAddressProps) {
  return (
    <ContractAddressWrapper delay={0.1}>
      <LinkWithIcon
        href={`${LINKS.etherscan}/address/${address}`}
        color={color}
      >
        {truncateAddress(address)}
      </LinkWithIcon>
    </ContractAddressWrapper>
  )
}
