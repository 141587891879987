import React, { useState } from "react"
import ReactPlayer from "react-player"
import styled from "styled-components"

import sizes from "../../designSystem/sizes"
import useScreenSize from "../../hooks/useScreenSize"

const FloatingBackgroundContainer = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  overflow: hidden;
  z-index: 1000;
  display: flex;
  align-items: center;
  justify-content: center;
`

const VideoBackground: React.FC = () => {
  const { video, width } = useScreenSize()
  const [videoReady, setVideoReady] = useState(false)

  return (
    <FloatingBackgroundContainer>
      <ReactPlayer
        key="video-player"
        url="https://player.vimeo.com/video/943720946?h=1b15f626da?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
        playing={true}
        width={video.width}
        height={video.height}
        style={{
          opacity: videoReady ? 1 : 0,
          minWidth: video.width,
          minHeight: video.height,
          position: "absolute",
          scale: "1",
        }}
        muted
        loop
        onReady={() => {
          setVideoReady(true)
        }}
      />
    </FloatingBackgroundContainer>
  )
}
export default VideoBackground
