import { Button, ToastContainer } from "react-bootstrap"

import { useThreeJaneContext } from "../../../../store"
import DepositToast from "./DepositToast"
import ErrorToast from "./ErrorToast"
import WithdrawToast from "./WithdrawToast"

export function ThreeJaneToast() {
  const { amountBn, txError, showToast, setShowToast, action } =
    useThreeJaneContext((s) => ({
      amountBn: s.amountBn,
      txError: s.txError,
      showToast: s.showToast,
      setShowToast: s.setShowToast,
      action: s.action,
    }))

  return (
    <>
      <ToastContainer position="top-end" className="p-3">
        {txError ? (
          <ErrorToast
            show={showToast}
            onClose={() => setShowToast(false)}
            errorMessage={
              action === "deposit" ? "Deposit failed" : "Withdrawal failed"
            }
          />
        ) : action === "deposit" ? (
          <DepositToast
            show={showToast}
            onClose={() => setShowToast(false)}
            amountBn={amountBn}
          />
        ) : (
          <WithdrawToast
            show={showToast}
            onClose={() => setShowToast(false)}
            amountBn={amountBn}
          />
        )}
      </ToastContainer>
      {/* <Button onClick={() => setShowToast(!showToast)}>Show toast</Button> */}
    </>
  )
}
