import { useQuery } from "@tanstack/react-query"

import { fetchApy } from "../gql/queries"
import { formatAmount } from "../utils/math"
import { useVaultSize } from "./useVaultSize"

const STAKING_APY = 2.83

export function useApy() {
  const { multiple } = useVaultSize({})

  const dataSubgraph = useQuery({
    queryKey: ["apy"],
    queryFn: async () => {
      return fetchApy({})
    },
  })

  const optionApy = (dataSubgraph?.data ?? 0) * 100

  const total = STAKING_APY + optionApy

  return {
    staking: `${formatAmount(STAKING_APY, true)}%`,
    options: `${formatAmount(optionApy, true)}%`,
    combo: `${STAKING_APY + parseInt(formatAmount(optionApy, true), 10)}%`,
    etherfiPoints: `3x`,
    elPoints: "1x",
    amplol: `${formatAmount(multiple, false)}x`,
    total: `${formatAmount(total, true)}%`,
  }
}
