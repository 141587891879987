import { Address } from "viem"
import { useReadContract, useReadContracts } from "wagmi"

import { DECIMALS_ETH } from "../constants"
import { getContractAbi, getContractAddr } from "../utils/address"
import { sharesToAsset } from "../utils/math"

const etherfiAbiAddress = {
  abi: getContractAbi("EtherfiVault"),
  address: getContractAddr("EtherfiVault"),
} as const

const amplolAbiAddress = {
  abi: getContractAbi("AMPLOL"),
  address: getContractAddr("AMPLOL"),
} as const

export const useUserAccount = ({ account }: { account: Address }) => {
  const { data, isLoading } = useReadContracts({
    contracts: [
      {
        ...etherfiAbiAddress,
        functionName: "depositReceipts",
        args: [account],
      },
      {
        ...etherfiAbiAddress,
        functionName: "accountVaultBalance",
        args: [account],
      },
      {
        ...etherfiAbiAddress,
        functionName: "withdrawals",
        args: [account],
      },
      {
        ...etherfiAbiAddress,
        functionName: "shares",
        args: [account],
      },
      {
        ...etherfiAbiAddress,
        functionName: "vaultState",
      },
      {
        ...amplolAbiAddress,
        functionName: "balanceOf",
        args: [account],
      },
    ],
  })

  // struct DepositReceipt {
  //  // Maximum of 65535 rounds. Assuming 1 round is 7 days, maximum is 1256 years.
  //  uint16 round;
  //  // Deposit amount, max 20,282,409,603,651 or 20 trillion ETH deposit
  //  uint104 amount;
  //  // Unredeemed shares balance
  //  uint128 unredeemedShares;
  // }
  const depositReceipts = data?.[0].result
  const depositReceiptsRound = depositReceipts?.[0] || 0
  const accountVaultBalance = data?.[1].result || 0n
  const pendingDeposits = depositReceipts?.[1] || 0n
  const withdrawals = data?.[2].result
  const shares = data?.[3].result
  const vaultState = data?.[4].result
  const amplolBalance = data?.[5].result || 0n
  const round = vaultState?.[0]

  // struct Withdrawal {
  //  // Maximum of 65535 rounds. Assuming 1 round is 7 days, maximum is 1256 years.
  //  uint16 round;
  //  // Number of shares withdrawn
  //  uint128 shares;
  // }
  const withdrawalShares = withdrawals?.[1] || 0n

  const { data: roundPricePerShare } = useReadContract({
    ...etherfiAbiAddress,
    functionName: "roundPricePerShare",
    args: [BigInt(depositReceiptsRound)],
    query: {
      enabled: !!depositReceiptsRound,
    },
  })
  const withdrawAmount = sharesToAsset({
    shares: withdrawalShares,
    assetPerShare: roundPricePerShare ?? 0n,
    decimals: DECIMALS_ETH,
  })

  const instantWithdrawalAmount =
    depositReceiptsRound === round ? pendingDeposits : 0n

  return {
    totalUserBalance: accountVaultBalance + instantWithdrawalAmount,
    isLoading,
    accountVaultBalance,
    pendingDeposits,
    shares,
    instantWithdrawalAmount,
    withdrawAmount,
    withdrawalShares,
    amplolBalance,
  }
}
