import { useCallback, useState } from "react"
import { Row } from "react-bootstrap"
import styled from "styled-components"

import { HorizontalHeader } from "../../components/Header/HorizontalHeader"
import ActionModal from "../../components/Modal/ActionModal"
import { VaultDetails } from "../../components/Vault/VaultDetails"
import { VaultFooter } from "../../components/Vault/VaultFooter"
import { VaultSummary } from "../../components/Vault/VaultSummary"
import colors from "../../designSystem/colors"
import { components } from "../../designSystem/components"
import sizes from "../../designSystem/sizes"
import { useVaultName } from "../../hooks/useVaultName"

const Content = styled(Row)`
  @media (max-width: ${sizes.lg}px) {
    height: 100%;
  }

  > *:not(:last-child) {
    border-right: 1px solid ${colors.border};
  }
`

const ScrollableContent = styled(Content)`
  @media (max-width: ${sizes.lg}px) {
    height: calc(100% - 2 * ${components.header}px - ${components.footer}px);
  }
  height: calc(100% - ${components.header}px - ${components.footer}px);
  overflow-y: scroll;
  overflow-x: hidden;

  ::-webkit-scrollbar {
    width: 0;
    background: transparent;
  }
`

const VaultContainer = styled.div`
  width: calc(100% - ${components.sidebar}px);
  @media (max-width: ${sizes.lg}px) {
    width: 100%;
  }

  > .row {
    margin-left: 0 !important;
    width: 100%;
    border-right: 1px solid ${colors.border};
  }
`

const VaultPage = () => {
  const vault = useVaultName()

  const [action, setAction] = useState<"deposit" | "withdraw">()

  const onHide = useCallback(() => {
    setAction(undefined)
  }, [])

  return (
    <>
      <ActionModal
        show={!!action}
        actionType={action === "deposit" ? "deposit" : "withdraw"}
        onHide={onHide}
        vault={vault}
      />
      <VaultContainer>
        <HorizontalHeader />
        <ScrollableContent>
          <VaultSummary vault={vault} />
          <VaultDetails vault={vault} />
        </ScrollableContent>
        <VaultFooter vault={vault} setAction={setAction} />
      </VaultContainer>
    </>
  )
}

export default VaultPage
